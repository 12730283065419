import React, { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import {
  AvatarExperimentSwitchback,
  Box,
  Input,
  Loading,
  RowDetail,
} from "@console/dsc";
import { useTheme } from "@emotion/react";

import { trackEvent } from "../../../../analytics";
import Footer from "../../../../components/Footer";
import Header from "../../../../components/Header";
import { useMetaTitle } from "../../../../components/Layout";
import StandardError from "../../../../components/StandardError";
import { INPUT_WIDTH_STANDARD } from "../../../../config/general";
import { useExperiments } from "../../../../contexts/experiments/Experiments.context";
import useManageEntity from "../../../../hooks/useManageEntity";
import useStandardInputs from "../../../../hooks/useStandardInputs";
import { AppPageProps } from "../../../App/App.types";
import useReturnPaths from "../../../App/hooks/useReturnPaths";
import { sharedTooltipCopy } from "../../data/microcopy";

const EditSwitchbackTest = ({ app }: AppPageProps) => {
  const [, setMetaTitle] = useMetaTitle();
  const theme = useTheme();
  const { returnPath, returnPathList } = useReturnPaths();
  const params = useParams() as {
    id: string;
    field?: string;
  };

  const [isProcessing, setIsProcessing] = useState(false);

  const { loadSwitchbackTests } = useExperiments();

  const {
    editEntity: editSwitchbackTest,
    entity: switchbackTest,
    entityLoadError: switchbackTestLoadError,
    entityEditError: switchbackTestEditError,
    loadEntity: loadSwitchbackTest,
    isEntityEdited: isSwitchbackTestSaved,
  } = useManageEntity("experiments/switchback");

  const {
    getStandardInputsProps,
    pendingStandardInputs,
    standardInputsErrors,
    updateStandardInputs,
  } = useStandardInputs(app, "experiments/switchback", true);

  // load switchback test
  useEffect(() => {
    if (!switchbackTest && !switchbackTestLoadError) {
      loadSwitchbackTest(app.id, params.id);
    }
  }, [
    app.id,
    switchbackTest,
    switchbackTestLoadError,
    loadSwitchbackTest,
    params.id,
  ]);

  // page display
  useEffect(() => {
    if (switchbackTest) {
      setMetaTitle(`Edit ${switchbackTest.name}`);
    }
  }, [switchbackTest, setMetaTitle]);

  // pre-fill pending switchback test with original values
  useEffect(() => {
    if (switchbackTest && !pendingStandardInputs.id) {
      updateStandardInputs([
        { key: "name", value: switchbackTest.name },
        { key: "id", value: switchbackTest.id },
        { key: "description", value: switchbackTest.description },
      ]);
    }
  }, [switchbackTest, pendingStandardInputs.id, updateStandardInputs]);

  // disable loading state if edit switchback test error
  useEffect(() => {
    if (switchbackTestEditError && isProcessing) {
      setIsProcessing(false);
    }
  }, [isProcessing, switchbackTestEditError]);

  const handleSwitchbackTestSave = async (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    e.preventDefault();
    e.stopPropagation();

    setIsProcessing(true);

    const payload = { ...pendingStandardInputs };

    await editSwitchbackTest(app.id, switchbackTest!.id, payload);
  };

  const handleCancel = () => {
    trackEvent("SwitchbackTests", {
      view: "Edit Switchback Test",
      action: "Edit Switchback Test Canceled",
    });
    return;
  };

  const handleSwitchbackTestPostDelete = () => {
    trackEvent("SwitchbackTests", {
      view: "Edit Switchback Test",
      action: "Switchback Test Deleted",
    });

    loadSwitchbackTests(app.id);
  };

  if (switchbackTestLoadError) {
    return <StandardError errorMessage={switchbackTestLoadError} />;
  }
  if (!pendingStandardInputs.id || !switchbackTest) {
    return <Loading type="full-screen" dotColor={theme.color.orange500} />;
  }
  if (isSwitchbackTestSaved) {
    trackEvent("SwitchbackTests", {
      view: "Edit Switchback Test",
      action: "Switchback Test Saved",
    });

    loadSwitchbackTest(app.id, params.id);
    loadSwitchbackTests(app.id);

    return <Redirect to={returnPath} />;
  }

  const isActionButtonDisabled =
    !pendingStandardInputs.name || !!standardInputsErrors.name;

  return (
    <>
      <Header
        configPageTitle={{
          label: `Edit ${switchbackTest.name}`,
          ancestorIcon: <AvatarExperimentSwitchback size={24} />,
          ancestorLabel: "Switchback Tests",
          ancestorUrl: returnPathList,
        }}
      />

      <form>
        <RowDetail
          hasNoBorder
          property="Name"
          secondaryLabel="For reference only"
          render={
            <Box width="100%" maxWidth={INPUT_WIDTH_STANDARD}>
              <Input
                {...getStandardInputsProps({
                  placeholder: "Switchback test name",
                  testId: "edit-switchback-test-name-input",
                  type: "name",
                  trackEventCategory: "SwitchbackTests",
                  trackEventProperties: {
                    view: "Edit Switchback Test",
                    action: "Field Changed",
                    meta: {
                      field: "name",
                    },
                  },
                })}
              />
            </Box>
          }
        />

        <RowDetail
          property="ID"
          testId="switchback-test-row-id"
          secondaryLabel="Read-only"
          tooltipCopy={sharedTooltipCopy.idEdit("switchback test").content}
          render={
            <Box flexGrow={1} maxWidth={INPUT_WIDTH_STANDARD}>
              <Input
                htmlType="text"
                isDisabled
                placeholder="Switchback Test ID"
                value={switchbackTest.id}
                readOnly
              />
            </Box>
          }
        />

        <RowDetail
          property="Description"
          secondaryLabel="(optional)"
          render={
            <Box width="100%" maxWidth={INPUT_WIDTH_STANDARD}>
              <Input
                {...getStandardInputsProps({
                  placeholder: "Switchback test description",
                  testId: "edit-switchback-test-description-input",
                  type: "description",
                  trackEventCategory: "SwitchbackTests",
                  trackEventProperties: {
                    view: "Edit Switchback Test",
                    action: "Field Changed",
                    meta: {
                      field: "description",
                    },
                  },
                })}
              />
            </Box>
          }
        />

        <Footer
          app={app}
          endpoint="experiments/switchback"
          entityId={switchbackTest.id}
          error={switchbackTestEditError}
          handleCancel={handleCancel}
          handleMainAction={handleSwitchbackTestSave}
          handlePostDelete={handleSwitchbackTestPostDelete}
          isActionButtonLoading={isProcessing}
          isActionButtonDisabled={isActionButtonDisabled}
          returnPath={returnPath}
          returnPathList={returnPathList}
          view="edit"
        />
      </form>
    </>
  );
};

export default EditSwitchbackTest;
