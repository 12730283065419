import React, { useEffect } from "react";
import { Box, Button2, Flex, IconX, Input, Text } from "@console/dsc";
import { rem, roundNumber } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";

import { AppResponse } from "../../../api/core/controlPlane.types";
import AddConfigOptions from "../../../components/AddConfigOptions";
import {
  ConfigOption,
  HandleConfigOptionChangeParams,
} from "../../../components/AddConfigOptions/AddConfigOptions.types";
import ControlPanel from "../../../components/ControlPanel";
import InstanceSelect from "../../../components/InstanceSelect";
import { PendingInstances } from "../../../components/InstanceSelect/InstanceSelect.types";
import Panel from "../../../components/Panel";
import { DEFAULT_ENSEMBLE_RUN_GROUP_ID_LABEL } from "../../../config/apps";
import useStandardInputs from "../../../hooks/useStandardInputs";
import { parseInputTypeNumberDisplay } from "../../../utils/inputHelpers";
import { checkIdForError, getIdSlug } from "../../../utils/systemIds";
import { PendingEnsembleRunGroup } from "../App.types";
import { ensembleDefinitionTooltips } from "../data/appTooltips";

type AddEnsembleRunGroupsProps = {
  app: AppResponse;
  pendingRunGroups: PendingEnsembleRunGroup[];
  setPendingRunGroups: React.Dispatch<
    React.SetStateAction<PendingEnsembleRunGroup[]>
  >;
};

const getEmptyConfigOption = (): ConfigOption => {
  return { option: "", value: "" };
};

const getEmptyRunGroup = (runGroupsLength: number): PendingEnsembleRunGroup => {
  return {
    configOptions: [getEmptyConfigOption()],
    id: `${DEFAULT_ENSEMBLE_RUN_GROUP_ID_LABEL}-${runGroupsLength + 1}`,
    idError: null,
    instances: [undefined],
    repetitions: 0,
  };
};

const AddEnsembleRunGroups = ({
  app,
  pendingRunGroups,
  setPendingRunGroups,
}: AddEnsembleRunGroupsProps) => {
  const theme = useTheme();
  const { getMaxLength } = useStandardInputs();

  // load initial blank option set
  useEffect(() => {
    if (!pendingRunGroups.length) {
      setPendingRunGroups([getEmptyRunGroup(0)]);
    }
  }, [pendingRunGroups.length, setPendingRunGroups]);

  const addEmptyRunGroup = (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    e.preventDefault();
    e.stopPropagation();

    setPendingRunGroups((prevState) => {
      return [...prevState, getEmptyRunGroup(prevState.length)];
    });

    return;
  };

  const removePendingRunGroup = (
    e: {
      preventDefault: () => void;
      stopPropagation: () => void;
    },
    index: number
  ) => {
    e.preventDefault();
    e.stopPropagation();

    setPendingRunGroups((prevState) => {
      prevState.splice(index, 1);
      return [...prevState];
    });

    return;
  };

  const addEmptyRunGroupConfigOption =
    (e: { preventDefault: () => void; stopPropagation: () => void }) =>
    (index: number) => {
      e.preventDefault();
      e.stopPropagation();

      const pendingRunGroup = pendingRunGroups[index];
      const { configOptions } = pendingRunGroup;

      pendingRunGroup.configOptions = [
        ...configOptions,
        getEmptyConfigOption(),
      ];

      setPendingRunGroups((prevState) => {
        prevState[index] = pendingRunGroup;
        return [...prevState];
      });

      return;
    };

  const setPendingRunGroupInstances =
    (pendingRunGroupInstances: PendingInstances) => (index: number) => {
      const pendingRunGroup = pendingRunGroups[index];
      pendingRunGroup.instances = pendingRunGroupInstances;

      setPendingRunGroups((prevState) => {
        prevState[index] = pendingRunGroup;
        return [...prevState];
      });

      return;
    };

  const handlePendingRunGroupRepetitions = (
    repetitions: number,
    index: number
  ) => {
    const pendingRunGroup = pendingRunGroups[index];
    pendingRunGroup.repetitions = Number(repetitions);

    setPendingRunGroups((prevState) => {
      prevState[index] = pendingRunGroup;
      return [...prevState];
    });

    return;
  };

  const handlePendingRunGroupConfigOptionChange =
    ({ index, type, value }: HandleConfigOptionChangeParams) =>
    (pendingRunGroupIndex: number) => {
      const pendingRunGroup = pendingRunGroups[pendingRunGroupIndex];
      const pendingRunGroupConfigOptions = pendingRunGroup.configOptions;
      const pendingRunGroupConfigOption = pendingRunGroup.configOptions[index];

      const inputValue = value;
      pendingRunGroupConfigOption[type] =
        type === "option" ? inputValue.trim() : inputValue;

      // insert updated config option back into pendingRunGroup
      pendingRunGroupConfigOptions[index] = pendingRunGroupConfigOption;
      pendingRunGroup.configOptions = pendingRunGroupConfigOptions;

      // update state with new pendingRunGroup
      setPendingRunGroups((prevState) => {
        prevState[pendingRunGroupIndex] = pendingRunGroup;
        return [...prevState];
      });

      return;
    };

  const removePendingRunGroupConfigOption =
    (_e: any, index: number) => (pendingRunGroupIndex: number) => {
      const pendingRunGroup = pendingRunGroups[pendingRunGroupIndex];
      const pendingRunGroupConfigOptions = pendingRunGroup.configOptions;

      // clear values, but don't remove if only config option
      let pendingRunGroupConfigOptionsModified;
      if (pendingRunGroupConfigOptions.length === 1) {
        pendingRunGroupConfigOptionsModified = [getEmptyConfigOption()];
      } else {
        pendingRunGroupConfigOptionsModified =
          pendingRunGroupConfigOptions.filter((_pendingOption, i) => {
            return i !== index;
          });
      }

      pendingRunGroup.configOptions = pendingRunGroupConfigOptionsModified;

      // update state with new pendingRunGroup
      setPendingRunGroups((prevState) => {
        prevState[pendingRunGroupIndex] = pendingRunGroup;
        return [...prevState];
      });

      return;
    };

  const handlePendingRunGroupIdChange = (
    e: { target: { value: string } },
    index: number
  ) => {
    const pendingRunGroup = pendingRunGroups[index];
    if (!pendingRunGroup) return;
    const pendingRunGroupId = getIdSlug(e?.target?.value);
    pendingRunGroup.id = pendingRunGroupId;
    pendingRunGroup.idError = checkIdForError(pendingRunGroupId);

    setPendingRunGroups((prevState) => {
      prevState[index] = pendingRunGroup;
      return [...prevState];
    });

    return;
  };
  const handlePendingRunGroupIdOnBlur = (
    e: { target: { value: string } },
    index: number
  ) => {
    const pendingRunGroup = pendingRunGroups[index];
    if (!pendingRunGroup) return;
    const pendingRunGroupId = getIdSlug(e?.target?.value);
    pendingRunGroup.id = pendingRunGroupId;
    pendingRunGroup.idError = checkIdForError(pendingRunGroupId, true);

    setPendingRunGroups((prevState) => {
      prevState[index] = pendingRunGroup;
      return [...prevState];
    });

    return;
  };

  const renderPendingRunGroups = () => {
    return pendingRunGroups.map((pendingRunGroup, pendingRunGroupIndex) => {
      const {
        instances,
        id: pendingRunGroupId,
        idError: pendingRunGroupIdError,
        repetitions: pendingRunGroupRepetitions,
      } = pendingRunGroup;

      const pendingRunGroupRunCount =
        roundNumber(pendingRunGroup.repetitions, 0) + 1;

      const inputIdMaxLength = getMaxLength("id");

      return (
        <ControlPanel
          key={`pending-run-group-${pendingRunGroupIndex}`}
          isOpen
          hasNoBorder
          useNonNativeElements
          summaryNode={
            <Flex
              alignItems="flex-start"
              flexDirection={["column", "column", "column", "column", "row"]}
            >
              <Flex
                width="100%"
                ml={1}
                alignItems="flex-start"
                maxWidth={`calc(${rem(480)} + ${theme.spacing.s7})`}
                flexShrink={0}
              >
                <Box width="100%">
                  <Input
                    htmlType="text"
                    name={`${pendingRunGroupId}-id`}
                    testId={`${pendingRunGroupId}-id-input`}
                    placeholder="Run Group ID"
                    maxLength={inputIdMaxLength}
                    value={pendingRunGroupId}
                    onChange={(e: { target: { value: string } }) => {
                      handlePendingRunGroupIdChange(e, pendingRunGroupIndex);
                      return;
                    }}
                    onBlur={(e: { target: { value: string } }) => {
                      handlePendingRunGroupIdOnBlur(e, pendingRunGroupIndex);
                      return;
                    }}
                    errorMessage={pendingRunGroupIdError}
                    errorMessageTestId={`${pendingRunGroupId}-id-input-error`}
                    isError={pendingRunGroupIdError}
                  />
                </Box>

                <Button2
                  mt={1}
                  isDisabled={pendingRunGroups.length < 2}
                  type="text-quiet"
                  icon={
                    <IconX
                      iconColor={theme.color.gray500}
                      iconColorHover={theme.color.gray600}
                    />
                  }
                  onClick={(e: {
                    preventDefault: () => void;
                    stopPropagation: () => void;
                  }) => removePendingRunGroup(e, pendingRunGroupIndex)}
                  styles={{
                    width: `${theme.spacing.s7} !important`,
                    flexShrink: 0,
                    position: "relative",
                    zIndex: 10,
                  }}
                />
              </Flex>

              <Box pt={[1, 1, 1, 1, rem(9)]} pl={[1, 1, 1, 1, 4]}>
                <Text styleName="body-3">
                  Run count:{" "}
                  <Text
                    as="strong"
                    styleName="body-3-bold"
                    styles={{ color: theme.color.gray800 }}
                  >
                    {pendingRunGroupRunCount}
                  </Text>
                </Text>
              </Box>
            </Flex>
          }
          stylesDetails={{
            paddingBottom: `0 !important`,
            paddingLeft: 0,
            paddingRight: 0,
            ".summary .details-control-icon": {
              top: rem(5),
              transform: "none",
            },
          }}
        >
          <Box width={"100%"} maxWidth={rem(480)} pb={4}>
            <Panel
              preset="instance"
              rows={[
                {
                  render: (
                    <InstanceSelect
                      {...{ app }}
                      hasAvatar
                      pendingInstances={instances}
                      setPendingInstances={(
                        pendingInstances: PendingInstances
                      ) =>
                        setPendingRunGroupInstances(pendingInstances)(
                          pendingRunGroupIndex
                        )
                      }
                      isSingleSelect
                    />
                  ),
                  tooltipContent:
                    ensembleDefinitionTooltips.create.runGroupInstance.content,
                },
                {
                  render: (
                    <Flex width="100%" alignItems="center" relative>
                      <Input
                        htmlType="number"
                        placeholder="Enter a number"
                        value={parseInputTypeNumberDisplay(
                          pendingRunGroupRepetitions
                        )}
                        min={0}
                        step={1}
                        onChange={(e: { target: { value: number } }) =>
                          handlePendingRunGroupRepetitions(
                            e.target.value,
                            pendingRunGroupIndex
                          )
                        }
                        styles={{
                          fontFamily: theme.ui2Typography.fontFamilyCode,
                        }}
                      />

                      {pendingRunGroupRepetitions !== undefined && (
                        <Text
                          as="label"
                          styleName="meta-1"
                          mt={rem(3)}
                          ml={1}
                          styles={{
                            position: "absolute",
                            left: `calc(
                            ${theme.spacing.s6} + 
                            ${
                              pendingRunGroupRepetitions.toString().length - 1
                            } * 
                            ${rem(9)}
                          )
                        `,
                            color: theme.color.gray600,
                          }}
                        >
                          repetitions
                        </Text>
                      )}
                    </Flex>
                  ),
                  tooltipContent:
                    ensembleDefinitionTooltips.create.runGroupRepetitions
                      .content,
                },
              ]}
            />

            <Panel
              mt={2}
              preset="config"
              sectionTitle="Configuration"
              rows={[
                {
                  render: (
                    <Box width="100%">
                      <AddConfigOptions
                        addEmptyConfigOption={(e) =>
                          addEmptyRunGroupConfigOption(e)(pendingRunGroupIndex)
                        }
                        handleConfigOptionChange={({
                          value,
                          index,
                          pendingConfigOptions,
                          type,
                        }) =>
                          handlePendingRunGroupConfigOptionChange({
                            value,
                            index,
                            pendingConfigOptions,
                            type,
                          })(pendingRunGroupIndex)
                        }
                        pendingConfigOptions={pendingRunGroup.configOptions}
                        removeConfigOption={(e, index) =>
                          removePendingRunGroupConfigOption(
                            e,
                            index
                          )(pendingRunGroupIndex)
                        }
                        sectionTooltip={
                          ensembleDefinitionTooltips.create.runGroupConfig
                        }
                      />
                    </Box>
                  ),
                },
              ]}
            />
          </Box>
        </ControlPanel>
      );
    });
  };

  return (
    <>
      {renderPendingRunGroups()}

      <Box mt={5}>
        <Button2
          htmlType="button"
          type="outline"
          label="Add run group"
          onClick={(e: {
            preventDefault: () => void;
            stopPropagation: () => void;
          }) => addEmptyRunGroup(e)}
        />
      </Box>
    </>
  );
};

export default AddEnsembleRunGroups;
