import React, { useEffect, useMemo, useState } from "react";
import { Redirect, useLocation, useParams } from "react-router-dom";
import {
  AvatarRunProfile,
  Box,
  Button2,
  Flex,
  Input,
  Loading,
  RowDetail,
} from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";

import { trackEvent } from "../../../analytics";
import {
  IntegrationKind,
  RunProfile,
} from "../../../api/core/controlPlane.types";
import { useUser } from "../../../AuthProvider";
import ContactLinkErrorMessage from "../../../components/ContactLinkErrorMessage";
import Header from "../../../components/Header";
import { useMetaTitle } from "../../../components/Layout";
import { useIntegrations } from "../../../contexts/integrations/Integrations.context";
import { useRunProfiles } from "../../../contexts/runProfiles/RunProfiles.context";
import { standardInputValueByKey } from "../../../hooks/useStandardInputs";
import { IntegrationOption } from "../../Integrations/Integrations.types";
import DeleteAction from "../components/DeleteAction";
import IntegrationProfileSelect from "../components/Integrations.component";
import RunProfileDeprecationNotice from "../components/RunProfileDeprecationNotice";
import { emptyRp } from "../components/utils";
import { runProfileTooltipCopy } from "../data/microcopy";

// get query params
const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

const EditRunProfile = () => {
  const [, setMetaTitle] = useMetaTitle();
  const [{ features }] = useUser();

  const theme = useTheme();
  const { pathname } = useLocation();
  const params = useParams<{ id: string }>();
  const query = useQuery();
  const {
    getProfileById,
    editRunProfile,
    removeRunProfile,
    clearRunProfileError,
    runProfilesError,
    runProfilesLoading,
  } = useRunProfiles();

  const { getFullIntegrationInfo } = useIntegrations();
  const rp = React.useMemo(
    () => getProfileById(params.id),
    [getProfileById, params.id]
  );

  const [pendingProfile, setPendingProfile] = useState<RunProfile>(emptyRp);

  // build return URLs
  // if Edit button clicked from main Run Profiles list (?source=list),
  // return to main list, otherwise return to run profile details view
  const returnPathRunProfiles = pathname.split("/").slice(0, -2).join("/");
  const returnPath =
    query.get("source") === "list"
      ? returnPathRunProfiles
      : pathname.split("/").slice(0, -1).join("/");

  const [showDeleteProfile, setShowDeleteProfile] = useState(false);
  const [inputError, setInputErrors] = useState({ name: false });
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    return clearRunProfileError;
  }, [clearRunProfileError]);

  useEffect(() => {
    if (rp) {
      setMetaTitle(`Edit ${rp.name}`);

      return setPendingProfile({
        ...rp,
        integrations: rp.integrations?.length
          ? getFullIntegrationInfo(rp.integrations)
          : [],
      });
    }
  }, [rp, getFullIntegrationInfo, setMetaTitle]);

  const handleRunProfileSave = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    setSubmitted(false);

    if (!pendingProfile || !pendingProfile?.name) {
      return setInputErrors({ name: true });
    }

    // analytics tracking
    // rp = original data; pendingProfile = changed data
    if (rp) {
      // get array of integration types
      const rpIntegrationsTypes = rp.integrations?.map(
        (rpIntegration) => rpIntegration.type
      );
      const ppIntegrationsTypes = pendingProfile.integrations?.map(
        (pendingProfileIntegration) => pendingProfileIntegration.type
      );

      // get changed integrations (if any)
      const changedIntegrations = {
        integrationsRemoved:
          !!rpIntegrationsTypes?.length && ppIntegrationsTypes?.length === 0
            ? rpIntegrationsTypes
            : rpIntegrationsTypes?.filter(
                (rpIntegrationType) =>
                  !ppIntegrationsTypes?.includes(rpIntegrationType)
              ),
        integrationsAdded:
          !!ppIntegrationsTypes?.length && rpIntegrationsTypes?.length === 0
            ? ppIntegrationsTypes
            : ppIntegrationsTypes?.filter(
                (ppIntegrationType) =>
                  !rpIntegrationsTypes?.includes(ppIntegrationType)
              ),
      } as {
        [key: string]: IntegrationKind[];
      };

      // track run profile fields changed
      let rpKey: keyof RunProfile;
      for (rpKey in rp) {
        if (
          ["name", "description"].includes(rpKey) &&
          rp[rpKey] !== pendingProfile[rpKey]
        ) {
          trackEvent("Config", {
            view: "Edit Run Profile",
            action: "Field Changed",
            meta: {
              field: rpKey as "name" | "description",
            },
          });
        }
      }

      // track run profile integrations changed
      let changedIntegration: string;
      for (changedIntegration in changedIntegrations) {
        if (changedIntegrations[changedIntegration]) {
          if (changedIntegration === "integrationsRemoved") {
            changedIntegrations[changedIntegration].length &&
              trackEvent("Config", {
                view: "Edit Run Profile",
                action: "Integrations Removed",
                meta: {
                  type: changedIntegrations[
                    changedIntegration
                  ] as IntegrationKind[],
                },
              });
          }
          if (changedIntegration === "integrationsAdded") {
            changedIntegrations[changedIntegration].length &&
              trackEvent("Config", {
                view: "Edit Run Profile",
                action: "Integrations Added",
                meta: {
                  type: changedIntegrations[
                    changedIntegration
                  ] as IntegrationKind[],
                },
              });
          }
        }
      }
    }

    trackEvent("Config", {
      view: "Edit Run Profile",
      action: "Edit Run Profile Saved",
    });

    const { version, id, ...restPendingProfile } = pendingProfile;
    editRunProfile(params.id, {
      ...restPendingProfile,
      integrations:
        pendingProfile.integrations?.map((integration) => ({
          id: integration.id,
          type: integration.type,
          category: integration.category,
          class: integration.class,
        })) || [],
    });

    setSubmitted(true);
  };

  const confirmRunProfileDelete = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    return setShowDeleteProfile(true);
  };

  const deleteRunProfile = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    trackEvent("Config", {
      view: "Edit Run Profile",
      action: "Run Profile Deleted",
    });

    removeRunProfile(pendingProfile.id);

    setSubmitted(true);
  };

  const cancelDeleteRunProfile = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    return setShowDeleteProfile(false);
  };

  const handleIntegrationIntegrationClick = (
    integrationSelected: IntegrationOption | undefined
  ) => {
    if (integrationSelected) {
      setPendingProfile({
        ...pendingProfile,
        integrations: (pendingProfile.integrations || []).concat({
          id: integrationSelected.id,
          type: integrationSelected.type,
          name: integrationSelected.name,
          description: integrationSelected.description,
          category: integrationSelected.category,
          class: integrationSelected.class,
        }),
      });
    }
  };

  const handleIntegrationRemoveOnClick = (integrationId: string) => {
    setPendingProfile({
      ...pendingProfile,
      integrations:
        pendingProfile.integrations?.filter(
          (selectedIntegration) => selectedIntegration.id !== integrationId
        ) || [],
    });
  };

  const handleInputChange = <T extends "name" | "description">({
    target: { name, value },
  }: {
    target: { name: T; value: RunProfile[T] };
  }) => {
    setPendingProfile({
      ...pendingProfile,
      [name]: standardInputValueByKey[name](value || ""),
    });
  };

  if (runProfilesLoading) {
    return <Loading type="full-screen" dotColor={theme.color.orange500} />;
  }

  if (!submitted && runProfilesError) {
    return <ContactLinkErrorMessage errorMsg={runProfilesError} />;
  }

  if (submitted && !runProfilesError && !rp) {
    return <Redirect to={returnPathRunProfiles} />;
  }

  if ((submitted && !runProfilesError) || !rp) {
    return <Redirect to={returnPath} />;
  }

  return (
    <>
      <RunProfileDeprecationNotice />

      <Header
        configPageTitle={{
          label: `Edit ${rp.name}`,
          icon: <AvatarRunProfile size={32} />,
        }}
      />
      <Box mt={rem(-1)}>
        <form onSubmit={(e) => handleRunProfileSave(e)}>
          <RowDetail
            property="Name"
            secondaryLabel="For reference only"
            render={
              <Box width="100%" maxWidth={rem(408)}>
                <Input
                  name="name"
                  htmlType="text"
                  data-testid="name-input"
                  isError={inputError.name}
                  errorMessage="Please enter a name"
                  placeholder="Profile name"
                  value={pendingProfile?.name}
                  onChange={handleInputChange}
                />
              </Box>
            }
          />

          <RowDetail
            property="ID"
            secondaryLabel="Read-only"
            tooltipCopy={runProfileTooltipCopy.idEditView.content}
            tooltipExtraLinkLabel={
              runProfileTooltipCopy.idEditView.extraLinkLabel
            }
            tooltipExtraLinkUrl={runProfileTooltipCopy.idEditView.extraLinkUrl}
            render={
              <Box flexGrow={1} maxWidth={rem(408)}>
                <Input
                  htmlType="text"
                  isDisabled
                  placeholder="Run Profile ID"
                  value={rp.id}
                  readOnly
                />
              </Box>
            }
          />

          {
            <RowDetail
              property="Description"
              secondaryLabel="(optional)"
              render={
                <Box width="100%" maxWidth={rem(408)}>
                  <Input
                    name="description"
                    htmlType="text"
                    placeholder="Profile description"
                    value={pendingProfile.description}
                    onChange={handleInputChange}
                  />
                </Box>
              }
            />
          }

          {features?.onfleet && (
            <IntegrationProfileSelect
              {...{
                pendingIntegrations: pendingProfile.integrations || [],
                onAdd: handleIntegrationIntegrationClick,
                onRemove: handleIntegrationRemoveOnClick,
              }}
            />
          )}

          {!showDeleteProfile && (
            <Flex
              hasBorderTop
              hasBorderWidthThick
              pt={4}
              justifyContent="space-between"
            >
              <Flex alignItems="center">
                <Button2 size="large" htmlType="submit" label="Save" />
                <Button2
                  ml={2}
                  type="text"
                  size="large"
                  label="Cancel"
                  htmlType="button"
                  to={returnPath}
                  onClick={() =>
                    trackEvent("Config", {
                      view: "Edit Run Profile",
                      action: "Edit Run Profile Canceled",
                    })
                  }
                />
              </Flex>

              <Button2
                ml="auto"
                type="text"
                data-testid="delete-run-profile"
                label="Delete Run Profile"
                onClick={(e: { preventDefault: () => void }) =>
                  confirmRunProfileDelete(e)
                }
                styles={{
                  color: theme.color.red600,
                  "&:hover": {
                    color: theme.color.red700,
                  },
                  "&:focus, &:active": {
                    color: theme.color.red800,
                  },
                  "&:focus-visible": {
                    outlineWidth: rem(1.5),
                    outlineStyle: "solid",
                    outlineColor: theme.color.red900,
                  },
                }}
              />
            </Flex>
          )}
        </form>
        {runProfilesError && (
          <ContactLinkErrorMessage errorMsg={runProfilesError} />
        )}

        {showDeleteProfile && (
          <DeleteAction
            cancelDeleteItem={cancelDeleteRunProfile}
            deleteItem={deleteRunProfile}
            itemName={pendingProfile.name}
            itemTypeName={"run profile"}
          />
        )}
      </Box>
    </>
  );
};

export default EditRunProfile;
