import React, { useEffect } from "react";
import { Redirect, useLocation, useParams } from "react-router-dom";
import {
  Box,
  Flex,
  IconLock,
  Loading,
  Notification,
  RowDetail,
  Tag,
  Text,
} from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";

import { trackEvent } from "../../../../analytics";
import { Configuration } from "../../../../api/core/controlPlane.types";
import Header from "../../../../components/Header";
import { useMetaTitle } from "../../../../components/Layout";
import { useIntegrations } from "../../../../contexts/integrations/Integrations.context";
import { integrationsTooltipCopy } from "../../data/microcopy";
import { getIntegrationAvatar } from "../../helpers/avatars";
import { availableIntegrationConfig } from "../../Integrations.config";
import { PrivateInput, PublicInput } from "../../Integrations.types";
import FeatureCompatibility from "../FeatureCompatibility";

const InstalledIntegration = () => {
  const [, setMetaTitle] = useMetaTitle();
  const theme = useTheme();
  const { pathname } = useLocation();
  const { id } = useParams<{ id: string }>();
  const {
    getIntegrationById,
    integrationsLoading,
    integrationsError,
    updateIntegrationConfiguration,
  } = useIntegrations();

  const integration = getIntegrationById(id);

  useEffect(() => {
    updateIntegrationConfiguration(integration);
    setMetaTitle(integration?.name || "Integration");
  }, [integration, setMetaTitle, updateIntegrationConfiguration]);

  const isLoadingConfiguration =
    integration &&
    availableIntegrationConfig[integration.type].shouldLoadAdditionalConfig &&
    !integration?.configuration;

  if (integrationsLoading || isLoadingConfiguration) {
    return <Loading type="full-screen" dotColor={theme.color.orange500} />;
  }

  if (integrationsError) {
    return (
      <Notification
        mt={2}
        type="error"
        hasContactExtra
        message={integrationsError}
      />
    );
  }

  if (!integration) {
    return <Redirect to="/config/integrations/installed" />;
  }

  const integrationAvailable =
    availableIntegrationConfig[integration.type] || undefined;

  const privateInputs = integrationAvailable?.privateInputs;
  const publicInputs = integrationAvailable?.publicInputs;

  const renderPublicInput = (input: PublicInput) => {
    switch (input.type) {
      case "onfleetFeatureCompatibility":
        // we can assume config from integration is onfleet & typecast
        // because this input type only exists for onfleet integrations
        const configuration =
          integration?.configuration as Configuration["onfleet"];
        return (
          <FeatureCompatibility compatibility={configuration.compatibility} />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Header
        configActionButton={{
          label: "Edit",
          url: `${pathname}/edit`,
          onClick: () =>
            trackEvent("Integrations", {
              view: "Installed Detail",
              action: "Edit Button Clicked",
            }),
          isActionAllowed: true,
        }}
        configPageTitle={{
          label: integration?.name || "Integration",
          isEarlyAccess:
            integration &&
            availableIntegrationConfig[integration.type]?.isEarlyAccess,
          icon: integration && getIntegrationAvatar(integration.type, 32),
        }}
      />

      <Box mt={rem(-1)}>
        <RowDetail
          property="ID"
          tooltipCopy={integrationsTooltipCopy.id.content}
          tooltipExtraLinkLabel={integrationsTooltipCopy.id.extraLinkLabel}
          tooltipExtraLinkUrl={integrationsTooltipCopy.id.extraLinkUrl}
          render={
            <Flex>
              <Tag mb={-1} ml={rem(-6)} type="id" label={integration.id} />
            </Flex>
          }
        />

        {integration.description && (
          <RowDetail
            property="Description"
            render={
              <Flex>
                <Text styleName="body-2">{integration.description}</Text>
              </Flex>
            }
          />
        )}

        {privateInputs &&
          privateInputs.map((privateInput: PrivateInput) => (
            <RowDetail
              key={privateInput.id}
              property={privateInput.label}
              tooltipCopy={privateInput?.tooltipCopy?.content}
              tooltipExtraLinkLabel={privateInput?.tooltipCopy?.extraLinkLabel}
              tooltipExtraLinkUrl={privateInput?.tooltipCopy?.extraLinkUrl}
              render={
                <Flex alignItems="center">
                  <Text
                    mr={1}
                    styleName="body-2"
                    styles={{ color: theme.color.green700 }}
                  >
                    (Not shown)
                  </Text>
                  <IconLock iconColor={theme.color.green600} />
                </Flex>
              }
            />
          ))}

        {publicInputs &&
          publicInputs.map((input) => (
            <RowDetail
              key={input.id}
              property={input.label}
              secondaryLabel={input.secondaryLabel}
              tooltipCopy={input.tooltipCopy}
              render={renderPublicInput(input)}
            ></RowDetail>
          ))}
      </Box>
    </>
  );
};

export default InstalledIntegration;
