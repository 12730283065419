import React from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Button2,
  Flex,
  Loading,
  Notification,
  Tag,
  Text,
} from "@console/dsc";
import { BreakText } from "@console/dsc/src/components/PreviewRow2/PreviewRow2.styled";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";

import { OrganizationMember } from "../../../api/core/controlPlane.types";
import { useUser } from "../../../AuthProvider";
import Bin from "../../../components/Bin";
import { getAccUrl } from "../../../utils/navigation";

const TEAM_ADMIN_DISPLAY_LIMIT = 5;

type TeamAdminsProps = {
  teamMembersError: string;
  teamAdmins?: OrganizationMember[];
  teamRoot?: OrganizationMember;
};

const TeamAdmins = ({
  teamAdmins,
  teamMembersError,
  teamRoot,
}: TeamAdminsProps) => {
  const [{ email, id: accountId }] = useUser();
  const theme = useTheme();

  const renderTeamAdmin = (teamAdmin: OrganizationMember, isYou: boolean) => {
    return (
      <Flex
        key={teamAdmin.email}
        hasBorderTop
        width="100%"
        minHeight={theme.spacing.s8}
        pb={rem(2)}
        alignItems="center"
      >
        <Text
          as="h3"
          styleName="body-2"
          styles={{
            color: theme.color.gray800,
          }}
        >
          <BreakText>{teamAdmin.email}</BreakText>
        </Text>

        {isYou && (
          <Tag
            mt={rem(2)}
            ml={2}
            textColor={theme.color.gray800}
            backgroundColor={theme.color.gray200}
            label="you"
          />
        )}
      </Flex>
    );
  };

  const teamMembersUrl = getAccUrl(accountId, "/team/members");

  const renderContent = () => {
    if (!teamRoot && !teamAdmins) {
      return <Loading />;
    }
    if (teamMembersError) {
      return (
        <Notification size="small" message={teamMembersError} type="error" />
      );
    }

    return (
      <>
        {teamRoot && renderTeamAdmin(teamRoot, teamRoot.email === email)}
        {teamAdmins &&
          teamAdmins
            .slice(0, TEAM_ADMIN_DISPLAY_LIMIT)
            .map((teamAdmin) =>
              renderTeamAdmin(teamAdmin, teamAdmin.email === email)
            )}
      </>
    );
  };

  return (
    <Bin isMini width="100%">
      <Flex minHeight="100%" flexDirection="column" pt={3} pr={3} pb={4} pl={3}>
        <Text styleName="header-2" styles={{ color: theme.color.gray800 }}>
          Team Admins
        </Text>
        <Text mt={rem(3)} styleName="meta-1" hasLinkGray>
          <Link to={teamMembersUrl}>More</Link>
        </Text>

        <Box mt={6}>{renderContent()}</Box>

        {teamAdmins && teamAdmins.length > TEAM_ADMIN_DISPLAY_LIMIT && (
          <Flex mt={2}>
            <Button2
              type="outline-quiet"
              label="View all"
              to={teamMembersUrl}
            />
          </Flex>
        )}
      </Flex>
    </Bin>
  );
};

export default TeamAdmins;
