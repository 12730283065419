import * as React from "react";
import { useTheme } from "@emotion/react";

import { rem } from "../../lib/tools";
import Text from "../Text";

import { TooltipContent, TooltipPlace } from "./Tooltip.styled";
import { TooltipProps } from "./Tooltip.types";

const Tooltip = ({
  children,
  direction,
  extraLinkLabel,
  extraLinkUrl,
  hexColorString,
  m,
  mt,
  mr,
  mb,
  ml,
  orientation,
}: TooltipProps) => {
  const theme = useTheme();

  const thisDirection = direction || "right";
  const thisOrientation = orientation || "top";

  return (
    <TooltipPlace {...{ m, mt, mr, mb, ml }} hexColorString={hexColorString}>
      <TooltipContent
        direction={thisDirection}
        orientation={thisOrientation}
        width={rem(230)}
        py={2}
        px={3}
        background={theme.color.gray800T}
      >
        <Text
          as="div"
          styleName="meta-1"
          styles={{ color: theme.color.gray100 }}
        >
          {children}
        </Text>
        {!!extraLinkUrl && (
          <Text
            as="a"
            mt={1}
            styleName="meta-2-bold"
            href={extraLinkUrl}
            target="_blank"
            styles={{
              display: "block",
              color: theme.color.white,
            }}
          >
            {extraLinkLabel || "More"}
          </Text>
        )}
      </TooltipContent>
    </TooltipPlace>
  );
};

export default Tooltip;
