import { Box, Flex, IconCheck, IconX, Text } from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";
import { isEmpty } from "lodash";

import { CompatibilityConfiguration } from "../../../../api/core/controlPlane.types";
import Toggle from "../../../../components/Toggle";

import useFeatureCompatibility from "./hooks/useFeatureCompatibility";
import { getCompatibilityItemValue } from "./utils/featureCompatibility";
import { COMPATIBILITY_ITEMS } from "./constants";
import { CompatibilityValueObjectType, CompatibilityValues } from "./types";

export type FeatureCompatibilityProps = {
  compatibility?: CompatibilityConfiguration;
  onCompatibilityValueChange?: (newValues: CompatibilityValues) => void;
  isEditable?: boolean;
};

export default function FeatureCompatibility({
  compatibility = {},
  onCompatibilityValueChange,
  isEditable = false,
}: FeatureCompatibilityProps) {
  const theme = useTheme();
  const {
    compatibilityValues,
    handleToggleAll,
    handleCheckboxClick,
    isAllToggled,
  } = useFeatureCompatibility({ compatibility, onCompatibilityValueChange });

  const renderUneditableItem = (
    item: { id: string; label: string },
    type: CompatibilityValueObjectType
  ) => {
    const itemValue = getCompatibilityItemValue(item.id, type, compatibility);
    return (
      <Flex mt={1} alignItems="center" key={`${type}-${item.id}`}>
        {itemValue ? (
          <IconCheck iconColor={theme.color.green500} />
        ) : (
          <IconX iconColor={theme.color.red500} />
        )}
        <Text
          mt={rem(-1)}
          ml={1}
          styleName="body-2"
          styles={{
            color: itemValue ? theme.color.gray800 : theme.color.gray600,
          }}
        >
          {item.label}
        </Text>
      </Flex>
    );
  };

  const renderEditableItem = (
    item: { id: string; label: string },
    type: CompatibilityValueObjectType
  ) => {
    // if a specific value is missing from the compatibilityValues
    // object, it should default to "true" since a user is by default opted in
    // to parsing all Onfleet features
    const isActive =
      item.id in compatibilityValues[type]
        ? compatibilityValues[type][item.id]
        : true;

    return (
      <Toggle
        key={type + item.id}
        htmlId={type + item.id}
        data-testid={type + item.id}
        mt={1}
        isActive={isActive}
        label={item.label}
        size="x-small"
        onClickFunc={() => handleCheckboxClick(type, item.id)}
        bgColor={theme.color.gray600}
      />
    );
  };

  const renderItems = (type: CompatibilityValueObjectType) => {
    const itemsToRender = COMPATIBILITY_ITEMS[type];

    const sortedItems =
      // if compatibility is empty, simply render all items from the config
      isEditable || isEmpty(compatibility)
        ? itemsToRender
        : // sort disabled items to the bottom
          [...itemsToRender].sort(
            (a, b) =>
              Number(compatibility[type]?.[b.id]) -
              Number(compatibility[type]?.[a.id])
          );

    return sortedItems.map((task) =>
      isEditable
        ? renderEditableItem(task, type)
        : renderUneditableItem(task, type)
    );
  };

  const renderColumnHeader = (type: CompatibilityValueObjectType) => {
    // header should be darker if at least 1 toggle switch is "on"
    const headerColor = Object.values(compatibilityValues[type]).some(
      (value) => value
    )
      ? theme.color.gray800
      : theme.color.gray600;

    return (
      <Text
        mb={1}
        styleName="body-2-bold"
        styles={{
          textTransform: "capitalize",
          color: headerColor,
        }}
      >
        {type}
      </Text>
    );
  };

  return (
    <Box>
      {isEditable && (
        <Toggle
          mt={[2, 2, 0]}
          mb={2}
          onClickFunc={handleToggleAll}
          isActive={isAllToggled}
          label="Parse all compatible Onfleet features (default)"
          data-testid="toggle-all-compatibility-items"
          bgColor={theme.color.gray600}
        />
      )}
      <Flex mt={isEditable ? 3 : 1}>
        <Flex mr={10} flexDirection="column">
          {renderColumnHeader("tasks")}
          {renderItems("tasks")}
        </Flex>
        <Flex flexDirection="column">
          {renderColumnHeader("workers")}
          {renderItems("workers")}
        </Flex>
      </Flex>
    </Box>
  );
}
