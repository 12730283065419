import React from "react";
import { Box, Flex, Tag, Text } from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";
import { kebabCase } from "lodash";

import {
  StyledPreviewItemInnerFlex,
  StyledPreviewItemLink,
  StyledTagList,
} from "./PreviewItem.styled";
import { PreviewItemProps } from "./PreviewItem.types";

const PreviewItem = ({
  avatar,
  description,
  isBeta,
  isFeatured,
  metatag,
  m,
  mt,
  mr,
  mb,
  ml,
  name,
  onClick,
  renderExtra,
  renderExtraTop,
  tags,
  url,
}: PreviewItemProps) => {
  const theme = useTheme();

  const renderPreviewItem = () => {
    return (
      <StyledPreviewItemInnerFlex
        {...{ isFeatured, m, mt, mr, mb, ml, onClick }}
        className="preview-item"
        flexDirection="column"
        width="100%"
        hasBorder
        isRounded
        noShrink
        relative
        pt={4}
        pr={5}
        pb={4}
        pl={4}
      >
        {isFeatured && (
          <Text
            as="span"
            className="preview-item-featured-tag"
            styleName="meta-1-bold"
            mt={-4}
            ml={-4}
            mr={-5}
            styles={{
              display: "block",
              paddingTop: theme.spacing.s1,
              paddingBottom: theme.spacing.s1,
              textAlign: "center",
              color: theme.color.white,
              backgroundColor: theme.color.yellow600,
              borderTopLeftRadius: theme.border.radiusSmall,
              borderTopRightRadius: theme.border.radiusSmall,
            }}
          >
            Featured
          </Text>
        )}
        {avatar && <Box mr={2}>{avatar}</Box>}

        <Flex flexDirection="column" flexGrow={1}>
          {renderExtraTop && renderExtraTop}

          <Flex alignItems="baseline">
            <Text
              as="h2"
              styleName="header-2"
              mt={2}
              styles={{ color: theme.color.gray800 }}
              data-testid={`preview-item-title-${kebabCase(name)}`}
            >
              {name}
            </Text>
            {isBeta && (
              <Tag ml={1} size="small" type="early-access" label="beta" />
            )}
          </Flex>

          {metatag && (
            <Text
              styleName="body-3"
              mt={rem(1)}
              styles={{ color: theme.color.gray600 }}
            >
              {metatag}
            </Text>
          )}

          {description && (
            <Text
              mt={metatag ? 2 : 1}
              styleName="body-2"
              styles={{ color: theme.color.gray800 }}
            >
              {description}
            </Text>
          )}

          {tags && (
            <StyledTagList mt={4}>
              {tags.map((tag, index) => (
                <Tag
                  key={`${tag}-${index}`}
                  mr={1}
                  label={tag}
                  textColor={theme.color.sky600}
                  backgroundColor={theme.color.sky100}
                  styles={{ span: { textTransform: "capitalize" } }}
                />
              ))}
            </StyledTagList>
          )}

          {renderExtra && (
            <Box mt="auto" pt={3}>
              {renderExtra}
            </Box>
          )}
        </Flex>
      </StyledPreviewItemInnerFlex>
    );
  };

  return url ? (
    <StyledPreviewItemLink to={url} {...{ isFeatured }}>
      {renderPreviewItem()}
    </StyledPreviewItemLink>
  ) : (
    renderPreviewItem()
  );
};

export default PreviewItem;
