import * as React from "react";
import { Flex, Text } from "@console/dsc";
import { useTheme } from "@emotion/react";

import { getLabelColor, getStyleName } from "./utils/presets";
import { ToggleButton } from "./Toggle.styles";
import { ToggleProps } from "./Toggle.types";

const Toggle = ({
  bgColor,
  htmlId,
  isActive,
  isDisabled,
  label,
  onClickFunc,
  size,
  m,
  mt,
  mr,
  mb,
  ml,
  ...rest
}: ToggleProps) => {
  const theme = useTheme();

  return (
    <Flex alignItems="center" {...{ m, mt, mr, mb, ml }}>
      <ToggleButton
        {...{ isDisabled, size }}
        id={htmlId}
        type="button"
        bgColor={bgColor || theme.color.teal600}
        onClick={isDisabled ? () => null : onClickFunc}
        aria-pressed={isActive ? "true" : "false"}
        aria-label={isActive ? "On" : "Off"}
        aria-disabled={isDisabled ? "true" : "false"}
        disabled={isDisabled}
        {...rest}
      />

      <Text
        pl={size === "x-small" ? 1 : 2}
        styleName={getStyleName({ isActive, size })}
        styles={{
          color: getLabelColor({ isActive, isDisabled, theme }),
          ...(isDisabled && { cursor: "not-allowed" }),
        }}
      >
        {label}
      </Text>
    </Flex>
  );
};

export default Toggle;
