import React, { useMemo } from "react";
import { Box, Select } from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";

import { trackEvent, TrackEvents } from "../../../analytics/functions";
import {
  ExecutionClassType,
  UserAccessExecutionClass,
} from "../../../api/core/dataPlane.types";
import {
  EC_8C_16GB_12H,
  EC_16C_60GB_12H,
  EC_DEFAULT,
} from "../../../config/apps";
import { ExecutionClassOption } from "../App.types";
import {
  checkIsPremiumExecutionClass,
  getExecutionClassDescription,
} from "../utils/instances";

const ExecutionClassSelect = <T extends keyof TrackEvents>({
  availableExecutionClasses,
  isSmall,
  pendingExecutionClass,
  trackEventCategory,
  trackEventProperties,
  setPendingExecutionClass,
}: {
  pendingExecutionClass: ExecutionClassType;
  setPendingExecutionClass: React.Dispatch<
    React.SetStateAction<ExecutionClassType>
  >;
  availableExecutionClasses?: UserAccessExecutionClass[];
  isSmall?: boolean;
  trackEventCategory?: T;
  trackEventProperties?: TrackEvents[T];
}) => {
  const executionClassOptions: ExecutionClassOption[] = useMemo(() => {
    if (!availableExecutionClasses) return [];

    // convert returned execution classes to select options and
    // filter out deprecated execution classes
    const availableExecutionClassOptions = availableExecutionClasses.reduce(
      (ecOptions, ec) => {
        if ([EC_DEFAULT, EC_8C_16GB_12H, EC_16C_60GB_12H].includes(ec.class)) {
          ecOptions.push({
            id: ec.class,
            description: getExecutionClassDescription(ec.class),
            isPremium: checkIsPremiumExecutionClass(ec.class),
          } as ExecutionClassOption);
        }
        return ecOptions;
      },
      [] as ExecutionClassOption[]
    );

    // add default option if not present
    if (!availableExecutionClasses.some((ec) => ec.class === EC_DEFAULT)) {
      availableExecutionClassOptions.unshift({
        id: EC_DEFAULT,
        description: getExecutionClassDescription(EC_DEFAULT),
        isPremium: false,
        isDefault: true,
      });
    }

    return availableExecutionClassOptions;
  }, [availableExecutionClasses]);

  const getExecutionClassOptionFromId = (
    executionClass: ExecutionClassType
  ): ExecutionClassOption => {
    const selectedExecutionClassOption = executionClassOptions.find(
      (ecOption) => {
        return ecOption.id === executionClass;
      }
    );
    if (selectedExecutionClassOption) return selectedExecutionClassOption;
    return executionClassOptions[0];
  };

  const updateExecutionClassSelection = (
    selectedExecutionClass: ExecutionClassOption
  ) => {
    setPendingExecutionClass(selectedExecutionClass.id);
    return;
  };

  return (
    <Box width="100%" maxWidth={rem(408)}>
      <Select
        testId="select-instance-execution-class"
        type="execution-class"
        placeholder="Select execution class"
        noOptionsMessage={() => "No execution classes"}
        options={executionClassOptions}
        getOptionValue={(selectedInstance: ExecutionClassOption) =>
          selectedInstance.id
        }
        value={
          pendingExecutionClass &&
          getExecutionClassOptionFromId(pendingExecutionClass)
        }
        onChange={(selection: ExecutionClassOption) => {
          trackEventCategory &&
            trackEventProperties &&
            trackEvent(trackEventCategory, trackEventProperties);
          updateExecutionClassSelection(selection);
        }}
        {...(isSmall && {
          size: "small",
        })}
      />
    </Box>
  );
};

export default ExecutionClassSelect;
