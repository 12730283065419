import { Link } from "react-router-dom";
import { Box, Flex, InputCheckbox, Tag, Text } from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { CellContext, HeaderContext } from "@tanstack/react-table";
import { DateTime } from "luxon";

import {
  RunsData,
  RunsDatum,
} from "../../../../../api/core/controlPlane.types";
import { getAccUrl } from "../../../../../utils/navigation";
import { maskUserEmail } from "../../../../../utils/user";
import { getExecutionClassDescription } from "../../../utils/instances";
import { getStatusTextColor } from "../../RunDetails/utils/getStatusTextColor";
import {
  GetRunHistoryHeadersParams,
  Table2HeaderObj,
} from "../RunHistory.types";

import { isSwitchbackRunWithPlanId } from "./getRunHistoryItems";

const historyHasKey = (key: string, experimentRuns: RunsData) => {
  return (
    !!experimentRuns && experimentRuns.some((run) => run.hasOwnProperty(key))
  );
};

export const getChildOfQueryParam = (
  childOf?: string,
  childOfName?: string
) => {
  let queryString = "";
  if (childOf) {
    queryString += `?child_of=${childOf}`;

    if (childOfName) {
      queryString += `?child_of_name=${childOfName}`;
    }
  }
  return queryString;
};

export const getRunHistoryTableHeaders = ({
  accId,
  appId,
  childOf,
  childOfName,
  groupEditIds,
  groupEditHandleCheck,
  groupEditHandleCheckAll,
  highlightId,
  isActiveGroupEditCheckAll,
  isGroupEdit,
  isLinkTargetBlank,
  isScenarioTest,
  runHistory,
  scenarioConfigOptionsHeaders = [],
  statsHeaders = [],
  theme,
  showRunName,
  showPlanUnitIndex = false,
}: GetRunHistoryHeadersParams): Table2HeaderObj[] => {
  return [
    ...(isGroupEdit
      ? [
          {
            id: "group_edit_id",
            accessorKey: "group_edit_id",
            label: "",
            enableColumnFilter: false,
            enableSorting: false,
            header: (_props: HeaderContext<RunsDatum, any>) => {
              return (
                <InputCheckbox
                  ml={1}
                  data-testid="group-edit-run-all"
                  checked={isActiveGroupEditCheckAll}
                  handleChange={groupEditHandleCheckAll}
                  id="group-edit-check-all"
                  value="group-edit-check-all"
                />
              );
            },
            cell: (props: CellContext<RunsDatum, any>) => {
              const runId = props.getValue();
              const isGroupEditIdActive =
                groupEditIds &&
                groupEditIds.find((groupEditId) => groupEditId === runId);
              return (
                <Flex alignItems="center">
                  <Box height={theme.spacing.s4}>
                    <InputCheckbox
                      mt={rem(-2)}
                      data-testid={`group-edit-run-${runId}`}
                      checked={isGroupEditIdActive}
                      handleChange={groupEditHandleCheck}
                      id={runId}
                      value={runId}
                    />
                  </Box>
                </Flex>
              );
            },
          },
        ]
      : []),
    {
      id: "id",
      accessorKey: "id",
      header: "Run Id",
      label: "Run Id",
      cell: (props) => {
        const runId = props.getValue();
        const isHighlighted = runId === highlightId;

        return (
          <Flex alignItems="center">
            <Link
              to={getAccUrl(
                accId,
                `/app/${appId}/run/${runId}${getChildOfQueryParam(
                  childOf,
                  childOfName
                )}`
              )}
              id={runId}
              target={isLinkTargetBlank ? "_blank" : undefined}
            >
              {runId}
            </Link>
            {isHighlighted && (
              <Tag
                ml={rem(6)}
                label="Best"
                size="small"
                textColor={theme.color.yellow800}
                backgroundColor={theme.color.yellow200}
              />
            )}
          </Flex>
        );
      },
    },
    ...(showPlanUnitIndex && isSwitchbackRunWithPlanId(runHistory)
      ? [
          {
            id: "plan_unit_index",
            accessorKey: "plan_unit_index",
            header: "Plan Unit Index",
            label: "Plan Unit Index",
            cell: (props: CellContext<RunsDatum, any>) => {
              return props.getValue();
            },
          },
        ]
      : []),
    ...(showRunName
      ? [
          {
            id: "name",
            accessorKey: "name",
            header: "Name",
            label: "Name",
            cell: (props: CellContext<RunsDatum, any>) => {
              const cellValue = props.getValue();
              return cellValue ? (
                cellValue
              ) : (
                <Text
                  as="span"
                  styleName="body-3"
                  styles={{ color: theme.color.gray500 }}
                >
                  None
                </Text>
              );
            },
          },
        ]
      : []),
    ...(historyHasKey("input_id", runHistory)
      ? [
          {
            id: "input-id",
            accessorKey: "input_id",
            header: "Input Id",
            label: "Input Id",
            cell: (props: CellContext<RunsDatum, any>) => {
              const cellValue = props.getValue();
              return cellValue ? (
                cellValue
              ) : (
                <Text
                  as="span"
                  styleName="body-3"
                  styles={{ color: theme.color.gray500 }}
                >
                  Not Available
                </Text>
              );
            },
          },
        ]
      : []),
    {
      id: "status_v2",
      accessorKey: "status_v2",
      header: "Status",
      label: "Status",
      cell: (props) => {
        const cellValue = props.getValue();
        return (
          <Text
            styles={{
              color: getStatusTextColor({ status: cellValue, theme }),
            }}
            as="span"
            styleName="body-3"
          >
            {cellValue}
          </Text>
        );
      },
    },
    {
      id: "run_type",
      accessorKey: "run_type",
      header: "Type",
      label: "Type",
      cell: (props) => {
        const cellValue = props.getValue();

        return (
          <Text as="span" styleName="body-3">
            {cellValue}
          </Text>
        );
      },
    },
    ...(isScenarioTest
      ? [
          {
            id: "scenario_id",
            accessorKey: "scenario_id",
            header: "Scenario Id",
            label: "Scenario Id",
            cell: (props: CellContext<RunsDatum, any>) => {
              return (
                <Text as="span" styleName="body-3">
                  {props.getValue()}
                </Text>
              );
            },
          },
          {
            id: "input_set_id",
            accessorKey: "input_set_id",
            header: "Input Set ID",
            label: "Input Set ID",
            cell: (props: CellContext<RunsDatum, any>) => {
              return (
                <Text as="span" styleName="body-3">
                  {props.getValue()}
                </Text>
              );
            },
          },
          {
            id: "repetition",
            accessorKey: "repetition",
            header: "Repetition",
            label: "Repetition",
            cell: (props: CellContext<RunsDatum, any>) => {
              return (
                <Text as="span" styleName="body-3">
                  {props.getValue() || 0}
                </Text>
              );
            },
          },
          {
            id: "option_set",
            accessorKey: "option_set",
            header: "Option Set",
            label: "Option Set",
            cell: (props: CellContext<RunsDatum, any>) => {
              return (
                <Text as="span" styleName="body-3">
                  {props.getValue()}
                </Text>
              );
            },
          },
          ...scenarioConfigOptionsHeaders,
        ]
      : []),
    {
      id: "created_at",
      accessorKey: "created_at",
      header: "Date",
      label: "Date",
      cell: (props) => {
        const cellValue = props.getValue();
        return (
          <time
            dateTime={cellValue}
            title={DateTime.fromISO(cellValue).toFormat(
              "yyyy-MM-dd 'T'HH:mm:ssZZZ (ZZZZ)"
            )}
          >
            {DateTime.fromISO(cellValue).toFormat(
              "yyyy-MM-dd · h:mm:ss a ZZZZ"
            )}
          </time>
        );
      },
    },
    {
      id: "user_email",
      accessorKey: "user_email",
      header: "User",
      label: "User",
      cell: (props) => {
        const cellValue = props.getValue();
        return cellValue && cellValue.includes("@") ? (
          <Text as="span" styleName="body-3">
            {maskUserEmail(cellValue)}
          </Text>
        ) : (
          <Text
            as="span"
            styleName="body-3"
            styles={{ color: theme.color.gray500 }}
          >
            Unknown
          </Text>
        );
      },
    },
    {
      id: "application_id",
      accessorKey: "application_id",
      header: "App Id",
      label: "App Id",
      cell: (props) => {
        return (
          <Text as="span" styleName="body-3">
            {props.getValue()}
          </Text>
        );
      },
    },
    {
      id: "application_instance_id",
      accessorKey: "application_instance_id",
      header: "Instance Id",
      label: "Instance Id",
      cell: (props: CellContext<RunsDatum, any>) => {
        const cellValue = props.getValue();
        return cellValue ? (
          <Link
            to={getAccUrl(accId, `/app/${appId}/instance/${cellValue}`)}
            target="_blank"
            rel="noreferrer"
          >
            {cellValue}
          </Link>
        ) : (
          <Text
            as="span"
            styleName="body-3"
            styles={{ color: theme.color.gray500 }}
          >
            None
          </Text>
        );
      },
    },
    {
      id: "application_version_id",
      accessorKey: "application_version_id",
      header: "Version Id",
      label: "Version Id",
      cell: (props) => {
        const cellValue = props.getValue();
        return cellValue ? (
          <Link
            to={getAccUrl(accId, `/app/${appId}/version/${cellValue}`)}
            target="_blank"
            rel="noreferrer"
          >
            {cellValue}
          </Link>
        ) : (
          <Text
            as="span"
            styleName="body-3"
            styles={{ color: theme.color.gray500 }}
          >
            None
          </Text>
        );
      },
    },
    {
      id: "execution_class",
      accessorKey: "execution_class",
      header: "Execution Class",
      label: "Execution Class",
      cell: (props) => {
        const cellValue = props.getValue();
        return cellValue ? (
          <span title={getExecutionClassDescription(cellValue)}>
            {cellValue}
          </span>
        ) : (
          <Text
            as="span"
            styleName="body-3"
            styles={{ color: theme.color.gray500 }}
          >
            Not reported
          </Text>
        );
      },
    },
    ...statsHeaders,
  ];
};
