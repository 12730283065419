import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Button2,
  Flex,
  IconExternalLink,
  Notification,
  Text,
} from "@console/dsc";
import { useTheme } from "@emotion/react";

import { UserOrganization } from "../../../../api/core/dataPlane.types";
import { useUser } from "../../../../AuthProvider";
import FreeTrialSignup from "../../../../components/FreeTrialSignup";
import FreeTrialSummary from "../../../../components/FreeTrialSignup/components/FreeTrialSummary";
import { FREE_TRIAL_MODAL_TITLE } from "../../../../components/FreeTrialSignup/data/constants";
import Header from "../../../../components/Header";
import { useMetaTitle } from "../../../../components/Layout";
import Modal2 from "../../../../components/Modal2";
import { switchToOrg } from "../../../../components/OrgSwitcher/utils/switchToOrg";
import StandardError from "../../../../components/StandardError";
import useTeam from "../../../../hooks/useTeam";
import { TEXT_MEASURE_MAX_WIDTH } from "../../../../utils/constants";
import { getAccUrl } from "../../../../utils/navigation";
import { ModalClickEventProps } from "../../Team.types";
import ConfirmLeaveTeamModal from "../Details/components/ConfirmModal";
import {
  ExitModalFuncParams,
  LeaveTeamFuncParams,
} from "../Details/Details.types";
import { getRootOrg } from "../Members/utils/isCurrentUserRoot";

type NoTeamsAccessProps = {
  isRootUser: boolean;
  team?: UserOrganization;
};

const pageTitle = "No Teams Access";

const NoTeamsAccess = ({ isRootUser, team }: NoTeamsAccessProps) => {
  const [user] = useUser();
  const { features, organizations } = user;
  const theme = useTheme();
  const history = useHistory();
  const [, setMetaTitle] = useMetaTitle();

  const { isLeaveTeamProcessing, leaveTeam, leaveTeamError } = useTeam();
  const [leaveTeamConfirm, setLeaveTeamConfirm] = useState<UserOrganization>();

  const [displayTrialModal, setDisplayTrialModal] = useState(false);

  const rootOrg = getRootOrg(organizations);

  useEffect(() => {
    setMetaTitle(pageTitle);
  }, [setMetaTitle]);

  const handleLeaveTeamConfirmModal = ({ e, team }: LeaveTeamFuncParams) => {
    e.preventDefault();
    setLeaveTeamConfirm(team);
  };

  const handleLeaveTeam = async ({ e, team }: LeaveTeamFuncParams) => {
    e.preventDefault();
    await leaveTeam(team.id, rootOrg?.id || "");

    setLeaveTeamConfirm(undefined);
    return;
  };

  const exitConfirmLeaveTeam = ({ e }: ExitModalFuncParams) => {
    e.preventDefault();
    setLeaveTeamConfirm(undefined);
    return;
  };

  if (leaveTeamError) return <StandardError errorMessage={leaveTeamError} />;

  const handleModalCloseOnClick = (e: ModalClickEventProps) => {
    e && e.preventDefault();
    e && e.stopPropagation();

    return setDisplayTrialModal(false);
  };

  const renderNonRootNoAccessContent = () => {
    return (
      <>
        <Notification
          type="notice"
          message={`Team access ${
            team?.name ? `for ${team.name}` : ""
          } is prevented. This account does not have teams enabled. Contact the account owner for more information.`}
        />

        <Flex mt={4}>
          {team && (
            <Button2
              mr={2}
              label="Leave team"
              onClick={(e: any) => handleLeaveTeamConfirmModal({ e, team })}
            />
          )}
          {leaveTeamConfirm && (
            <ConfirmLeaveTeamModal
              {...{ leaveTeamProcessing: isLeaveTeamProcessing }}
              leaveTeam={handleLeaveTeam}
              exitModal={exitConfirmLeaveTeam}
              team={leaveTeamConfirm}
            />
          )}

          <Button2
            type="outline-quiet"
            htmlType="button"
            label="Go back to your account"
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
              switchToOrg({
                e,
                history,
                isCurrentTeam: false,
                teamId:
                  organizations?.find((org) => org.role === "root")?.id || "",
              })
            }
          />
        </Flex>
      </>
    );
  };

  const renderNoAccessContent = () => {
    return (
      <>
        <Notification
          type="notice"
          message={
            <Text styleName="body-2" hasLinkBlack>
              <Link to={getAccUrl(user.id, "/team/plan")}>
                Upgrade your account
              </Link>{" "}
              to access teams (includes a 14-day free trial)
            </Text>
          }
        />

        <Text mt={4} styleName="body-1">
          Nextmv Teams gives you the ability to invite other members to your
          account. Team members are granted their own API keys and share in all
          the features of your account. Collaborate on model development, allow
          teammates to run your apps from the browser and more!
        </Text>

        <Flex mt={4}>
          <Button2
            type="primary"
            label="Start a free trial"
            to={getAccUrl(user.id, "/team/plan")}
          />

          <Button2
            ml={2}
            type="outline-quiet"
            href="https://www.nextmv.io/docs/platform/teams"
            isUrlExternal
            label="Teams in Docs"
            icon={<IconExternalLink iconColor={theme.color.gray600} />}
          />
        </Flex>

        {displayTrialModal && !features?.publicruns && (
          <Modal2
            htmlId="modal-start-free-trial"
            isActive={displayTrialModal}
            title={FREE_TRIAL_MODAL_TITLE}
            summary={<FreeTrialSummary />}
            closeActionOnClick={(e: {
              preventDefault: () => void;
              stopPropagation: () => void;
            }) => handleModalCloseOnClick(e)}
          >
            <FreeTrialSignup />
          </Modal2>
        )}
      </>
    );
  };

  return (
    <>
      <Header configPageTitle={{ label: pageTitle }} />

      <Flex mt={5} flexDirection="column" maxWidth={TEXT_MEASURE_MAX_WIDTH}>
        {isRootUser ? renderNoAccessContent() : renderNonRootNoAccessContent()}
      </Flex>
    </>
  );
};

export default NoTeamsAccess;
