import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { AvatarWebhook, Box, Flex, Input, RowDetail, Tag } from "@console/dsc";

import { trackEvent } from "../../../../analytics";
import { CreateWebhookPayload } from "../../../../api/core/controlPlane.types";
import { useUser } from "../../../../AuthProvider";
import Footer from "../../../../components/Footer";
import Header from "../../../../components/Header";
import { useMetaTitle } from "../../../../components/Layout";
import PlanNotice from "../../../../components/PlanNotice";
import { INPUT_WIDTH_STANDARD } from "../../../../config/general";
import { useWebhooks } from "../../../../contexts/webhooks/Webhooks.context";
import useManageWebhook from "../../../../hooks/useManageWebhook";
import useStandardInputs from "../../../../hooks/useStandardInputs";
import { getIsUserPaid } from "../../../../utils/authProviderHelpers";
import { handleInputChange } from "../../../../utils/inputHelpers";
import useReturnPaths from "../../../App/hooks/useReturnPaths";
import { webhookTooltips } from "../../data/microcopy";
import { RUN_STATUS_EVENT_TYPE } from "../../utils/constants";

const pageTitle = "New webhook";

const NewWebhook = () => {
  const [user] = useUser();
  const [, setMetaTitle] = useMetaTitle();
  const { returnPath, returnPathList } = useReturnPaths();

  const isUserPaid = getIsUserPaid(user);

  const [isProcessing, setIsProcessing] = useState(false);
  const [endpointUrl, setEndpointUrl] = useState("");
  const [inputsError, setInputsError] = useState({ endpointUrl: false });
  const eventTypes = [RUN_STATUS_EVENT_TYPE];

  const { loadWebhooks } = useWebhooks();
  const {
    addWebhookEntity: addWebhook,
    isWebhookEntityAdded: isWebhookAdded,
    webhookEntityAddError: webhookAddError,
  } = useManageWebhook("webhooks");

  const {
    getStandardInputsProps,
    pendingStandardInputs,
    standardInputsErrors,
  } = useStandardInputs(undefined, "webhooks");

  // page display
  useEffect(() => {
    setMetaTitle(pageTitle);
  }, [setMetaTitle]);

  // disable loading state if add new webhook error
  useEffect(() => {
    if (webhookAddError && isProcessing) {
      setIsProcessing(false);
    }
  }, [isProcessing, webhookAddError]);

  const handleWebhookCreate = async (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    e.preventDefault();
    e.stopPropagation();

    setIsProcessing(true);

    let payload: CreateWebhookPayload = {
      ...pendingStandardInputs,
      endpoint_url: endpointUrl,
      event_types: eventTypes,
    };

    await addWebhook("", payload);
  };

  const handleCancel = () => {
    trackEvent("Webhooks", {
      view: "Create Webhook",
      action: "Create Webhook Canceled",
    });
    return;
  };

  if (isWebhookAdded) {
    trackEvent("Webhooks", {
      view: "Create Webhook",
      action: "New Webhook Created",
    });

    loadWebhooks({ shouldPaginate: true });

    return <Redirect to={returnPathList} />;
  }

  const isActionButtonDisabled =
    !isUserPaid ||
    !pendingStandardInputs.id ||
    !!standardInputsErrors.id ||
    !endpointUrl;

  return (
    <>
      <Header
        configPageTitle={{
          ancestorIcon: <AvatarWebhook size={24} />,
          label: pageTitle,
          ancestorLabel: "Webhooks",
          ancestorUrl: returnPathList,
        }}
      />

      <PlanNotice mb={4} type="webhooks" />

      <form>
        <RowDetail
          property="ID"
          tooltipCopy={webhookTooltips.idCreate.content}
          render={
            <Box width="100%" maxWidth={INPUT_WIDTH_STANDARD}>
              <Input
                {...getStandardInputsProps({
                  placeholder: "Webhook ID",
                  testId: "new-webhook-id-input",
                  type: "id",
                  trackEventCategory: "Webhooks",
                  trackEventProperties: {
                    view: "Create Webhook",
                    action: "Webhook ID Changed",
                  },
                })}
              />
            </Box>
          }
        />

        <RowDetail
          property="Description"
          secondaryLabel="(optional)"
          render={
            <Box width="100%" maxWidth={INPUT_WIDTH_STANDARD}>
              <Input
                {...getStandardInputsProps({
                  placeholder: "Webhook description",
                  testId: "new-webhook-description-input",
                  type: "description",
                  trackEventCategory: "Webhooks",
                  trackEventProperties: {
                    view: "Create Webhook",
                    action: "Field Entered",
                    meta: {
                      field: "description",
                    },
                  },
                })}
              />
            </Box>
          }
        />

        <RowDetail
          property="Endpoint URL"
          render={
            <Box width="100%" maxWidth={INPUT_WIDTH_STANDARD}>
              <Input
                placeholder="Webhook endpoint URL"
                testId="new-webhook-endpoint-url-input"
                errorMessage={"Please enter an endpoint URL"}
                errorMessageTestId={"endpoint-url-input-error"}
                htmlType="text"
                name="endpointUrl"
                isError={inputsError.endpointUrl}
                isRequired
                onBlur={() => {
                  if (!endpointUrl) {
                    setInputsError({ ...inputsError, endpointUrl: true });
                  }
                  trackEvent("Webhooks", {
                    view: "Create Webhook",
                    action: "Field Entered",
                    meta: {
                      field: "endpointUrl",
                    },
                  });
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setInputsError({ ...inputsError, endpointUrl: false });
                  handleInputChange(e, setEndpointUrl);
                }}
                value={endpointUrl}
              />
            </Box>
          }
        />

        <RowDetail
          property="Event Types"
          secondaryLabel="Read-only"
          testId="webhook-row-event-types"
          render={eventTypes.map((eventType) => (
            <Flex key={eventType}>
              <Tag size="large" type="id" label={eventType} />
            </Flex>
          ))}
        />

        <Footer
          actionButtonLabel="Create webhook"
          webhookEndpoint="webhooks"
          error={webhookAddError}
          handleCancel={handleCancel}
          handleMainAction={handleWebhookCreate}
          isActionButtonLoading={isProcessing}
          isActionButtonDisabled={isActionButtonDisabled}
          returnPath={returnPath}
          returnPathList={returnPathList}
          view="create"
        />
      </form>
    </>
  );
};

export default NewWebhook;
