import React from "react";
import { IconRouteHaversine, IconRouteRoad } from "@console/dsc";
import { useTheme } from "@emotion/react";

import { MapDisplayMode } from "../../../../Map.types";
import {
  MAP_DISPLAY_APPROXIMATE,
  MAP_DISPLAY_ROAD,
} from "../../../../utils/constants";

import { IconRouteToggleButton, RouteToggleFlex } from "./RouteToggle.styled";

export type RouteToggleProps = {
  isMapMenuActive: boolean;
  isRouteToggleDisabled: boolean;
  mapDisplayMode: MapDisplayMode;
  setMapDisplayMode: (mode: MapDisplayMode) => void;
};

export default function RouteToggle({
  isMapMenuActive,
  isRouteToggleDisabled,
  mapDisplayMode,
  setMapDisplayMode,
}: RouteToggleProps) {
  const theme = useTheme();

  const handleRouteToggle = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    mode: MapDisplayMode
  ) => {
    e.preventDefault();

    setMapDisplayMode(mode);
  };

  return (
    <RouteToggleFlex
      {...{ isMapMenuActive }}
      mr={3}
      alignItems="center"
      isDisabled={isRouteToggleDisabled}
    >
      <IconRouteToggleButton
        isLeft
        isActive={mapDisplayMode === MAP_DISPLAY_ROAD}
        isDisabled={isRouteToggleDisabled}
        onClick={(e) => handleRouteToggle(e, MAP_DISPLAY_ROAD)}
        data-testid="map-route-toggle-road"
      >
        <IconRouteRoad
          iconSize={28}
          iconColor={
            isRouteToggleDisabled
              ? theme.color.gray300
              : mapDisplayMode === MAP_DISPLAY_ROAD
              ? theme.color.teal600
              : theme.color.gray400
          }
        />
      </IconRouteToggleButton>

      <IconRouteToggleButton
        isRight
        isActive={mapDisplayMode === MAP_DISPLAY_APPROXIMATE}
        isDisabled={isRouteToggleDisabled}
        onClick={(e) => handleRouteToggle(e, MAP_DISPLAY_APPROXIMATE)}
        data-testid="map-route-toggle-haversine"
      >
        <IconRouteHaversine
          iconSize={28}
          iconColor={
            isRouteToggleDisabled
              ? theme.color.gray300
              : mapDisplayMode === MAP_DISPLAY_APPROXIMATE
              ? theme.color.teal600
              : theme.color.gray400
          }
        />
      </IconRouteToggleButton>
    </RouteToggleFlex>
  );
}
