import { rem } from "@console/dsc/src/lib/tools";
import { spacing } from "@console/dsc/src/tokens/space";

// style constants
export const HEADER_HEIGHT = rem(63);
export const HEADER_HEIGHT_MD_NAV_EXTRA = rem(97);
export const MAIN_NAV_MOBILE_HEIGHT = spacing.s9;
export const MAIN_NAV_SIDEBAR_WIDTH = rem(68);
export const SECTION_NAV_SIDEBAR_WIDTH = rem(272);

export const BANNER_HEIGHT_MOBILE = spacing.s10;
export const BANNER_HEIGHT = spacing.s8;

export const SITE_TOP_BORDER_RADIUS = rem(7);
export const ORG_HEADER_HEIGHT = spacing.s8;
export const ORG_HEADER_HEIGHT_NUM = 40;
export const ORG_SWITCHER_WIDTH = rem(270);
export const ORG_SWITCHER_MAX_HEIGHT = rem(480);
export const PROFILE_MENU_WIDTH = rem(196);

export const TEXT_MEASURE_MAX_WIDTH = rem(720);

// contact link
export const NEXTMV_CONTACT_LINK = "https://www.nextmv.io/contact";

// apps
export const DATA_RENDER_STYLE_LIMIT_BYTES: number = 300000;
export const SHADOW_TEST_DEFAULT_MAX_RUNS: number = 300;
export const SWITCHBACK_TEST_MAX_PLAN_UNIT: number = 1000;
export const SWITCHBACK_TEST_MIN_PLAN_UNIT: number = 1;
export const SWITCHBACK_TEST_MAX_PLAN_UNIT_DURATION_MINUTES: number = 10080;
export const SWITCHBACK_TEST_MIN_PLAN_UNIT_DURATION_MINUTES: number = 1;
export const ENTITY_ID_CHAR_COUNT_MAX = 40;
export const ENTITY_ID_CHAR_COUNT_MIN = 2;
export const ENTITY_NAME_CHAR_COUNT_MAX = 64;
export const ENTITY_NAME_CHAR_COUNT_MIN = 3;
export const LATEST_INSTANCE_ID = "latest";
