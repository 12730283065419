import React from "react";
import { CaptionProps, useNavigation } from "react-day-picker";
import { Flex, IconChevronLeft, IconChevronRight, Text } from "@console/dsc";
import { useTheme } from "@emotion/react";
import { DateTime } from "luxon";

import { StyledNavButton } from "../DatePicker.styled";
import { navigateToMonth } from "../utils/monthNavigator";

const CustomCaptionSingle = (props: CaptionProps) => {
  const theme = useTheme();
  const { goToMonth, nextMonth, previousMonth } = useNavigation();

  const { displayMonth } = props;
  const isPrevDisabled = !previousMonth;
  const isNextDisabled = !nextMonth;

  return (
    <Flex mb={2} pb={3} alignItems="center" hasBorderBottom>
      <StyledNavButton
        isDisabled={isPrevDisabled}
        aria-disabled={isPrevDisabled}
        disabled={isPrevDisabled}
        onClick={(e) => navigateToMonth({ e, goToMonth, month: previousMonth })}
      >
        <IconChevronLeft iconSize={20} iconColor={theme.color.gray500T} />
      </StyledNavButton>

      <Text
        as="h4"
        mr="auto"
        ml="auto"
        styleName="body-1-bold"
        styles={{ color: theme.color.gray800 }}
      >
        {DateTime.fromJSDate(displayMonth).toFormat("LLLL yyyy")}
      </Text>

      <StyledNavButton
        isDisabled={isNextDisabled}
        aria-disabled={isNextDisabled}
        disabled={isNextDisabled}
        onClick={(e) => navigateToMonth({ e, goToMonth, month: nextMonth })}
      >
        <IconChevronRight iconSize={20} iconColor={theme.color.gray500T} />
      </StyledNavButton>
    </Flex>
  );
};

export default CustomCaptionSingle;
