import { useEffect, useMemo } from "react";
import { Switch } from "react-router-dom";
import { Loading } from "@console/dsc";
import { useTheme } from "@emotion/react";

import { useUser } from "../AuthProvider";
import { useAppsCollection } from "../contexts/apps/Apps.context";
import IntegrationsProvider from "../contexts/integrations/Integrations.context";
import MarketplaceProvider from "../contexts/marketplace/Marketplace.context";
import RunProfilesProvider from "../contexts/runProfiles/RunProfiles.context";
import WebhooksProvider from "../contexts/webhooks/Webhooks.context";
import { isCurrentUserRoot } from "../pages/Team/subpages/Members/utils/isCurrentUserRoot";
import { useIsNewUser } from "../utils";
import { checkHasTeamsAccess } from "../utils/authProviderHelpers";

import {
  getAuthenticatedRoutes,
  noAccessTeamsRoutes,
} from "./authenticatedConfig";
import { usePageTracking } from "./hooks";
import { createRoutes } from "./utils";

const AuthenticatedRoutes = () => {
  const [user] = useUser();
  const { features, id: accountId, organizations, roles } = user;
  const theme = useTheme();

  const [isNewUser, setIsNewUser] = useIsNewUser();
  const { apps, appsError, loadApps } = useAppsCollection();

  // load apps into context if not already present
  useEffect(() => {
    if (!apps && !appsError) {
      loadApps({});
    }
  }, [apps, appsError, loadApps]);

  const userHasApps: boolean = useMemo(() => {
    return !!apps && !!apps.length;
  }, [apps]);

  const authenticatedRoutes = getAuthenticatedRoutes(
    features,
    userHasApps,
    accountId || ""
  );

  const isUserNonRootOnUnpaidAccount =
    !!accountId &&
    !isCurrentUserRoot(organizations, roles) &&
    !checkHasTeamsAccess(user);

  usePageTracking();

  if (userHasApps && isNewUser) {
    setIsNewUser(false);
  }

  return (
    <IntegrationsProvider>
      <RunProfilesProvider>
        <MarketplaceProvider>
          <WebhooksProvider>
            {!apps ? (
              <Loading type="full-screen" dotColor={theme.color.orange500} />
            ) : (
              <Switch>
                {isUserNonRootOnUnpaidAccount
                  ? createRoutes(noAccessTeamsRoutes, features, accountId)
                  : createRoutes(authenticatedRoutes, features, accountId)}
              </Switch>
            )}
          </WebhooksProvider>
        </MarketplaceProvider>
      </RunProfilesProvider>
    </IntegrationsProvider>
  );
};

export default AuthenticatedRoutes;
