import React, { useEffect } from "react";
import { Redirect, useLocation, useParams } from "react-router-dom";
import {
  AvatarRunProfile,
  Box,
  Flex,
  Loading,
  PreviewRow2,
  RowDetail,
  Tag,
  Text,
} from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";

import { trackEvent } from "../../../analytics";
import { useUser } from "../../../AuthProvider";
import ContactLinkErrorMessage from "../../../components/ContactLinkErrorMessage";
import Header from "../../../components/Header";
import { useMetaTitle } from "../../../components/Layout";
import { useIntegrations } from "../../../contexts/integrations/Integrations.context";
import { useRunProfiles } from "../../../contexts/runProfiles/RunProfiles.context";
import { getIntegrationAvatar } from "../../Integrations/helpers/avatars";
import RunProfileDeprecationNotice from "../components/RunProfileDeprecationNotice";
import { getIntegrationConfigByType } from "../components/utils";
import { runProfileTooltipCopy } from "../data/microcopy";

const RunProfile = () => {
  const [user] = useUser();
  const { features } = user;
  const [, setMetaTitle] = useMetaTitle();
  const theme = useTheme();
  const { pathname } = useLocation();
  const params = useParams();
  const { getProfileById, runProfilesError, runProfilesLoading } =
    useRunProfiles();
  const { getFullIntegrationInfo } = useIntegrations();

  const rp = getProfileById(params?.id);

  useEffect(() => {
    setMetaTitle(rp?.name || "");
  }, [setMetaTitle, rp]);

  if (runProfilesLoading) {
    return <Loading type="full-screen" dotColor={theme.color.orange500} />;
  }

  if (runProfilesError) {
    return <ContactLinkErrorMessage errorMsg={runProfilesError} />;
  }

  if (!rp) {
    return <Redirect to="/config/run-profiles" />;
  }

  const integrationDetails = getFullIntegrationInfo(rp.integrations || []);

  return (
    <>
      <RunProfileDeprecationNotice />

      <Header
        configActionButton={{
          label: "Edit",
          url: `${pathname}/edit`,
          onClick: () =>
            trackEvent("Config", {
              view: "Run Profile",
              action: "Edit Button Clicked",
            }),
          isActionAllowed: true,
        }}
        configPageTitle={{
          label: rp?.name || "",
          icon: <AvatarRunProfile size={32} />,
        }}
      />
      <Box mt={rem(-1)}>
        <RowDetail
          property="ID"
          tooltipCopy={runProfileTooltipCopy.id.content}
          tooltipExtraLinkLabel={runProfileTooltipCopy.id.extraLinkLabel}
          tooltipExtraLinkUrl={runProfileTooltipCopy.id.extraLinkUrl}
          render={
            <Flex>
              <Tag mb={-1} ml={rem(-6)} type="id" label={rp?.id} />
            </Flex>
          }
        />

        {rp.description && (
          <RowDetail
            property="Description"
            render={
              <Box maxWidth={rem(680)}>
                <Text styleName="body-2">{rp.description}</Text>
              </Box>
            }
          />
        )}

        {features?.onfleet && (
          <>
            {!!integrationDetails.length ? (
              <RowDetail
                property="Integrations"
                render={integrationDetails.map((integration) => {
                  const integrationConfig = getIntegrationConfigByType(
                    integration.type
                  );
                  return (
                    <Box mt={-3}>
                      <PreviewRow2
                        key={integration.id}
                        hasNoBorder
                        icon={
                          <Box mt={1}>
                            {getIntegrationAvatar(integration.type, 36)}
                          </Box>
                        }
                        tag={
                          integrationConfig?.isEarlyAccess ? (
                            <Tag ml={1} mb={-1} type="early-access" />
                          ) : undefined
                        }
                        name={integration.name || ""}
                        id={
                          integration.type
                            ? integration.type.charAt(0).toUpperCase() +
                              integration.type.slice(1)
                            : ""
                        }
                      />
                    </Box>
                  );
                })}
              />
            ) : (
              <RowDetail
                property="Integrations"
                render={
                  <Text
                    styleName="body-2"
                    styles={{ color: theme.color.gray600 }}
                  >
                    None
                  </Text>
                }
              />
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default RunProfile;
