import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { Box, Button2, Flex, Notification, Text } from "@console/dsc";
import { useTheme } from "@emotion/react";

import useManageEntity from "../../hooks/useManageEntity";
import useManageWebhook from "../../hooks/useManageWebhook";
import { getEntityTypeFromEndpoint } from "../../utils/entities/getEntityTypeFromEndpoint";

import { getIsDeleteSupported } from "./utils/getIsDeleteSupported";
import {
  getActionButtonLabel,
  getEntityErrorTestId,
  getFooterButtonTestId,
} from "./utils/getPropsByView";
import { FooterProps } from "./Footer.types";

const Footer = ({
  app,
  actionButtonLabel,
  actionButtonMinWidth,
  confirmContent,
  endpoint: appEndpoint,
  entityId,
  error,
  handleCancel,
  handleMainAction,
  handlePostDelete,
  handleSecondaryAction,
  isActionButtonDisabled,
  isActionButtonLoading,
  isSecondaryButtonDisabled,
  isSecondaryButtonLoading,
  isConfirm,
  isWebhookEntity,
  returnPath,
  returnPathList,
  secondaryButtonLabel,
  secondaryButtonMinWidth,
  webhookEndpoint,
  view,
}: FooterProps) => {
  // Webhook entities are handled separately from app entities
  const endpoint = isWebhookEntity ? webhookEndpoint : appEndpoint;
  // TEMPORARY until delete entity feature fully supported
  const isDeleteSupported = isWebhookEntity
    ? !!webhookEndpoint && getIsDeleteSupported(webhookEndpoint)
    : !!endpoint && getIsDeleteSupported(endpoint);

  const theme = useTheme();

  const [isConfirmDelete, setIsConfirmDelete] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const {
    removeEntity: removeAppEntity,
    entityRemoveError: appEntityRemoveError,
    isEntityRemoved: isAppEntityRemoved,
  } = useManageEntity(appEndpoint);

  const {
    removeWebhookEntity,
    webhookEntityRemoveError,
    isWebhookEntityRemoved,
  } = useManageWebhook(webhookEndpoint);

  const isEntityRemoved = isWebhookEntity
    ? isWebhookEntityRemoved
    : isAppEntityRemoved;

  const entityRemoveError = isWebhookEntity
    ? webhookEntityRemoveError
    : appEntityRemoveError;

  const handleDeleteCancel = (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    e.preventDefault();
    e.stopPropagation();

    setIsConfirmDelete(false);
    return;
  };

  const handleDeleteConfirm = (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    e.preventDefault();
    e.stopPropagation();

    setIsConfirmDelete(true);
    return;
  };

  const handleDeleteInternal = async (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    e.preventDefault();
    e.stopPropagation();

    if (isWebhookEntity && entityId) {
      setIsSubmitted(true);
      await removeWebhookEntity(entityId);
    } else if (app && !isWebhookEntity) {
      setIsSubmitted(true);
      await removeAppEntity(app.id, entityId);
    }
  };

  const entityErrorTestId = getEntityErrorTestId(view);
  const actionButtonTestId = getFooterButtonTestId(view);
  const secondaryButtonTestId = getFooterButtonTestId(view, true);
  const entityType = getEntityTypeFromEndpoint(endpoint, true);

  const renderMainViewControls = () => {
    return (
      <>
        <Button2
          testId={actionButtonTestId}
          isDisabled={isActionButtonDisabled}
          isLoading={isActionButtonLoading}
          label={actionButtonLabel || getActionButtonLabel(view)}
          size={view === "list" ? "default" : "large"}
          htmlType="submit"
          onClick={handleMainAction}
          {...(actionButtonMinWidth && {
            styles: {
              minWidth: actionButtonMinWidth,
            },
          })}
        />

        {secondaryButtonLabel && (
          <Button2
            testId={secondaryButtonTestId}
            ml={2}
            isDisabled={isSecondaryButtonDisabled}
            isLoading={isSecondaryButtonLoading}
            label={secondaryButtonLabel}
            size="large"
            type="outline"
            htmlType="button"
            onClick={handleSecondaryAction}
            {...(secondaryButtonMinWidth && {
              styles: {
                minWidth: secondaryButtonMinWidth,
              },
            })}
          />
        )}

        {handleCancel && returnPath && (
          <Button2
            testId="cancel-entity-button"
            mr="auto"
            ml={2}
            type="text"
            size="large"
            label="Cancel"
            to={returnPath}
            onClick={handleCancel}
          />
        )}

        {isDeleteSupported && view === "edit" && (
          <Button2
            testId="delete-entity-button"
            ml="auto"
            type="text"
            label="Delete"
            onClick={handleDeleteConfirm}
            styles={{
              color: theme.color.red600,
              "&:hover": {
                color: theme.color.red700,
              },
              "&:focus, &:active": {
                color: theme.color.red800,
              },
              "&:focus-visible": {
                outlineWidth: theme.border.widthMediumShadowOnly,
                outlineStyle: "solid",
                outlineColor: theme.color.red900,
              },
            }}
          />
        )}
      </>
    );
  };

  const renderDeleteControls = () => {
    return (
      <Flex width="100%">
        <Box>
          <Text styleName="body-1-bold" styles={{ color: theme.color.gray800 }}>
            Are you sure you want to delete this {entityType}?
          </Text>

          <Text mt={1} styleName="body-2">
            This action cannot be undone.
          </Text>

          <Button2
            data-testid="confirm-delete-entity-button"
            isDisabled={isSubmitted}
            isLoading={isSubmitted}
            mt={3}
            size="large"
            label={`Delete ${getEntityTypeFromEndpoint(endpoint, true)}`}
            onClick={handleDeleteInternal}
            {...(!isSubmitted && {
              styles: {
                backgroundColor: theme.color.red500,
                "&:hover": {
                  backgroundColor: theme.color.red600,
                },
                "&:focus, &:active": {
                  backgroundColor: theme.color.red700,
                },
                "&:focus-visible": {
                  outlineWidth: theme.border.widthMediumShadowOnly,
                  outlineStyle: "solid",
                  outlineColor: theme.color.red900,
                },
              },
            })}
          />
        </Box>

        <Button2
          isDisabled={isSubmitted}
          ml="auto"
          type="text"
          label="Cancel"
          onClick={handleDeleteCancel}
        />
      </Flex>
    );
  };

  const renderFooterContents = () => {
    if (isConfirm && confirmContent) {
      return confirmContent;
    }
    if (!isConfirmDelete) {
      return renderMainViewControls();
    }
    if (view === "edit" && isConfirmDelete) {
      return renderDeleteControls();
    }
  };

  const footerError = error || entityRemoveError;

  if (isSubmitted && !footerError && isEntityRemoved && returnPathList) {
    isDeleteSupported && handlePostDelete && handlePostDelete();

    return <Redirect to={returnPathList} />;
  }

  return (
    <Flex
      pt={4}
      pb={8}
      flexWrap="wrap"
      flexDirection="row"
      justifyContent="space-between"
      hasBorderTop
      hasBorderWidthThick
    >
      {footerError && (
        <Box width="100%">
          <Notification
            data-testid={entityErrorTestId}
            mt={1}
            mb={5}
            type="error"
            hasContactExtra
            message={footerError}
          />
        </Box>
      )}

      {renderFooterContents()}
    </Flex>
  );
};

export default Footer;
