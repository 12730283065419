import { Flex } from "@console/dsc";
import styled from "@emotion/styled/macro";

import {
  BANNER_HEIGHT,
  BANNER_HEIGHT_MOBILE,
  MAIN_NAV_SIDEBAR_WIDTH,
  ORG_HEADER_HEIGHT,
  SECTION_NAV_SIDEBAR_WIDTH,
} from "../../utils/constants";

import {
  GetMainMarginLeftWidthParams,
  GetSiteContainerHeightParams,
  MainProps,
} from "./Layout.types";

const getMainMarginLeftWidth = ({
  context,
  hasSectionNav,
}: GetMainMarginLeftWidthParams) => {
  if (context === "md" && hasSectionNav) {
    return `calc(${MAIN_NAV_SIDEBAR_WIDTH} + ${SECTION_NAV_SIDEBAR_WIDTH})`;
  }

  return MAIN_NAV_SIDEBAR_WIDTH;
};

const getSiteContainerHeight = ({
  breakpoint,
  hasBanner,
}: GetSiteContainerHeightParams) => {
  if (breakpoint === "sm") {
    return hasBanner
      ? `calc(100vh - ${BANNER_HEIGHT} - ${ORG_HEADER_HEIGHT})`
      : `calc(100vh - ${ORG_HEADER_HEIGHT})`;
  }

  // mobile
  return hasBanner
    ? `calc(100vh - ${BANNER_HEIGHT_MOBILE} - ${ORG_HEADER_HEIGHT})`
    : `calc(100vh - ${ORG_HEADER_HEIGHT})`;
};

export const SiteContainer = styled(Flex)`
  width: calc(100%);
  min-height: ${(props) =>
    getSiteContainerHeight({
      hasBanner: props.hasBanner,
    })};
  background-color: ${({ theme }) => theme.color.white};

  @media (min-width: ${({ theme }) => theme.breakpoint.sm}) {
    min-height: ${(props) =>
      getSiteContainerHeight({
        breakpoint: "sm",
        hasBanner: props.hasBanner,
      })};
  }
`;

export const Main = styled.main<MainProps>`
  box-sizing: border-box;
  display: block;
  max-width: 100%;
  flex-grow: 1;
  margin-left: 0;
  overflow-x: hidden;
  padding-right: ${({ theme }) => theme.spacing.s4};
  padding-left: ${({ theme }) => theme.spacing.s4};

  @media (min-width: ${({ theme }) => theme.breakpoint.sm}) {
    max-width: calc(
      100% -
        ${(props) =>
          getMainMarginLeftWidth({
            context: "sm",
            hasSectionNav: props.hasSectionNav,
          })}
    );
    margin-left: ${(props) =>
      getMainMarginLeftWidth({
        context: "sm",
        hasSectionNav: props.hasSectionNav,
      })};
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.md}) {
    max-width: calc(
      100% -
        ${(props) =>
          getMainMarginLeftWidth({
            context: "md",
            hasSectionNav: props.hasSectionNav,
          })}
    );
    margin-left: ${(props) =>
      getMainMarginLeftWidth({
        context: "md",
        hasSectionNav: props.hasSectionNav,
      })};
    padding-right: ${({ theme }) => theme.spacing.s7};
    padding-left: ${({ theme }) => theme.spacing.s6};
  }
`;
