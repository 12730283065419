import React from "react";
import { Link } from "react-router-dom";
import { Box, Notification, Text } from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";

import { useUser } from "../../AuthProvider";
import { getIsUserPaid } from "../../utils/authProviderHelpers";
import { getAccUrl } from "../../utils/navigation";

import { PlanNoticeProps, PlanNoticeType } from "./PlanNotice.types";

const getPlanNoticeMessage = (type: PlanNoticeType): string => {
  switch (type) {
    case "custom-apps":
      return "Running custom applications remotely is not available with the free plan.";
    case "ensembling":
      return "Ensemble runs and definitions are not available with the free plan.";
    case "webhooks":
      return "Webhooks are not available with the free plan.";

    default:
      return "";
  }
};

const PlanNotice = ({ app, type, m, mt, mr, mb, ml }: PlanNoticeProps) => {
  const [user] = useUser();
  const { id: accountId } = user;
  const theme = useTheme();
  const isUserPaid = getIsUserPaid(user);

  if (isUserPaid) return null;
  if (type === "custom-apps" && app?.type === "subscription") {
    return null;
  }

  return (
    <Box data-testid="plan-notice" mt={mt || 4} {...{ m, mr, mb, ml }}>
      <Notification
        type="notice"
        message={
          <Text
            styleName="body-2"
            styles={{
              "a, a:visited": {
                paddingBottom: rem(1),
                fontWeight: theme.ui2Typography.fontWeightBody2Bold,
                textDecoration: "none",
                color: theme.color.yellow800,
                borderBottomWidth: theme.border.width,
                borderBottomStyle: theme.border.style,
                borderBottomColor: theme.color.gray300T,
              },
              "a:hover, a:focus, a:active": {
                color: theme.color.yellow900,
                borderBottomColor: theme.color.gray400T,
              },
              "a:focus-visible": {
                outline: 0,
                borderRadius: theme.border.radius,
                boxShadow: `0 0 0 ${theme.border.widthMediumShadowOnly} ${theme.color.yellow700}`,
              },
            }}
          >
            {getPlanNoticeMessage(type)}{" "}
            <Link to={getAccUrl(accountId, "/team/plan")}>
              View plans and pricing
            </Link>
          </Text>
        }
      />
    </Box>
  );
};

export default PlanNotice;
