import { Box, Text } from "@console/dsc";
import {
  formatNumber,
  formatSecondsToMinsSecs,
  rem,
} from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";

import { MainViewType } from "../../../../../../pages/App/subpages/RunDetails/RunDetails.types";
import { formatGeoCoordsForDisplay } from "../../../../../../utils/positionHelpers";
import { formatDateTimeFromServer } from "../../../../../../utils/timeHelpers";
import { MarkerPoint, RouteSetByID } from "../../../../Map.types";
import { isVehicleStartOrEnd } from "../../../../utils/mapping";
import { formatNumberForDisplay, getStopColor } from "../../utils/mapMarkers";

export type VehicleAndStopPopupContentProps = {
  point: MarkerPoint;
  activeTab: MainViewType;
  routesById?: RouteSetByID;
};

export default function VehicleAndStopPopupContent({
  point,
  activeTab,
  routesById,
}: VehicleAndStopPopupContentProps) {
  const theme = useTheme();

  const renderTime = (
    label: string,
    time: string,
    date?: string,
    timezone?: string
  ) => {
    return (
      <>
        <Text
          pt={1}
          styleName="meta-1-bold"
          styles={{ color: theme.color.gray800 }}
        >
          {label}: {time.toLowerCase()}
          {timezone && (
            <Text
              ml={1}
              as="span"
              styleName="meta-2"
              styles={{
                textTransform: "uppercase",
                letterSpacing: "0.01em",
                color: theme.color.gray600,
              }}
            >
              ({timezone})
            </Text>
          )}
          {date && (
            <Text as="span" ml={1} styleName="meta-1">
              {date}{" "}
            </Text>
          )}
        </Text>
      </>
    );
  };

  const renderTimes = () => {
    if (!point.isAssigned) {
      return <Text styleName="meta-1">Unassigned</Text>;
    }

    const {
      eta,
      etd,
      ets,
      travel_duration,
      cumulative_travel_duration,
      travel_distance,
      cumulative_travel_distance,
    } = point;

    const [etaTime, etaDate, etaTimezone] = formatDateTimeFromServer(eta || "");
    const [etsTime, etsDate, etsTimezone] = formatDateTimeFromServer(ets || "");
    const [etdTime, etdDate, etdTimezone] = formatDateTimeFromServer(etd || "");

    return (
      (etaTime || etsTime || etdTime) && (
        <Box pt={1}>
          {etaTime && renderTime("ETA", etaTime, etaDate, etaTimezone)}
          {etsTime && renderTime("ETS", etsTime, etsDate, etsTimezone)}
          {etdTime && renderTime("ETD", etdTime, etdDate, etdTimezone)}
          {travel_duration && (
            <Text
              pt={1}
              styleName="meta-1-bold"
              styles={{ color: theme.color.gray800 }}
            >
              Duration:{" "}
              <Text
                as="span"
                styleName="body-3-bold"
                styles={{ color: theme.color.gray800 }}
              >
                {formatSecondsToMinsSecs(travel_duration)}
                <Text as="span" ml={1} styleName="meta-1">
                  {cumulative_travel_duration && (
                    <>({formatSecondsToMinsSecs(cumulative_travel_duration)})</>
                  )}
                </Text>
              </Text>
            </Text>
          )}
          {travel_distance && (
            <Text
              styleName="meta-1-bold"
              styles={{ color: theme.color.gray800 }}
            >
              Distance{" "}
              <Text
                as="span"
                styleName="body-3-bold"
                styles={{ color: theme.color.gray800 }}
              >
                {formatNumber(travel_distance, true)}
                <Text as="span" ml={1} styleName="meta-1">
                  {cumulative_travel_distance && (
                    <>({formatNumber(cumulative_travel_distance, true)})</>
                  )}
                </Text>
              </Text>
            </Text>
          )}
        </Box>
      )
    );
  };

  const timeDataMarkup = renderTimes();

  const renderVehicleSummaryDatum = (
    label: string,
    value: number,
    testId?: string
  ) => {
    return (
      <>
        <Text {...{ testId }} pt={2} styleName="meta-2">
          {label}
        </Text>
        <Text
          pt={rem(2)}
          styleName="meta-1-bold"
          styles={{ color: theme.color.gray800 }}
        >
          {formatNumberForDisplay(value)}
        </Text>
      </>
    );
  };

  const selectedRoute =
    !!routesById && !!point.vehicleId && routesById[point.vehicleId];
  const routeDuration = selectedRoute && selectedRoute.route_duration;
  const stopsDuration = selectedRoute && selectedRoute.stops_duration;
  const travelTime = selectedRoute && selectedRoute.travel_time;
  const travelDistance = selectedRoute && selectedRoute.travel_distance;
  const waitingDuration = selectedRoute && selectedRoute.waiting_duration;

  const shouldRenderRouteTotals =
    selectedRoute && activeTab === "result" && isVehicleStartOrEnd(point.name);

  return (
    <>
      <Text
        styleName="meta-1-bold"
        styles={{
          color: point.color ? getStopColor(point.color) : theme.color.gray700,
        }}
      >
        {point.name}
      </Text>

      <Text
        pt={rem(2)}
        styleName="meta-1-bold"
        styles={{ color: theme.color.gray600 }}
      >
        {formatGeoCoordsForDisplay(point.position)}
      </Text>

      {activeTab === "result" &&
        !isVehicleStartOrEnd(point.name) &&
        timeDataMarkup && <>{timeDataMarkup}</>}

      {shouldRenderRouteTotals && (
        <>
          {routeDuration &&
            renderVehicleSummaryDatum(
              "Total time (includes wait and service times)",
              routeDuration,
              "vehicle-popup-total-time"
            )}
          {travelTime &&
            renderVehicleSummaryDatum(
              "Total travel time",
              travelTime,
              "vehicle-popup-total-travel-time"
            )}
          {stopsDuration &&
            renderVehicleSummaryDatum(
              "Total service time",
              stopsDuration,
              "vehicle-popup-total-service-time"
            )}
          {waitingDuration &&
            renderVehicleSummaryDatum(
              "Total wait time",
              waitingDuration,
              "vehicle-popup-total-wait-time"
            )}
          {travelDistance &&
            renderVehicleSummaryDatum(
              "Total route distance",
              travelDistance,
              "vehicle-popup-total-route-distance"
            )}
        </>
      )}
    </>
  );
}
