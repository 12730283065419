import React, { useEffect, useMemo, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import {
  Box,
  Button2,
  Flex,
  Input,
  Loading,
  Notification,
  RowDetail,
} from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";

import { trackEvent } from "../../../../analytics";
import { PutAppPayload } from "../../../../api/core/controlPlane.types";
import Header from "../../../../components/Header";
import InstanceSelect from "../../../../components/InstanceSelect";
import { InstanceSelectInstanceIds } from "../../../../components/InstanceSelect/InstanceSelect.types";
import { useMetaTitle } from "../../../../components/Layout";
import StandardError from "../../../../components/StandardError";
import { useAppCollection } from "../../../../contexts/apps/App.context";
import { useAppsCollection } from "../../../../contexts/apps/Apps.context";
import useMarketplacePartners from "../../../../contexts/marketplace/hooks/useMarketplacePartners";
import useStandardInputs from "../../../../hooks/useStandardInputs";
import { appTooltipCopy } from "../../../Apps/data/microcopy";
import { AppPageProps } from "../../App.types";
import ExecutableDetails from "../../components/ExecutableDetails";
import MarketplaceAppPreview from "../../components/MarketplaceAppPreview";
import useReturnPaths from "../../hooks/useReturnPaths";

const EditAppDetails = ({ app }: AppPageProps) => {
  const [, setMetaTitle] = useMetaTitle();
  const theme = useTheme();
  const params = useParams() as { id: string };
  const { returnPath } = useReturnPaths();

  const {
    loadMarketplacePartnerApp,
    marketplacePartnerApp,
    marketplacePartnerAppError,
  } = useMarketplacePartners();

  const [submitted, setSubmitted] = useState(false);
  const [pendingInstanceIds, setPendingInstanceIds] =
    useState<InstanceSelectInstanceIds>([""]);
  const [hasLoaded, setHasLoaded] = useState(false);

  const { appLoadError, editApp, loadApp } = useAppCollection();
  const { loadApps } = useAppsCollection();

  const {
    getStandardInputsProps,
    pendingStandardInputs,
    standardInputsErrors,
    updateStandardInputs,
  } = useStandardInputs(undefined, undefined, true);

  const filteredPendingInstanceIds = useMemo(() => {
    return pendingInstanceIds.filter(Boolean);
  }, [pendingInstanceIds]);

  // load app data
  useEffect(() => {
    if (app.id !== params.id) {
      loadApp(params.id);
    }
  }, [app.id, loadApp, params.id]);

  // page display
  useEffect(() => {
    if (app.id === params.id) {
      setMetaTitle(`Edit ${app.name}`);
    }
  }, [app.id, app.name, params.id, setMetaTitle]);

  // pre-fill pending app with original data
  useEffect(() => {
    if (app && !pendingStandardInputs.id) {
      updateStandardInputs([
        { key: "name", value: app.name },
        { key: "id", value: app.id },
        { key: "description", value: app.description },
      ]);
    }

    // use hasLoaded so selection is clearable
    if (app && app.default_instance && !hasLoaded) {
      setPendingInstanceIds([app.default_instance]);
      setHasLoaded(true);
    }
  }, [
    app,
    hasLoaded,
    params.id,
    pendingStandardInputs.id,
    updateStandardInputs,
  ]);

  // load subscription marketplace app preview (if applicable)
  useEffect(() => {
    if (
      !marketplacePartnerApp &&
      !marketplacePartnerAppError &&
      app?.type === "subscription" &&
      app.subscription_id
    ) {
      loadMarketplacePartnerApp(app.subscription_id);
    }
  }, [
    app.subscription_id,
    app?.type,
    loadMarketplacePartnerApp,
    marketplacePartnerApp,
    marketplacePartnerAppError,
  ]);

  const handleAppSave = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const payload: PutAppPayload = { ...pendingStandardInputs };

    // add default instance if exists
    if (filteredPendingInstanceIds.length) {
      payload["default_instance"] = filteredPendingInstanceIds[0];
    }

    trackEvent("Apps", {
      view: "Edit App",
      action: "Edit App Saved",
    });

    editApp(app.id, "", payload);
    setSubmitted(true);
  };

  const editAppError = appLoadError;

  if (!pendingStandardInputs.id || !app)
    return <Loading type="full-screen" dotColor={theme.color.orange500} />;
  if (!submitted && editAppError)
    return <StandardError errorMessage={editAppError} />;

  if (submitted && !editAppError) {
    loadApps({});
    loadApp(app.id);
    return <Redirect to={returnPath} />;
  }

  const isActionButtonDisabled =
    !pendingStandardInputs.name || !!standardInputsErrors.name;

  return (
    <>
      <Header
        configPageTitle={{
          label: `Edit ${app.name}`,
        }}
      />

      <form onSubmit={(e) => handleAppSave(e)}>
        <RowDetail
          hasNoBorder
          property="Name"
          secondaryLabel="For reference only"
          render={
            <Box width="100%" maxWidth={rem(408)}>
              <Input
                {...getStandardInputsProps({
                  placeholder: "App name",
                  testId: "edit-app-name-input",
                  type: "name",
                  trackEventCategory: "Apps",
                  trackEventProperties: {
                    view: "Edit App",
                    action: "Field Changed",
                    meta: {
                      field: "name",
                    },
                  },
                })}
              />
            </Box>
          }
        />

        <RowDetail
          property="ID"
          secondaryLabel="Read-only"
          tooltipCopy={appTooltipCopy.idEdit}
          render={
            <Box flexGrow={1} maxWidth={rem(408)}>
              <Input
                htmlType="text"
                isDisabled
                placeholder="App ID"
                value={app.id}
                readOnly
              />
            </Box>
          }
        />

        <RowDetail
          property="Description"
          secondaryLabel="(optional)"
          render={
            <Box width="100%" maxWidth={rem(408)}>
              <Input
                {...getStandardInputsProps({
                  placeholder: "App description",
                  testId: "edit-app-description-input",
                  type: "description",
                  trackEventCategory: "Apps",
                  trackEventProperties: {
                    view: "Edit App",
                    action: "Field Changed",
                    meta: {
                      field: "description",
                    },
                  },
                })}
              />
            </Box>
          }
        />

        <RowDetail
          property="Instance"
          secondaryLabel="Select default instance (optional)"
          render={
            <InstanceSelect
              {...{ app, pendingInstanceIds, setPendingInstanceIds }}
              isSingleSelect
            />
          }
        />

        {marketplacePartnerApp && (
          <RowDetail
            property="Marketplace App"
            tooltipCopy={appTooltipCopy.marketplaceApp}
            render={
              <MarketplaceAppPreview marketplaceApp={marketplacePartnerApp} />
            }
          />
        )}

        {app?.type === "custom" && (
          <RowDetail
            property="Latest Pushed Executable"
            secondaryLabel="Read-only"
            tooltipCopy={appTooltipCopy.appExecutableEdit}
            render={<ExecutableDetails executable={app?.active_executable} />}
          />
        )}

        <Flex
          hasBorderTop
          hasBorderWidthThick
          pt={4}
          justifyContent="space-between"
        >
          <Box>
            {editAppError && (
              <Notification
                data-testid="edit-app-error-message"
                mt={1}
                mb={5}
                type="error"
                hasContactExtra
                message={editAppError}
              />
            )}
            <Flex alignItems="center">
              <Button2
                isDisabled={isActionButtonDisabled}
                size="large"
                htmlType="submit"
                label="Save"
                data-testid="edit-app-save"
              />
              <Button2
                ml={2}
                type="text"
                size="large"
                label="Cancel"
                htmlType="button"
                data-testid="edit-app-cancel"
                to={returnPath}
                onClick={() =>
                  trackEvent("Apps", {
                    view: "Edit App",
                    action: "Edit App Canceled",
                  })
                }
              />
            </Flex>
          </Box>
        </Flex>
      </form>
    </>
  );
};

export default EditAppDetails;
