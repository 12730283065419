import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Button2,
  Flex,
  InputCheckbox,
  Loading,
  Notification,
  Text,
} from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";

import { createSubscription } from "../../api/payment/controlPlane";
import { useUser } from "../../AuthProvider";
import Bin from "../../components/Bin";
import Header from "../../components/Header";
import { useMetaTitle } from "../../components/Layout";
import { usePlans } from "../../contexts/plans/Plans.context";
import { PlanCustomError } from "../../contexts/plans/Plans.context.types";
import { useSubscription } from "../../contexts/subscription/Subscription.context";
import { confirmUserSubscription } from "../../contexts/subscription/utils";
import { formatPrice } from "../../utils/format";

import { UseParamTypes } from "./CustomPlan.types";

const pageTitle = "Plan Confirmation";

const renderLoading = () => <Loading />;

const renderError = (
  planCustomError: PlanCustomError | "subscription-exists"
) => {
  let errorMessage = "";
  switch (planCustomError) {
    case "subscription-exists":
      errorMessage = "Your account already has an existing plan.";
      break;
    case "no-plan":
      errorMessage = "No plan found. Please contact support@nextmv.io";
      break;
    default:
      errorMessage =
        "An error occurred. Try refreshing the page. If the error persists please contact support@nextmv.io";
      break;
  }
  return (
    <>
      <Notification
        mt={5}
        data-testid={
          planCustomError === "subscription-exists"
            ? "custom-connect-notice"
            : "custom-connect-error-message"
        }
        type={planCustomError === "subscription-exists" ? "notice" : "error"}
        message={errorMessage}
      />

      {planCustomError === "subscription-exists" && (
        <Text mt={4} styleName="body-1" hasLink>
          Go to your <Link to="/account/profile">Account Profile page</Link> to
          view your plan.
        </Text>
      )}
    </>
  );
};

const CustomPlanPage = () => {
  const [user] = useUser();

  const {
    loadPlanCustom,
    planCustom,
    planCustomError,
    planCustomLoading,
    setPlanCustomError,
    setPlanCustomLoading,
  } = usePlans();

  const {
    subscription,
    fetchSubscription,
    pendingSetupIntentSecret,
    receiptData,
    setReceiptData,
  } = useSubscription();

  const [, setMetaTitle] = useMetaTitle();
  const theme = useTheme();
  const { priceId } = useParams<UseParamTypes>();

  const [checkboxChecked, setCheckboxChecked] = useState(false);

  useEffect(() => {
    setMetaTitle(pageTitle);
    fetchSubscription();
  }, [fetchSubscription, setMetaTitle]);

  useEffect(() => {
    if (!planCustom && priceId) {
      loadPlanCustom(priceId);
    }
    return () => setPlanCustomError("");
  }, [loadPlanCustom, planCustom, priceId, setPlanCustomError]);

  const userHasConfirmedSubscription = confirmUserSubscription(
    subscription,
    pendingSetupIntentSecret
  );

  const handlePaymentSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setPlanCustomLoading(true);

    if (checkboxChecked && planCustom) {
      createSubscription(
        JSON.stringify({
          planId: planCustom.planId,
          account: user.id,
        })
      ).then((returnData) => {
        setPlanCustomLoading(false);

        if (!returnData.subscription) {
          setPlanCustomError("other");
        } else {
          setReceiptData({
            amount: returnData.subscription.price,
            id: returnData.subscription.subscriptionId,
            created: Date.now(),
          });
        }
      });
    }
  };

  const renderSubscriptionForm = () => {
    return (
      <Flex mt={[4, 8]} alignItems="center" justifyContent="center">
        <Bin width="100%" maxWidth={rem(560)}>
          <form onSubmit={handlePaymentSubmit}>
            <Text
              styleName="marketing-header-3"
              styles={{ color: theme.color.gray800 }}
            >
              {planCustom?.name}
            </Text>
            <Text mt={2} styleName="header-1">
              {formatPrice(planCustom?.price, 0)}
            </Text>

            <InputCheckbox
              mt={3}
              id="platform-accept-terms"
              label={`Enable platform access for ${user.email}.`}
              checked={checkboxChecked}
              handleChange={() => setCheckboxChecked(!checkboxChecked)}
            />
            <Button2
              htmlType="submit"
              data-testid="button-custom-plan-connect"
              mt={6}
              size="large"
              isDisabled={!checkboxChecked ? true : undefined}
              label="Connect Account"
            />
          </form>
        </Bin>
      </Flex>
    );
  };

  const renderSuccess = () => {
    return (
      <Flex mt={[4, 8]} alignItems="center" justifyContent="center">
        <Bin width="100%" maxWidth={rem(560)}>
          <Text
            data-testid="subscription-success-header"
            styleName="marketing-header-3"
            styles={{ color: theme.color.gray800 }}
          >
            Success!
          </Text>
          <Text mt={2} styleName="body-1-plus">
            Your account is now subscribed to Nextmv Platform.
          </Text>

          <Flex>
            <Button2
              mt={6}
              size="large"
              label="Go to my profile"
              to="/account/profile"
            />
          </Flex>
        </Bin>
      </Flex>
    );
  };

  const renderPageContent = () => {
    if (planCustomLoading) return renderLoading();
    if (userHasConfirmedSubscription) return renderError("subscription-exists");
    if (planCustomError) return renderError(planCustomError);
    if (receiptData) return renderSuccess();
    return planCustom && renderSubscriptionForm();
  };

  return (
    <>
      <Header configPageTitle={{ label: pageTitle }} />

      {renderPageContent()}
    </>
  );
};

export default CustomPlanPage;
