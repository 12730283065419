import React, { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import {
  AvatarExperimentScenario,
  Box,
  Input,
  Loading,
  RowDetail,
} from "@console/dsc";
import { useTheme } from "@emotion/react";

import { trackEvent } from "../../../../analytics";
import Footer from "../../../../components/Footer";
import Header from "../../../../components/Header";
import { useMetaTitle } from "../../../../components/Layout";
import StandardError from "../../../../components/StandardError";
import { PAYLOAD_EXPERIMENT_TYPE_SCENARIO } from "../../../../config/experiments";
import { INPUT_WIDTH_STANDARD } from "../../../../config/general";
import { useExperiments } from "../../../../contexts/experiments/Experiments.context";
import useManageEntity from "../../../../hooks/useManageEntity";
import useStandardInputs from "../../../../hooks/useStandardInputs";
import { AppPageProps } from "../../../App/App.types";
import useReturnPaths from "../../../App/hooks/useReturnPaths";
import { sharedTooltipCopy } from "../../data/microcopy";

const EditScenarioTest = ({ app }: AppPageProps) => {
  const [, setMetaTitle] = useMetaTitle();
  const theme = useTheme();
  const { returnPath, returnPathList } = useReturnPaths();
  const params = useParams() as {
    id: string;
    field?: string;
  };

  const [isProcessing, setIsProcessing] = useState(false);

  const { loadScenarioTests } = useExperiments();

  const {
    editEntity: editScenarioTest,
    entity: scenarioTest,
    entityLoadError: scenarioTestLoadError,
    entityEditError: scenarioTestEditError,
    loadEntity: loadScenarioTest,
    isEntityEdited: isScenarioTestSaved,
  } = useManageEntity("experiments/batch");

  const {
    getStandardInputsProps,
    pendingStandardInputs,
    standardInputsErrors,
    updateStandardInputs,
  } = useStandardInputs(app, "experiments/batch", true);

  // load batch experiment
  useEffect(() => {
    if (!scenarioTest && !scenarioTestLoadError) {
      loadScenarioTest(app.id, params.id);
    }
  }, [
    app.id,
    scenarioTest,
    scenarioTestLoadError,
    loadScenarioTest,
    params.id,
  ]);

  // page display
  useEffect(() => {
    if (scenarioTest) {
      setMetaTitle(`Edit ${scenarioTest.name}`);
    }
  }, [scenarioTest, setMetaTitle]);

  // pre-fill pending batch experiment with original values
  useEffect(() => {
    if (scenarioTest && !pendingStandardInputs.id) {
      updateStandardInputs([
        { key: "name", value: scenarioTest.name },
        { key: "id", value: scenarioTest.id },
        { key: "description", value: scenarioTest.description },
      ]);
    }
  }, [scenarioTest, pendingStandardInputs.id, updateStandardInputs]);

  // disable loading state if edit batch experiment error
  useEffect(() => {
    if (scenarioTestEditError && isProcessing) {
      setIsProcessing(false);
    }
  }, [isProcessing, scenarioTestEditError]);

  const handleScenarioTestSave = async (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    e.preventDefault();
    e.stopPropagation();

    setIsProcessing(true);

    const payload = { ...pendingStandardInputs };

    await editScenarioTest(app.id, scenarioTest!.id, payload);
  };

  const handleCancel = () => {
    trackEvent("Scenario", {
      view: "Edit Scenario Test",
      action: "Edit Scenario Test Canceled",
    });
    return;
  };

  const handleScenarioTestPostDelete = () => {
    trackEvent("Scenario", {
      view: "Edit Scenario Test",
      action: "Scenario Test Deleted",
    });

    loadScenarioTests({
      applicationId: app.id,
      type: PAYLOAD_EXPERIMENT_TYPE_SCENARIO,
    });
  };

  if (scenarioTestLoadError) {
    return <StandardError errorMessage={scenarioTestLoadError} />;
  }
  if (!pendingStandardInputs.id || !scenarioTest) {
    return <Loading type="full-screen" dotColor={theme.color.orange500} />;
  }
  if (isScenarioTestSaved) {
    trackEvent("Scenario", {
      view: "Edit Scenario Test",
      action: "Scenario Test Saved",
    });

    loadScenarioTest(app.id, params.id);
    loadScenarioTests({
      applicationId: app.id,
      type: PAYLOAD_EXPERIMENT_TYPE_SCENARIO,
    });

    return <Redirect to={returnPath} />;
  }

  const isActionButtonDisabled =
    !pendingStandardInputs.name || !!standardInputsErrors.name;

  return (
    <>
      <Header
        configPageTitle={{
          label: `Edit ${scenarioTest.name}`,
          ancestorIcon: <AvatarExperimentScenario size={24} />,
          ancestorLabel: "Scenario Tests",
          ancestorUrl: returnPathList,
        }}
      />

      <form>
        <RowDetail
          hasNoBorder
          property="Name"
          secondaryLabel="For reference only"
          render={
            <Box width="100%" maxWidth={INPUT_WIDTH_STANDARD}>
              <Input
                {...getStandardInputsProps({
                  placeholder: "Scenario test name",
                  testId: "edit-scenario-test-name-input",
                  type: "name",
                  trackEventCategory: "Scenario",
                  trackEventProperties: {
                    view: "Edit Scenario Test",
                    action: "Field Changed",
                    meta: {
                      field: "name",
                    },
                  },
                })}
              />
            </Box>
          }
        />

        <RowDetail
          property="ID"
          testId="edit-scenario-test-row-id"
          secondaryLabel="Read-only"
          tooltipCopy={sharedTooltipCopy.idEdit("scenario test").content}
          render={
            <Box flexGrow={1} maxWidth={INPUT_WIDTH_STANDARD}>
              <Input
                htmlType="text"
                isDisabled
                placeholder="Secnario test ID"
                value={scenarioTest.id}
                readOnly
              />
            </Box>
          }
        />

        <RowDetail
          property="Description"
          secondaryLabel="(optional)"
          render={
            <Box width="100%" maxWidth={INPUT_WIDTH_STANDARD}>
              <Input
                {...getStandardInputsProps({
                  placeholder: "Scenario test description",
                  testId: "edit-scenario-test-description-input",
                  type: "description",
                  trackEventCategory: "Scenario",
                  trackEventProperties: {
                    view: "Edit Scenario Test",
                    action: "Field Changed",
                    meta: {
                      field: "description",
                    },
                  },
                })}
              />
            </Box>
          }
        />

        <Footer
          app={app}
          endpoint="experiments/batch"
          entityId={scenarioTest.id}
          error={scenarioTestEditError}
          handleCancel={handleCancel}
          handleMainAction={handleScenarioTestSave}
          handlePostDelete={handleScenarioTestPostDelete}
          isActionButtonLoading={isProcessing}
          isActionButtonDisabled={isActionButtonDisabled}
          returnPath={returnPath}
          returnPathList={returnPathList}
          view="edit"
        />
      </form>
    </>
  );
};

export default EditScenarioTest;
