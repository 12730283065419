import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button2 } from "@console/dsc";
import { useTheme } from "@emotion/react";

import { trackEvent } from "../../../../analytics";
import { SwitchbackTestResponse } from "../../../../api/core/controlPlane.types";
import { useUser } from "../../../../AuthProvider";
import Header from "../../../../components/Header";
import { useMetaTitle } from "../../../../components/Layout";
import PlanNotice from "../../../../components/PlanNotice";
import StandardError from "../../../../components/StandardError";
import { useExperiments } from "../../../../contexts/experiments/Experiments.context";
import { getFilteredEntities } from "../../../../utils/entities/getFilteredEntities";
import { userHasAccessToAction } from "../../../../utils/rbac_utils";
import { ActionGroups } from "../../../../utils/rbac_utils/types";
import { AppPageProps } from "../../../App/App.types";
import LoadingListView from "../../../App/components/LoadingListView";
import { experimentsTooltips } from "../../data/microcopy";
import { renderExperimentsList } from "../../utils/renderExperimentsList";

const pageTitle = "Switchback Tests";

const SwitchbackTests = ({ app }: AppPageProps) => {
  const [{ id: accountId, roles }] = useUser();
  const [, setMetaTitle] = useMetaTitle();
  const theme = useTheme();
  const { pathname } = useLocation();
  const [filterText, setFilterText] = useState("");

  const {
    switchbackTests: switchbackTestResponse,
    switchbackTestsError,
    loadSwitchbackTests,
    setSwitchbackTests,
  } = useExperiments();

  const switchbackTests = switchbackTestResponse.items;

  // page display
  useEffect(() => {
    setMetaTitle(pageTitle);
  }, [setMetaTitle]);

  // load switchback tests if not in context already
  useEffect(() => {
    if (!switchbackTests && !switchbackTestsError) {
      loadSwitchbackTests(app.id);
    }
  }, [app.id, loadSwitchbackTests, switchbackTests, switchbackTestsError]);

  if (switchbackTestsError) {
    return <StandardError errorMessage={switchbackTestsError} />;
  }

  const canUserCreateAndEditSwitchback = userHasAccessToAction(
    roles,
    ActionGroups.ExperimentOperator,
    {}
  );

  const filteredSwitchbackTests = getFilteredEntities(
    filterText,
    switchbackTests
  );
  const renderSwitchbackTests = () => {
    return renderExperimentsList({
      accountId,
      app,
      canUserCreateAndEdit: canUserCreateAndEditSwitchback,
      experiments: filteredSwitchbackTests as SwitchbackTestResponse[],
      theme,
      kind: "switchback",
      urlOnClickTrackEventCategory: "SwitchbackTests",
      urlOnClickTrackEventProperties: {
        view: "Switchback Tests",
        action: "Link Clicked",
        meta: {
          type: "Switchback Test Detail",
        },
      },
    });
  };

  return (
    <>
      <Header
        configPageTitle={{
          label: pageTitle,
          tooltipContent: experimentsTooltips.switchback.content,
          tooltipExtraLinkLabel: experimentsTooltips.switchback.extraLinkLabel,
          tooltipExtraLinkUrl: experimentsTooltips.switchback.extraLinkUrl,
        }}
        configActionButton={{
          label: "New switchback test",
          url: `${pathname}/new`,
          onClick: () =>
            trackEvent("SwitchbackTests", {
              view: "Switchback Tests",
              action: "Create Switchback Test Button Clicked",
            }),
          isActionAllowed: canUserCreateAndEditSwitchback,
        }}
        configFilter={{
          inputText: filterText,
          testId: "filter-switchback-tests",
          setInputText: setFilterText,
        }}
        refreshButton={{
          emptyItems: {
            items: null,
            next_page_token: "",
          },
          itemsLoading: !switchbackTests,
          loadItems: () => loadSwitchbackTests(app.id),
          setItems: setSwitchbackTests,
        }}
      />

      <PlanNotice {...{ app }} type="custom-apps" />

      {!switchbackTests ? <LoadingListView /> : renderSwitchbackTests()}

      {switchbackTestResponse.next_page_token && (
        <Button2
          mt={2}
          label="Load more"
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.preventDefault();
            e.stopPropagation();
            return loadSwitchbackTests(
              app.id,
              switchbackTestResponse.next_page_token
            );
          }}
        />
      )}
    </>
  );
};

export default SwitchbackTests;
