import React from "react";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";

import Modal2 from "../../../../../components/Modal2";
import StandardError from "../../../../../components/StandardError";
import { TEST_ACTION_CANCEL } from "../../../../../config/experiments";
import { StartStopExperimentConfirmModalProps } from "../../../types/Experiment.types";

const CancelBatchExperimentConfirmModal = ({
  error,
  handleStartStopModalActions,
  modalType,
  setDisplayConfirmModal,
  testName,
}: StartStopExperimentConfirmModalProps) => {
  const theme = useTheme();

  // This particular modal should not appear for anything other than "cancel"
  // because batch experiments cannot be completed early and are started on creation.
  // We thus just return an empty element here after shutting display off if the modal type isn't correct.
  if (modalType !== TEST_ACTION_CANCEL) {
    setDisplayConfirmModal(null);
    return <></>;
  }

  const content = {
    title: `Cancel ${testName}`,
    summary:
      `Canceling the batch experiment will mark the experiment status as cancelled. ` +
      `All currently queued and active runs on the experiment will be canceled as well. ` +
      `Do you want to mark this experiment cancelled?`,
    primaryActionLabel: "Cancel batch experiment",
    secondaryActionLabel: "Never mind",
  };

  const getModalChildren = () => {
    return <>{error && <StandardError errorMessage={error} />}</>;
  };

  return (
    <Modal2
      isActive={true}
      width={rem(600)}
      title={content.title}
      summary={content.summary}
      closeActionOnClick={() => setDisplayConfirmModal(null)}
      primaryActionLabel={content.primaryActionLabel}
      primaryActionOnClick={handleStartStopModalActions}
      {...{
        primaryActionStyles: {
          backgroundColor: theme.color.red600,
          "&:hover, &:focus, &:active": {
            backgroundColor: theme.color.red700,
          },
          "&:focus-visible": {
            outlineColor: theme.color.red800,
          },
        },
      }}
      secondaryActionLabel={content.secondaryActionLabel}
      secondaryActionOnClick={() => setDisplayConfirmModal(null)}
      children={getModalChildren()}
    />
  );
};

export default CancelBatchExperimentConfirmModal;
