import React, { useEffect } from "react";
import { Box, Loading, Select } from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";

import {
  AppType,
  AppVersionsData,
  EntityErrorMessage,
  MarketplaceAppVersions,
  MarketplacePartnerApp,
} from "../../../api/core/controlPlane.types";
import StandardError from "../../../components/StandardError";
import useManageEntity from "../../../hooks/useManageEntity";
import { VersionOption } from "../App.types";
import { getMarketplacePartnerAppLatestVersions } from "../utils/marketplace";

export type VersionSelectProps = {
  loadMoreVersions: (e: any, nextPageToken: string) => void;
  marketplaceVersions: MarketplaceAppVersions;
  marketplaceVersionsError: EntityErrorMessage;
  pendingVersionId: string | undefined;
  setPendingVersionId: React.Dispatch<React.SetStateAction<string>>;
  versions: AppVersionsData;
  versionsError: EntityErrorMessage;
  appId?: string;
  appType?: AppType;
  versionsNextPageToken?: string;
  marketplacePartnerApp?: MarketplacePartnerApp;
};

const VersionSelect = ({
  appType,
  appId,
  marketplacePartnerApp,
  marketplaceVersions,
  marketplaceVersionsError,
  loadMoreVersions,
  pendingVersionId,
  setPendingVersionId,
  versions,
  versionsError,
  versionsNextPageToken,
}: VersionSelectProps) => {
  const {
    entity: version,
    loadEntity: loadVersion,
    entityLoadError: loadVersionError,
  } = useManageEntity("versions");

  const isSubscriptionApp = appType === "subscription";

  const thisVersionsError = isSubscriptionApp
    ? marketplaceVersionsError
    : versionsError || loadVersionError;
  const thisVersionsLoading = isSubscriptionApp
    ? !marketplaceVersions
    : !versions;

  useEffect(
    function loadInitialPendingVersion() {
      if (isSubscriptionApp) {
        return;
      }
      // manually fetch the version data in case the version is not in the initial version list
      if (pendingVersionId && appId && !version) {
        loadVersion(appId, pendingVersionId);
      }
    },
    [appId, isSubscriptionApp, loadVersion, pendingVersionId, version, versions]
  );

  const versionOptions: VersionOption[] = React.useMemo(() => {
    const marketplaceLatestVersions =
      (marketplacePartnerApp &&
        getMarketplacePartnerAppLatestVersions(marketplacePartnerApp)) ||
      [];

    const thisVersions =
      appType === "subscription"
        ? [
            ...(marketplaceLatestVersions ? marketplaceLatestVersions : []),
            ...(marketplaceVersions ? marketplaceVersions : []),
          ]
        : versions;

    const versionOptions = () => {
      if (!thisVersions) return [];
      return thisVersions.reduce((options: VersionOption[], version) => {
        options.push({
          id: version.id,
          name: version?.name || "",
          lastUpdated: version?.updated_at || version?.created_at,
        });
        return options;
      }, []);
    };
    return versionOptions();
  }, [appType, marketplacePartnerApp, marketplaceVersions, versions]);

  const getVersionOptionFromId = (versionId: string): VersionOption => {
    let selectedVersionOption = versionOptions.find((versionOption) => {
      return versionOption.id === versionId;
    });

    const isForceAddVersionOption =
      !selectedVersionOption &&
      !isSubscriptionApp &&
      !!version &&
      versionId === version.id;

    if (isForceAddVersionOption) {
      selectedVersionOption = {
        id: version.id,
        name: version.name,
        lastUpdated: version.updated_at || version.created_at,
      };
      versionOptions.push(selectedVersionOption);
    }

    if (selectedVersionOption) {
      return selectedVersionOption;
    }

    return versionOptions[0];
  };

  const updateVersionSelection = (selectedVersion: VersionOption) => {
    setPendingVersionId(selectedVersion.id);
    return;
  };

  if (thisVersionsError) {
    return <StandardError errorMessage={thisVersionsError} />;
  }
  if (thisVersionsLoading) {
    return <Loading />;
  }

  return (
    <Box maxWidth={rem(408)}>
      <Select
        testId="version-app-select"
        type="meta"
        placeholder={
          !versionOptions.length ? "No versions available" : "Select version"
        }
        options={versionOptions}
        {...(versionsNextPageToken && {
          loadMore: (e: any) => loadMoreVersions(e, versionsNextPageToken),
        })}
        getOptionValue={(versionSelected: VersionOption) => versionSelected.id}
        value={pendingVersionId && getVersionOptionFromId(pendingVersionId)}
        onChange={(selection: VersionOption) => {
          updateVersionSelection(selection);
        }}
      />
    </Box>
  );
};
export default VersionSelect;
