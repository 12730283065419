import React from "react";
import { Helmet } from "react-helmet";
import { Flex } from "@console/dsc";
import { css, Global, useTheme } from "@emotion/react";

import { useUser } from "../../AuthProvider";
import SectionNav from "../../components/SectionNav";
import Banner from "../Banner";
import { getBannerType } from "../Banner/utils/getBannerType";
import MainNav from "../MainNav";
import OrgHeader from "../OrgHeader";

import { useMetaTitle, withLayoutProvider } from "./Layout.context";
import { Main, SiteContainer } from "./Layout.styles";
import { LayoutProps } from "./Layout.types";

const Layout = ({
  activeAppNavSections,
  children,
  sectionIcon,
  sectionPages,
  sectionTitle,
  setActiveAppNavSections,
}: LayoutProps) => {
  const [user] = useUser();
  const [metaTitle] = useMetaTitle();
  const theme = useTheme();

  const bannerType = getBannerType(user);
  const hasSectionNav = !!(sectionPages && sectionTitle);

  const renderLayout = () => (
    <>
      <Global
        styles={css`
          body {
            background-color: ${theme.color.orgNavy};
          }
        `}
      />

      {bannerType && <Banner {...{ user }} type={bannerType} />}

      <OrgHeader backgroundColor={theme.color.orgNavy} />

      <SiteContainer flexDirection="column" mt={8} hasBanner={!!bannerType}>
        <Flex flexGrow={1} flexDirection={["column", "row"]}>
          <MainNav />

          <Flex
            width="100%"
            flexGrow={1}
            flexDirection={["column", "column", "row"]}
          >
            {hasSectionNav && (
              <SectionNav
                activeAppNavSections={activeAppNavSections}
                ariaLabel={sectionTitle}
                pages={sectionPages}
                sectionIcon={sectionIcon}
                sectionTitle={sectionTitle}
                setActiveAppNavSections={setActiveAppNavSections}
              />
            )}
            <Main {...{ hasSectionNav }} hasSectionTitle={!!sectionTitle}>
              {children}
            </Main>
          </Flex>
        </Flex>
      </SiteContainer>
    </>
  );

  return (
    <>
      <Helmet>
        <title>{metaTitle} | Nextmv Cloud</title>
        <meta property="og:title" content={`${metaTitle} | Nextmv Cloud`} />
      </Helmet>

      {renderLayout()}
    </>
  );
};

export default withLayoutProvider(Layout);
