import React, { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import {
  AvatarEnsembleDef,
  Box,
  Input,
  Loading,
  RowDetail,
} from "@console/dsc";
import { useTheme } from "@emotion/react";

import { trackEvent } from "../../../../analytics";
import Footer from "../../../../components/Footer";
import Header from "../../../../components/Header";
import { useMetaTitle } from "../../../../components/Layout";
import StandardError from "../../../../components/StandardError";
import { ENDPOINT_ENSEMBLES } from "../../../../config/endpoints";
import { INPUT_WIDTH_STANDARD } from "../../../../config/general";
import { useAppCollection } from "../../../../contexts/apps/App.context";
import useManageEntity from "../../../../hooks/useManageEntity";
import useStandardInputs from "../../../../hooks/useStandardInputs";
import { AppPageProps } from "../../App.types";
import { ensembleDefinitionTooltips } from "../../data/appTooltips";
import useReturnPaths from "../../hooks/useReturnPaths";

const EditEnsembleDef = ({ app }: AppPageProps) => {
  const [, setMetaTitle] = useMetaTitle();
  const params = useParams() as { id: string };
  const theme = useTheme();
  const { returnPath, returnPathList } = useReturnPaths();

  const [isProcessing, setIsProcessing] = useState(false);

  const { loadEnsembleDefs } = useAppCollection();

  const {
    editEntity: editEnsembleDef,
    entity: ensembleDefinition,
    entityLoadError: ensembleDefinitionLoadError,
    entityEditError: ensembleDefinitionEditError,
    loadEntity: loadEnsembleDef,
    isEntityEdited: isEnsembleDefSaved,
  } = useManageEntity(ENDPOINT_ENSEMBLES);

  const {
    getStandardInputsProps,
    pendingStandardInputs,
    standardInputsErrors,
    updateStandardInputs,
  } = useStandardInputs(app, ENDPOINT_ENSEMBLES, true);

  // load app input data
  useEffect(() => {
    if (!ensembleDefinition && !ensembleDefinitionLoadError) {
      loadEnsembleDef(app.id, params.id);
    }
  }, [
    app.id,
    ensembleDefinition,
    ensembleDefinitionLoadError,
    loadEnsembleDef,
    params.id,
  ]);

  // page meta display
  useEffect(() => {
    if (ensembleDefinition) {
      setMetaTitle(`Edit ${ensembleDefinition.name}`);
    }
  }, [ensembleDefinition, setMetaTitle]);

  // pre-fill pending version with original values
  useEffect(() => {
    if (ensembleDefinition && !pendingStandardInputs.id) {
      updateStandardInputs([
        { key: "name", value: ensembleDefinition.name },
        { key: "id", value: ensembleDefinition.id },
        { key: "description", value: ensembleDefinition.description },
      ]);
    }
  }, [ensembleDefinition, pendingStandardInputs.id, updateStandardInputs]);

  useEffect(() => {
    if (ensembleDefinitionEditError && isProcessing) {
      setIsProcessing(false);
    }
  }, [isProcessing, ensembleDefinitionEditError]);

  const handleEnsembleDefSave = async (e: {
    preventDefault: any;
    stopPropagation: any;
  }) => {
    e.preventDefault();
    e.stopPropagation();

    setIsProcessing(true);

    const payload = { ...pendingStandardInputs };

    await editEnsembleDef(app.id, ensembleDefinition!.id, payload);
  };

  const handleCancel = () => {
    trackEvent("EnsembleDefs", {
      view: "Edit Ensemble Def",
      action: "Edit Ensemble Def Canceled",
    });
    return;
  };

  const handleEnsembleDefPostDelete = () => {
    trackEvent("EnsembleDefs", {
      view: "Edit Ensemble Def",
      action: "Ensemble Def Deleted",
    });

    loadEnsembleDefs({ applicationId: app.id, shouldPaginate: true });
  };

  if (ensembleDefinitionLoadError) {
    return <StandardError errorMessage={ensembleDefinitionLoadError} />;
  }
  if (!pendingStandardInputs.id || !ensembleDefinition) {
    return <Loading type="full-screen" dotColor={theme.color.orange500} />;
  }
  if (isEnsembleDefSaved) {
    trackEvent("EnsembleDefs", {
      view: "Edit Ensemble Def",
      action: "Edit Ensemble Def Saved",
    });

    loadEnsembleDef(app.id, params.id);
    loadEnsembleDefs({ applicationId: app.id, shouldPaginate: true });

    return <Redirect to={returnPath} />;
  }

  const isActionButtonDisabled =
    !pendingStandardInputs.name || !!standardInputsErrors.name;

  return (
    <>
      <Header
        configPageTitle={{
          label: `Edit ${ensembleDefinition.name}`,
          ancestorIcon: <AvatarEnsembleDef />,
          ancestorLabel: "Ensemble Definitions",
          ancestorUrl: returnPathList,
        }}
      />

      <form>
        <RowDetail
          hasNoBorderTop
          property="Name"
          secondaryLabel="For reference only"
          render={
            <Box width="100%" maxWidth={INPUT_WIDTH_STANDARD}>
              <Input
                {...getStandardInputsProps({
                  placeholder: "Ensemble definition name",
                  testId: "edit-ensemble-def-name-input",
                  type: "name",
                  trackEventCategory: "EnsembleDefs",
                  trackEventProperties: {
                    view: "Edit Ensemble Def",
                    action: "Field Changed",
                    meta: {
                      field: "name",
                    },
                  },
                })}
              />
            </Box>
          }
        />

        <RowDetail
          property="ID"
          secondaryLabel="Read-only"
          tooltipCopy={ensembleDefinitionTooltips.edit.content}
          render={
            <Box flexGrow={1} maxWidth={INPUT_WIDTH_STANDARD}>
              <Input
                htmlType="text"
                isDisabled
                placeholder="Input ID"
                value={ensembleDefinition.id}
                readOnly
              />
            </Box>
          }
        />

        <RowDetail
          property="Description"
          secondaryLabel="(optional)"
          render={
            <Box width="100%" maxWidth={INPUT_WIDTH_STANDARD}>
              <Input
                {...getStandardInputsProps({
                  placeholder: "Ensemble definition description",
                  testId: "edit-ensemble-def-description-input",
                  type: "description",
                  trackEventCategory: "EnsembleDefs",
                  trackEventProperties: {
                    view: "Edit Ensemble Def",
                    action: "Field Changed",
                    meta: {
                      field: "description",
                    },
                  },
                })}
              />
            </Box>
          }
        />

        <Footer
          app={app}
          endpoint={ENDPOINT_ENSEMBLES}
          entityId={ensembleDefinition.id}
          error={ensembleDefinitionEditError}
          handleCancel={handleCancel}
          handleMainAction={handleEnsembleDefSave}
          handlePostDelete={handleEnsembleDefPostDelete}
          isActionButtonLoading={isProcessing}
          isActionButtonDisabled={isActionButtonDisabled}
          returnPath={returnPath}
          returnPathList={returnPathList}
          view="edit"
        />
      </form>
    </>
  );
};

export default EditEnsembleDef;
