import { Box } from "@console/dsc";
import { sharedButtonStyles } from "@console/dsc/src/components/Button2/Button2.styled";
import {
  getButtonSizeStyles,
  getButtonTypeStyles,
} from "@console/dsc/src/components/Button2/utils/getButtonStyles";
import { styleScrollbarY } from "@console/dsc/src/lib/constants";
import { rem } from "@console/dsc/src/lib/tools";
import styled from "@emotion/styled";

/*
  DOM STRUCTURE
  jse-main.jse-focus
    jse-text-mode.no-main-menu
      jse-menu
      jse-search-box-container (tree mode)
      jse-contents
        cm-editor
          cm-scroller
            cm-gutters
              cm-gutter.cm-gutter-lint
              cm-gutter.cm-lineNumbers
                cm-gutterElement.cm-activeLineGutter
              cm-gutter.cm-foldGutter
            cm-content.cm-lineWrapping
      jse-status-bar
*/

export const StyledEditor = styled(Box)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  /* target plain wrapping ref div around .jse-main */
  > div {
    min-height: 100%;
    height: 100%;
    flex-grow: 1;
  }

  /* set all fonts to monospace */
  * {
    font-family: ${({ theme }) => theme.ui2Typography.fontFamilyCode};
  }

  /* editor bounding box */
  .jse-main {
    box-sizing: border-box;
    min-height: 100%;
    height: 100%;
    position: relative;
    border: 0;
  }

  .jse-text-mode,
  .jse-tree-mode {
    box-sizing: border-box;
    min-height: 100%;
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
    border: 0 !important;
  }

  /* main contents of editor (text, tree) */
  .jse-contents {
    height: 100%;
    min-height: 100%;
    margin: 0 !important;
    padding: 0 !important;
    border: 0 !important;
  }
  /* adjust height for tree mode to accommodate top menu bar */
  .jse-tree-mode {
    .jse-contents {
      height: calc(100% - ${({ theme }) => theme.spacing.s7});
      min-height: calc(100% - ${({ theme }) => theme.spacing.s7});
    }
  }

  /* bottom status bar */
  .jse-status-bar {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    width: 100%;
    height: ${({ theme }) => theme.spacing.s7};
    padding-left: ${({ theme }) => theme.spacing.s4};
    position: absolute;
    left: 0;
    bottom: 0;
    font-size: ${({ theme }) => theme.ui2Typography.fontSizeMeta2} !important;
    font-weight: ${({ theme }) =>
      theme.ui2Typography.fontWeightMeta2} !important;
    line-height: ${({ theme }) =>
      theme.ui2Typography.lineHeightMeta2} !important;
    color: ${({ theme }) => theme.color.gray600} !important;
    border: 0 !important;
    border-radius: ${({ theme }) => theme.border.radius};
    background-color: ${({ theme }) => theme.color.gray100} !important;
    z-index: 11;
  }

  /*
    main scroll window (text and tree mode)
    height/min-height calcs make space for bottom status bar 
    in text mode and top menu bar in tree mode
  */
  .cm-editor .cm-scroller,
  .jse-contents > .jse-root {
    box-sizing: border-box !important;
    width: 100% !important;
    height: 100% !important;
    min-height: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    position: absolute !important;
    left: 0 !important;
    top: 0 !important;
    overflow-y: scroll !important;
    ${({ theme }) => styleScrollbarY(theme)}
  }

  /* make space for status bar for text mode */
  .jse-text-mode {
    .cm-editor .cm-scroller {
      height: calc(100% - ${({ theme }) => theme.spacing.s7}) !important;
      min-height: calc(100% - ${({ theme }) => theme.spacing.s7}) !important;
    }
  }

  /*
    expand/collapse icon for JSON code stanzas
    and tree nodes (tree mode)
  */
  .cm-gutterElement > span,
  .jse-json-node .jse-expand {
    box-sizing: border-box;
    display: block;
    width: ${({ theme }) => theme.spacing.s4};
    height: ${({ theme }) => theme.spacing.s4};
    border: 0;
    overflow: hidden;
    text-indent: -1000px;
    opacity: 0.47;

    &:hover,
    &:focus {
      background-color: transparent;
      box-shadow: 0;
      opacity: 0.92;
    }
    &:active {
      opacity: 0.92;
    }
    &:focus-visible {
      box-shadow: 0 0 0 ${rem(1)} ${({ theme }) => theme.color.gray200};
    }
    svg {
      display: none;
    }
  }
  .cm-gutterElement > span[title="Fold line"],
  .jse-json-node.jse-expanded > .jse-header-outer .jse-expand {
    background-position: left ${rem(1)} top ${({ theme }) => theme.spacing.s1} !important;
    background-repeat: no-repeat !important;
    background-color: transparent !important;
    background-image: url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath d='M4.26667 6.4H11.7333L8 11.2L4.26667 6.4Z' fill='%230f1111'/%3e %3c/svg%3e") !important;
    background-size: ${({ theme }) => theme.spacing.s3} !important;
  }
  .cm-gutterElement > span[title="Unfold line"],
  .jse-json-node .jse-expand {
    background-position: left ${rem(1)} top ${({ theme }) => theme.spacing.s1} !important;
    background-repeat: no-repeat !important;
    background-color: transparent !important;
    background-image: url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath d='M6.4 11.7333L6.4 4.26667L11.2 8L6.4 11.7333Z' fill='%230f1111'/%3e %3c/svg%3e") !important;
    background-size: ${({ theme }) => theme.spacing.s3} !important;
  }

  /* adjust size/position for tree mode */
  .jse-json-node.jse-expanded > .jse-header-outer .jse-expand {
    background-position: center !important;
    background-size: ${({ theme }) => theme.spacing.s4} !important;
  }
  .jse-json-node .jse-expand {
    background-position: center !important;
    background-size: ${({ theme }) => theme.spacing.s4} !important;
  }

  /*
    TEXT MODE
    JSON code editor
  */

  .cm-editor {
    height: 100%;
    min-height: 100%;
    position: relative;

    /*
      main JSON content
      this is the peer of .cm-gutters and comes after it in DOM order
    */
    .cm-content {
      box-sizing: border-box;
    }

    /*
      collection of three columns:
      1. gutter lint
      2. line numbers
      3. fold controls (expand/collapse code blocks)
    */
    .cm-gutters {
      border-right: 0 !important;
      background-color: ${({ theme }) => theme.color.white} !important;
    }

    /*
      generic column within cm-gutters
      e.g. .cm-gutter.cm-gutter-lint
           .cm-gutter.cm-lineNumbers
    */
    .cm-gutter {
      position: relative;
    }

    /*
      column for error notifications
    */
    .cm-gutter-lint {
      .cm-gutterElement {
        padding: 0 !important;
      }
    }

    /* update style of lint error marker */
    .cm-lint-marker-error {
      box-sizing: border-box;
      content: none !important;
      display: flex;
      justify-content: center;
      align-items: center;
      width: ${({ theme }) => theme.spacing.s4};
      height: ${({ theme }) => theme.spacing.s4};
      padding-top: ${rem(3)} !important;

      &::before {
        content: "";
        display: block;
        width: ${({ theme }) => theme.spacing.s2};
        height: ${({ theme }) => theme.spacing.s2};
        border-radius: ${({ theme }) => theme.spacing.s4};
        background-color: ${({ theme }) => theme.color.red500};
      }
    }

    /*
      column for line numbers
    */
    .cm-lineNumbers {
      .cm-gutterElement {
        box-sizing: border-box;
        padding-right: ${rem(2)} !important;
      }
    }

    /*
      individual line (cell) within a .cm-gutter column number
    */
    .cm-gutterElement {
      padding: 0 !important;
      font-family: ${({ theme }) => theme.ui2Typography.fontFamilyCode};
      font-size: ${({ theme }) => theme.ui2Typography.fontSizeCode};
      font-weight: ${({ theme }) => theme.ui2Typography.fontWeightCode};
      line-height: ${rem(19)};
      color: ${({ theme }) => theme.color.gray500};
    }

    /*
      active line style

      the active line style is activated via a gutter element:
      .cm-gutter.cm-gutter-lint
        .cm-gutterElement.cm-activeLineGutter
      
      and on the line element:
      .cm-content
        .cm-line.cm-activeLine
    */
    .cm-activeLineGutter,
    .cm-activeLine {
      background-color: transparent !important;
    }
    .cm-activeLineGutter {
      border-top-left-radius: ${({ theme }) => theme.border.radiusSmall};
      border-bottom-left-radius: ${({ theme }) => theme.border.radiusSmall};
    }
    .cm-activeLine {
      border-top-right-radius: ${({ theme }) => theme.border.radiusSmall};
      border-bottom-right-radius: ${({ theme }) => theme.border.radiusSmall};
    }

    /*
      collapsed code block icon/style
    */
    .cm-foldPlaceholder {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: ${({ theme }) => theme.spacing.s6};
      height: ${({ theme }) => theme.spacing.s4};
      padding: 0 !important;
      position: relative;
      overflow: hidden;
      text-indent: -1000px;
      border-radius: ${({ theme }) => theme.border.radiusSmall} !important;
      background-color: ${({ theme }) => theme.color.gray400} !important;
      transform: translateY(${rem(0.5)});

      &::after {
        display: block;
        content: "";
        width: ${({ theme }) => theme.spacing.s6};
        height: ${({ theme }) => theme.spacing.s4};
        position: absolute;
        left: 0;
        top: 0;
        color: ${({ theme }) => theme.color.white};
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.375 8.125C4.375 7.43464 3.81536 6.875 3.125 6.875C2.43464 6.875 1.875 7.43464 1.875 8.125C1.875 8.81536 2.43464 9.375 3.125 9.375C3.81536 9.375 4.375 8.81536 4.375 8.125ZM7.875 6.875C8.56536 6.875 9.125 7.43464 9.125 8.125C9.125 8.81536 8.56536 9.375 7.875 9.375C7.18464 9.375 6.625 8.81536 6.625 8.125C6.625 7.43464 7.18464 6.875 7.875 6.875ZM12.875 6.875C13.5654 6.875 14.125 7.43464 14.125 8.125C14.125 8.81536 13.5654 9.375 12.875 9.375C12.1846 9.375 11.625 8.81536 11.625 8.125C11.625 7.43464 12.1846 6.875 12.875 6.875Z' fill='%23ffffff'/%3e %3c/svg%3e");
        background-size: ${({ theme }) => theme.spacing.s4};
      }

      &:hover,
      &:focus,
      &:active {
        background-color: ${({ theme }) => theme.color.gray600} !important;
      }
    }
  }

  /* error message at bottom of code editor */
  .jse-message.jse-error {
    display: flex;
    flex-direction: column;
    padding-top: ${({ theme }) => theme.spacing.s1} !important;
    padding-right: ${({ theme }) => theme.spacing.s3} !important;
    padding-bottom: ${({ theme }) => theme.spacing.s2} !important;
    padding-left: ${({ theme }) => theme.spacing.s3} !important;
    background-color: ${({ theme }) => theme.color.red600} !important;

    transform: translateY(${({ theme }) => theme.spacing["-s7"]});

    .fa-icon {
      width: ${rem(14)};
      transform: translateY(${rem(3)});
    }
    .jse-text {
      font-size: ${({ theme }) => theme.ui2Typography.fontSizeMeta1};
      font-weight: ${({ theme }) => theme.ui2Typography.fontWeightMeta1};
      line-height: ${({ theme }) => theme.ui2Typography.lineHeightMeta1};
    }

    /*
      disable the Show me action button in error message panel
      style the Auto repair button to match Console style and
      change label to say Auto repair all
    */
    .jse-actions {
      button:nth-of-type(2) {
        display: none !important;
      }
      button.jse-button.jse-action {
        ${(props) => sharedButtonStyles(props)}
        ${(props) =>
          getButtonSizeStyles({
            hasIcon: false,
            hasIcon2: false,
            isMultiSelect: false,
            size: "small",
            theme: props.theme,
          })}
        ${(props) =>
          getButtonTypeStyles({
            isMultiSelect: false,
            theme: props.theme,
            type: "outline-quiet",
          })}
        
        color: ${({ theme }) => theme.color.white};
        background-color: transparent;
        box-shadow: inset 0 0 0 ${rem(1.5)} ${({ theme }) => theme.color.white};
        opacity: 0.8;

        &:hover,
        &:focus,
        &:active {
          color: ${({ theme }) => theme.color.white};
          background-color: transparent;
          box-shadow: inset 0 0 0 ${rem(1.5)}
            ${({ theme }) => theme.color.white} !important;
          opacity: 1;
        }

        &:focus-visible {
          outline: none;
          box-shadow: inset 0 0 0 ${rem(1.5)}
              ${({ theme }) => theme.color.white},
            0 0 0 ${rem(3)} rgba(255, 255, 255, 0.4) !important;
          opacity: 1;
        }

        &::after {
          content: "all";
          margin-left: ${rem(4)};
        }

        svg {
          display: none;
        }
      }
    }
  }

  /* disable display of error message tooltip */
  .cm-tooltip-lint {
    display: none;
  }

  /* MENU BAR SECTION */

  /* disable table mode */
  .jse-menu {
    .jse-group-button {
      &:nth-of-type(3) {
        display: none !important;
      }
    }
  }

  /*
    menu bar structure in TEXT mode (DOM order):
    - text mode tab: button.jse-button.jse-group-button.jse-first.jse-selected 
    - tree mode tab: button.jse-button.jse-group-button  
    - table mode tab: button.jse-button.jse-group-button.jse-last 
    - vertical line: div.jse-separator 
    - format button: button.jse-button.jse-format
    - compact button: button.jse-button.jse-compact
    - vertical line: div.jse-separator
    - sort button: button.jse-button.jse-sort
    - transform button: button.jse-button.jse-transform
    - search button: button.jse-button.jse-search
    - vertical line: div.jse-separator 
    - undo button: button.jse-button.jse-undo
    - redo button: button.jse-button.jse-redo
  */

  /*
    disable control buttons and vertical separators for text mode
  */
  .jse-text-mode {
    .jse-menu {
      .jse-separator,
      .jse-format,
      .jse-compact,
      .jse-sort,
      .jse-transform,
      .jse-search,
      .jse-undo,
      .jse-redo {
        display: none !important;
      }
    }
  }

  /* style mode switch buttons */
  .jse-menu .jse-group-button {
    display: inline-block;
    font-family: ${({ theme }) =>
      theme.ui2Typography.fontFamilyMeta2} !important;
    font-size: ${({ theme }) => theme.ui2Typography.fontSizeMeta2} !important;
    font-weight: ${({ theme }) =>
      theme.ui2Typography.fontWeightMeta2} !important;
    line-height: ${({ theme }) =>
      theme.ui2Typography.lineHeightMeta2} !important;
    text-transform: capitalize;
    color: ${({ theme }) => theme.color.gray500} !important;
    border: 0 !important;
    border-radius: ${({ theme }) => theme.border.radiusSmall} !important;
    background-color: transparent !important;

    &::after {
      content: "mode";
      margin-left: ${rem(3)};
      text-transform: lowercase;
    }

    &:hover,
    &:focus,
    &:active {
      color: ${({ theme }) => theme.color.gray700};
      background-color: transparent !important;
    }
  }

  /*
    for text mode, move menu bar to the bottom, superimposed
    on the status bar so we don't have an additional toolbar
    cluttering the main code view (also the only control shown
    is the ability to switch to tree mode)
  */
  .jse-text-mode {
    .jse-menu {
      width: auto;
      position: absolute !important;
      right: ${({ theme }) => theme.spacing.s2};
      bottom: 0;
      z-index: 100;
      background-color: transparent !important;

      .jse-group-button {
        /* text mode button (hidden) */
        &:nth-of-type(1) {
          display: none !important;
        }
      }
    }
  }

  /*
    menu bar structure in TREE mode (DOM order):
    - text mode tab: button.jse-button.jse-group-button.jse-first.jse-selected 
    - tree mode tab: button.jse-button.jse-group-button  
    - table mode tab: button.jse-button.jse-group-button.jse-last 
    - vertical line: div.jse-separator 
    - expand all button: button.jse-button.jse-expand-all
    - collapse all button: jse-button.jse-collapse-all
    - vertical line: div.jse-separator
    - sort button: jse-button jse-sort
    - transform button: jse-button.jse-transform
    - search button: jse-button.jse-search
    - context menu button: jse-button.jse-contextmenu
    - vertical line: div.jse-separator
    - undo button: button.jse-button.jse-undo
    - redo button: button.jse-button.jse-redo
  
    - shown only in read only mode:
      copy button: button.jse-button.jse-copy
  */

  /*
    disable some controls and vertical separators for tree mode 
    only expand/collapse all, and search are shown
  */
  .jse-tree-mode {
    .jse-menu {
      .jse-separator,
      .jse-transform,
      .jse-contextmenu,
      .jse-sort,
      .jse-undo,
      .jse-redo,
      .jse-copy {
        display: none !important;
      }
    }
  }

  /*
    for tree mode, move the menu bar back to the top with
    the control bar actions shown
  */
  .jse-menu {
    height: ${({ theme }) => theme.spacing.s7};
    border-radius: ${({ theme }) => theme.border.radius};
    border-radius: 0;
    background-color: ${({ theme }) => theme.color.gray200} !important;
  }

  /* hide current "tree" mode indicator */
  .jse-tree-mode {
    .jse-menu {
      .jse-group-button {
        &:nth-of-type(2) {
          display: none !important;
        }
      }
    }
  }

  /* disable default editor control icons in tree mode */
  .jse-tree-mode {
    .jse-button {
      svg {
        display: none;
      }
    }
  }

  /*
    style expand, collapse, search control icons
  */
  .jse-tree-mode {
    .jse-collapse-all,
    .jse-expand-all,
    .jse-search {
      width: ${({ theme }) => theme.spacing.s6} !important;
      height: ${({ theme }) => theme.spacing.s6} !important;
      margin-top: ${({ theme }) => theme.spacing.s1} !important;
      opacity: 0.4;

      &:hover,
      &:focus,
      &:active {
        opacity: 1;
      }
    }

    .jse-button.jse-collapse-all {
      background-position: center !important;
      background-repeat: no-repeat !important;
      background-color: transparent !important;
      background-image: url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath d='M11.7167 1.25L7.98333 6.05L4.25 1.25H11.7167Z' fill='%230f1111'/%3e %3cpath d='M11.7167 14.75L7.98333 9.95L4.25 14.75H11.7167Z' fill='%230f1111'/%3e %3cpath d='M15 7.375H1V8.625H15V7.375Z' fill='%230f1111'/%3e %3c/svg%3e") !important;
      background-size: ${({ theme }) => theme.spacing.s4} !important;
    }

    .jse-button.jse-expand-all {
      background-position: center !important;
      background-repeat: no-repeat !important;
      background-color: transparent !important;
      background-image: url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath d='M11.7167 5.5L7.98333 0.7L4.25 5.5H11.7167Z' fill='%230f1111'/%3e %3cpath d='M11.7167 10.5L7.98333 15.3L4.25 10.5H11.7167Z' fill='%230f1111'/%3e %3cpath d='M15 7.375H1V8.625H15V7.375Z' fill='%230f1111'/%3e %3c/svg%3e") !important;
      background-size: ${({ theme }) => theme.spacing.s4} !important;
    }

    .jse-button.jse-search {
      background-position: center !important;
      background-repeat: no-repeat !important;
      background-color: transparent !important;
      background-image: url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.4936 6.4968C10.4936 8.70416 8.70416 10.4936 6.4968 10.4936C4.28943 10.4936 2.5 8.70416 2.5 6.4968C2.5 4.28943 4.28943 2.5 6.4968 2.5C8.70416 2.5 10.4936 4.28943 10.4936 6.4968ZM9.81715 10.8778C8.89467 11.578 7.74426 11.9936 6.4968 11.9936C3.461 11.9936 1 9.53259 1 6.4968C1 3.461 3.461 1 6.4968 1C9.53259 1 11.9936 3.461 11.9936 6.4968C11.9936 7.74426 11.578 8.89467 10.8778 9.81715L14.3562 13.2955L14.8865 13.8259L13.8259 14.8865L13.2955 14.3562L9.81715 10.8778Z' fill='%230f1111'/%3e %3c/svg%3e") !important;
      background-size: ${({ theme }) => theme.spacing.s4} !important;
    }
  }

  /*
    display search bar permanently and anchor within menu bar
  */
  .jse-tree-mode {
    .jse-search-box-container {
      display: block !important;
    }
    .jse-search-box-container,
    .jse-search-box,
    .jse-search-form,
    .jse-search-contents,
    .jse-search-section {
      height: ${({ theme }) => theme.spacing.s7} !important;
      margin: 0 !important;
      padding: 0 !important;
      border: 0 !important;
      box-shadow: none !important;
      overflow: visible !important;
      background-color: transparent !important;
    }

    .jse-search-box-container {
      width: ${rem(240)} !important;
      position: absolute !important;
      right: 0 !important;
      top: 0 !important;

      .jse-replace-toggle,
      .jse-search-icon,
      .jse-search-clear {
        display: none !important;
      }

      .jse-search-input-label,
      .jse-search-input {
        margin: 0 !important;
        font-size: ${({ theme }) =>
          theme.ui2Typography.fontSizeMeta2} !important;
        font-weight: ${({ theme }) =>
          theme.ui2Typography.fontWeightMeta2} !important;
        line-height: ${({ theme }) =>
          theme.ui2Typography.lineHeightMeta2} !important;
      }
      .jse-search-input::placeholder {
        color: ${({ theme }) => theme.color.gray500};
      }

      .jse-search-count {
        padding-right: ${({ theme }) => theme.spacing.s1} !important;
        padding-left: ${({ theme }) => theme.spacing.s1} !important;
        font-size: ${({ theme }) =>
          theme.ui2Typography.fontSizeMeta2} !important;
        font-weight: ${({ theme }) =>
          theme.ui2Typography.fontWeightMeta2} !important;
        line-height: ${({ theme }) =>
          theme.ui2Typography.lineHeightMeta2} !important;
      }

      .jse-search-next,
      .jse-search-previous {
        width: ${({ theme }) => theme.spacing.s4} !important;
        height: ${({ theme }) => theme.spacing.s4} !important;
        background-color: transparent !important;
        opacity: 0.4;

        &:hover,
        &:focus,
        &:active {
          background-color: transparent !important;
          opacity: 1;
        }

        svg {
          display: none;
        }
      }

      .jse-search-next {
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-color: transparent !important;
        background-image: url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 2.00167C8.36727 2.00167 8.665 2.2994 8.665 2.66667L8.665 11.7279L11.7964 8.59644C12.0561 8.33674 12.4772 8.33674 12.7369 8.59644C12.9966 8.85614 12.9966 9.27719 12.7369 9.53689L8.47022 13.8036C8.34551 13.9283 8.17636 13.9983 8 13.9983C7.82363 13.9983 7.65448 13.9283 7.52977 13.8036L3.2631 9.53689C3.00341 9.27719 3.00341 8.85614 3.2631 8.59644C3.5228 8.33674 3.94386 8.33674 4.20356 8.59644L7.335 11.7279L7.335 2.66667C7.335 2.2994 7.63273 2.00167 8 2.00167Z' fill='%230f1111'/%3e %3c/svg%3e") !important;
        background-size: ${({ theme }) => theme.spacing.s4} !important;
      }

      .jse-search-previous {
        margin-right: ${({ theme }) => theme.spacing.s2} !important;
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-color: transparent !important;
        background-image: url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.52978 2.19644C7.78948 1.93674 8.21053 1.93674 8.47023 2.19644L12.7369 6.46311C12.9966 6.72281 12.9966 7.14386 12.7369 7.40356C12.4772 7.66326 12.0561 7.66326 11.7964 7.40356L8.665 4.27212L8.665 13.3333C8.665 13.7006 8.36727 13.9983 8 13.9983C7.63274 13.9983 7.335 13.7006 7.335 13.3333L7.335 4.27212L4.20356 7.40356C3.94386 7.66326 3.52281 7.66326 3.26311 7.40356C3.00341 7.14386 3.00341 6.72281 3.26311 6.46311L7.52978 2.19644Z' fill='%230f1111'/%3e %3c/svg%3e") !important;
        background-size: ${({ theme }) => theme.spacing.s4} !important;
      }
    }
  }

  /*
    disable context menu indicator (horizontal line with control)
    that appears on hover when you're in tree mode
  */
  .jse-insert-area {
    display: none !important;
  }
`;
