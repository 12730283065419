import React, { useEffect, useMemo, useState } from "react";
import { Box, Loading, Notification, Text } from "@console/dsc";
import { CustomChartStyleRules } from "@console/dsc/src/components/Chart/Chart.styled";
import {
  getTickLabelStyle,
  getTickLabelStyleAlt,
} from "@console/dsc/src/components/Chart/utils/chartStyles";
import { formatNumber, rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";
import { DateTime } from "luxon";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { getOrgRunCount } from "../../../api/core/controlPlane";
import { OrganizationRunCount } from "../../../api/core/controlPlane.types";
import { useUser } from "../../../AuthProvider";
import Bin from "../../../components/Bin";
import NotFoundError from "../../../customErrors/NotFoundError";
import { formatDate } from "../../../utils/format";
import { emptyRunCount } from "../../Team/subpages/Usage/utils/runCounts";
import { MonthlyUsageData } from "../Dashboard.types";
import { getMonthlyUsageChartYAxisWidth } from "../utils/monthlyUsageChart";

import MonthlyUsageCustomTooltip from "./MonthlyUsageCustomTooltip";

const MonthlyUsage = () => {
  const [{ id: accountId }] = useUser();
  const theme = useTheme();

  const [teamRunCount, setTeamRunCount] = useState<OrganizationRunCount>();
  const [error, setError] = useState("");

  useEffect(() => {
    const loadTeamRunCount = async () => {
      setError("");
      try {
        const resp = await getOrgRunCount(accountId || "");
        setTeamRunCount(resp);
      } catch (e) {
        // set empty run response, 404 equivalent to 0 runs for Org.
        if (e instanceof NotFoundError) {
          setTeamRunCount(emptyRunCount);
          return;
        }
        console.error(e);
        setError((e as Error).message);
      }
    };

    !teamRunCount && loadTeamRunCount();
  }, [accountId, teamRunCount]);

  const teamUsageData = useMemo(() => {
    return (
      teamRunCount?.metrics &&
      Object.keys(teamRunCount.metrics).reduce((usageData, month) => {
        const fullMonthName = DateTime.fromISO(
          `${month}-15T00:00:00Z`
        ).toFormat("MMMM");
        usageData.push({
          id: month,
          value: teamRunCount.metrics[month].count,
          tooltipTitle: fullMonthName,
          label: fullMonthName.slice(0, 3),
        });
        return usageData;
      }, [] as MonthlyUsageData[])
    );
  }, [teamRunCount?.metrics]);

  const yAxisWidth = getMonthlyUsageChartYAxisWidth(teamUsageData);

  const renderContent = () => {
    if (!teamRunCount) {
      return <Loading />;
    }
    if (error) {
      return <Notification size="small" message={error} type="error" />;
    }
    if (!teamUsageData?.length) {
      return (
        <Box>
          <Text styleName="body-2">No usage has been recorded yet.</Text>
        </Box>
      );
    }
    return (
      <Box relative>
        <CustomChartStyleRules
          className={`chart-style-grouped-summary-box-plot`}
          yAxisWidth={yAxisWidth || 0}
          style={{
            width: "100%",
            position: "relative",
          }}
        >
          <ResponsiveContainer minHeight={350} width="100%">
            <BarChart data={teamUsageData?.slice(-12)}>
              <CartesianGrid
                stroke={theme.color.gray200T}
                vertical={false}
                style={{
                  backgroundColor: theme.color.gray100,
                }}
              />

              <Bar
                dataKey="value"
                barSize={24}
                fill={theme.color.green300}
                stroke={theme.color.green600}
                radius={2}
                isAnimationActive={false}
              />

              <Tooltip
                content={<MonthlyUsageCustomTooltip />}
                isAnimationActive={false}
                cursor={{ fill: theme.color.gray200T }}
              />

              <XAxis
                type="category"
                dataKey="label"
                domain={[0, "dataMax"]}
                stroke={theme.color.gray200T}
                // @ts-ignore
                style={getTickLabelStyleAlt(theme)}
                tickCount={6}
                tickLine={false}
                tickMargin={5}
              />

              <YAxis
                type="number"
                dataKey="value"
                stroke="transparent"
                style={getTickLabelStyle(theme)}
                allowDecimals={false}
                tickCount={6}
                tickLine={false}
                tickMargin={5}
                tickFormatter={(value) => formatNumber(value)}
                width={yAxisWidth}
              />
            </BarChart>
          </ResponsiveContainer>
        </CustomChartStyleRules>
      </Box>
    );
  };

  return (
    <Bin
      isMini
      width="100%"
      {...((!teamUsageData || !teamUsageData.length) && {
        styles: {
          minHeight: rem(380),
          backgroundImage: `url("/images/dashboard-monthly-usage-background.png")`,
          backgroundPosition: `left ${theme.spacing.s6} bottom ${theme.spacing.s4}`,
          backgroundRepeat: "no-repeat",
          backgroundSize: `min(${rem(423)}, calc(100% - ${
            theme.spacing.s6
          } * 2)) auto`,
        },
      })}
    >
      <Box pt={3} pr={2} pb={2} pl={3}>
        <Text styleName="header-2" styles={{ color: theme.color.gray800 }}>
          Monthly Usage
        </Text>
        {teamRunCount?.updated_at && (
          <Text mt={rem(3)} styleName="meta-1" hasLinkGray>
            Last updated on {formatDate(teamRunCount.updated_at)}
          </Text>
        )}

        <Box mt={6}>{renderContent()}</Box>
      </Box>
    </Bin>
  );
};

export default MonthlyUsage;
