import {
  RunsData,
  RunsDatum,
} from "../../../../../api/core/controlPlane.types";
import { TAB_ID_INPUT_SET } from "../../../../../config/experiments";
import { getPlanSetRunCount } from "../../../../../contexts/experiments/utils/planSets";
import { sortAlpha } from "../../../../../utils/sort";
import { PlanSet } from "../../../Experiments.types";
import { getOptionConfigByScenarioFromRuns } from "../../../utils/getOptionConfigByScenarioFromRuns";

export const getInputCount = (scenarioRuns: RunsDatum[]) => {
  const runCountsByScenario: { [key: string]: number } = {};
  const repetitionsObj: { [key: string]: number } = {};

  scenarioRuns.forEach((run) => {
    const scenarioId = run.scenario_id;

    // avoid over counting config option runs
    if (!scenarioId || scenarioId !== run.option_set) {
      return;
    }

    repetitionsObj[scenarioId] = Math.max(
      run.repetition || 0,
      repetitionsObj[scenarioId] || 0
    );

    if (runCountsByScenario[scenarioId]) {
      runCountsByScenario[scenarioId]++;
    } else {
      runCountsByScenario[scenarioId] = 1;
    }
  });

  // divide the run count per scenario by the number of repetitions to get the actual input count
  for (const key in runCountsByScenario) {
    if (runCountsByScenario[key]) {
      runCountsByScenario[key] =
        runCountsByScenario[key] / (repetitionsObj[key] + 1);
    }
  }
  return runCountsByScenario;
};

export const cloneScenarios = (scenarioRuns: RunsData) => {
  if (!scenarioRuns) {
    return [];
  }
  const inputSetCountObj = getInputCount(scenarioRuns);

  const configObj = getOptionConfigByScenarioFromRuns(scenarioRuns);
  const uniqueScenarioIdSet = new Set<string>();
  const clonedScenarios: PlanSet[] = [];

  for (const run of scenarioRuns) {
    const runScenarioId = run.scenario_id;
    if (!runScenarioId) {
      continue;
    }

    if (uniqueScenarioIdSet.has(runScenarioId)) {
      continue;
    }
    uniqueScenarioIdSet.add(runScenarioId);

    clonedScenarios.push({
      activeTabId: TAB_ID_INPUT_SET,
      configOptions: configObj[runScenarioId],
      id: runScenarioId,
      idError: null,
      inputCount: inputSetCountObj[runScenarioId],
      inputSetId: run.input_set_id || "",
      instances: [
        {
          instanceId: run.application_instance_id,
          versionId: run.application_version_id,
        },
      ],
      isInputSetAdded: false,
      runCount: 0,
      shouldUseFilesForInputSet: false,
    });
  }

  const clonedScenariosWithRunCount = clonedScenarios.map((scenario) => {
    scenario.runCount = getPlanSetRunCount(scenario);
    return scenario;
  });

  clonedScenariosWithRunCount.sort((a, b) => {
    return sortAlpha(a.id, b.id);
  });

  return clonedScenariosWithRunCount;
};
