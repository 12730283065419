import React from "react";
import { Box, Flex, Tabs, Text } from "@console/dsc";
import { useTheme } from "@emotion/react";

import { ConversationResponse } from "../../../api/core/controlPlane.types";
import Editor from "../../../components/Editor";
import { getDataStructuredForEditor } from "../../../utils/inputHelpers";
import { REQUEST_TAB_ID, RESPONSE_TAB_ID } from "../utils/constants";
import { StyledConversationContentContainer } from "../Webhooks.styled";
import {
  ConversationBodyTab,
  ConversationBodyTabType,
} from "../Webhooks.types";

type ConversationDetailsSectionProps = {
  conversation: ConversationResponse;
  title: string;
};

const ConversationEditorSection = ({
  conversation,
  title,
}: ConversationDetailsSectionProps) => {
  const theme = useTheme();
  const [activeTab, setActiveTab] =
    React.useState<ConversationBodyTabType>(REQUEST_TAB_ID);

  const conversationBodyTabs: ConversationBodyTab[] = [
    {
      id: REQUEST_TAB_ID,
      label: "Request",
      // isDisabled: !conversation.request_body,
    },
    {
      id: RESPONSE_TAB_ID,
      label: "Response",
      // isDisabled: !conversation.response_body,
    },
  ];

  const handleTabOnClick = (
    e: { preventDefault: () => void; stopPropagation: () => void },
    tab: ConversationBodyTab
  ) => {
    e.preventDefault();
    e.stopPropagation();
    if (!tab.isDisabled) {
      setActiveTab(tab.id);
    }
    return;
  };

  const getConversationTabs = (conversationBodyTabs: ConversationBodyTab[]) => {
    return conversationBodyTabs.map((conversationBodyTab) => {
      const conversationBodyTabId = conversationBodyTab.id;
      return {
        id: conversationBodyTabId,
        isActive: activeTab === conversationBodyTabId,
        isDisabled: !!conversationBodyTab.isDisabled,
        label: conversationBodyTab.label,
        onClick: (e: {
          preventDefault: () => void;
          stopPropagation: () => void;
        }) => handleTabOnClick(e, conversationBodyTab),
      };
    });
  };

  const formattedConversationRequestBody = getDataStructuredForEditor(
    conversation.request_body
  );

  return (
    <Flex
      pt={[5, 7]}
      ml={[0, -6]}
      mr={[0, -7]}
      pr={[0, 7]}
      pl={[0, 6]}
      hasBorderTop
      flexDirection="column"
    >
      <Text
        as="h2"
        styleName="header-1"
        styles={{ color: theme.color.gray800 }}
      >
        {title}
      </Text>
      <Box mt={5} ml={2}>
        <Tabs type="panel" tabs={getConversationTabs(conversationBodyTabs)} />
      </Box>

      <StyledConversationContentContainer>
        {activeTab === REQUEST_TAB_ID && (
          <Editor content={formattedConversationRequestBody} readOnly />
        )}
        {activeTab === RESPONSE_TAB_ID && (
          <Text as="pre" styleName="code" pl={1}>
            {String(conversation.response_body)}
          </Text>
        )}
      </StyledConversationContentContainer>
    </Flex>
  );
};

export default ConversationEditorSection;
