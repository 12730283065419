import { Flex } from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import styled from "@emotion/styled/macro";

export const StyledConversationContentContainer = styled(Flex)`
  width: 100%;
  height: 50vh;
  min-height: ${rem(420)};
  flex-direction: column;
  flex-grow: 1;
  padding-top: ${({ theme }) => theme.spacing.s4};
  padding-bottom: ${({ theme }) => theme.spacing.s4};
`;
