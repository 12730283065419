import React, { useEffect, useMemo, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { Loading } from "@console/dsc";
import { useTheme } from "@emotion/react";

import { trackEvent } from "../../../../analytics";
import { AppsData } from "../../../../api/core/controlPlane.types";
import { useUser } from "../../../../AuthProvider";
import Footer from "../../../../components/Footer";
import Header from "../../../../components/Header";
import { useMetaTitle } from "../../../../components/Layout";
import StandardError from "../../../../components/StandardError";
import { useAppsCollection } from "../../../../contexts/apps/Apps.context";
import { getFilteredEntities } from "../../../../utils/entities/getFilteredEntities";
import { getAccUrl } from "../../../../utils/navigation";
import { userHasAccessToAction } from "../../../../utils/rbac_utils";
import { ActionGroups } from "../../../../utils/rbac_utils/types";
import EmptyAppsMessage from "../../components/EmptyAppsMessage";
import { messageEmptySubscriptionApps } from "../../data/microcopy";
import { getAppsByType } from "../../utils/getAppsForDisplay";
import { renderAppsList } from "../../utils/renderAppsList";

const pageTitle = "Subscription";

const SubscribedApps = () => {
  const [{ features, id: accountId, roles }] = useUser();
  const [, setMetaTitle] = useMetaTitle();
  const theme = useTheme();
  const { path } = useRouteMatch();

  const [filterText, setFilterText] = useState("");

  const {
    additionalAppsLoading,
    apps,
    appsError,
    appsNextPageToken,
    loadApps,
    setAdditionalAppsLoading,
  } = useAppsCollection();

  // manage page display
  useEffect(() => {
    setMetaTitle(pageTitle);
  }, [setMetaTitle]);

  // load apps into context if not already present
  useEffect(() => {
    if (!apps && !appsError) {
      loadApps({});
    }
  }, [apps, appsError, loadApps]);

  const subscriptionApps = useMemo(
    () => getAppsByType({ apps, type: "subscription" }),
    [apps]
  );

  if (appsError) {
    return <StandardError errorMessage={appsError} />;
  }
  if (!apps) {
    return <Loading type="full-screen" dotColor={theme.color.orange500} />;
  }

  const canUserCreateAndEditApps = userHasAccessToAction(
    roles,
    ActionGroups.AppOperator,
    {}
  );

  const filteredApps = getFilteredEntities(filterText, subscriptionApps);

  const handleLoadMore = (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    e.preventDefault();
    e.stopPropagation();

    setAdditionalAppsLoading(true);
    loadApps({
      nextPageToken: appsNextPageToken,
      shouldAppend: true,
      shouldPaginate: true,
    });
    return;
  };

  return (
    <>
      <Header
        configPageTitle={{
          label: pageTitle,
          tooltipContent: messageEmptySubscriptionApps.content,
          tooltipExtraLinkLabel: "More",
          tooltipExtraLinkUrl: messageEmptySubscriptionApps.docsLink,
        }}
        configActionButton={{
          label: "Browse marketplace",
          url: getAccUrl(accountId, "/marketplace"),
          onClick: () =>
            trackEvent("Apps", {
              view: "Subscribed Apps",
              action: "Browse Marketplace Button Clicked",
            }),
          isActionAllowed: true,
        }}
        configFilter={
          filteredApps && !filteredApps.length && !filterText
            ? undefined
            : {
                inputText: filterText,
                testId: "filter-apps",
                setInputText: setFilterText,
              }
        }
      />

      {subscriptionApps && subscriptionApps.length ? (
        renderAppsList({
          canUserCreateAndEditApps,
          features,
          filteredApps: filteredApps as AppsData,
          path,
          roles,
          theme,
          userId: accountId,
          isSubscribed: true,
        })
      ) : (
        <EmptyAppsMessage
          message="Subscription apps will appear here."
          testId="message-zero-subscription-apps"
        />
      )}

      {!!apps?.length && (
        <Footer
          endpoint="applications"
          error={appsError}
          handleMainAction={handleLoadMore}
          isActionButtonDisabled={!appsNextPageToken}
          isActionButtonLoading={additionalAppsLoading}
          view="list"
        />
      )}
    </>
  );
};

export default SubscribedApps;
