import { useEffect, useState } from "react";
import { Flex, InputCheckbox } from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { isEmpty } from "lodash";

import Toggle from "../../../../../../components/Toggle";
import { ScrollYFlex, VehicleControlsHeader } from "../../RunDetails.styled";
import { PANEL_TABS_HEIGHT } from "../../utils/constants";
import VehicleControls from "../VehicleControls";
import {
  doAllVehiclesHaveRoutes,
  updateAllVehicleVisibility,
} from "../VehicleControls/utils/vehicleControls";

import { ResultControlsProps } from "./ResultControls.types";

export default function ResultControls({
  errorMessageFailedRoutes,
  mapState,
  outputObjModified,
  routeSet,
}: ResultControlsProps) {
  const {
    displayVehiclesNoRoutes,
    setDisplayVehiclesNoRoutes,
    setVisibleVehicleRoutesById,
    visibleVehicleRoutesById,
  } = mapState;

  const [isAllToggled, setIsAllToggled] = useState(true);
  const [isRoutesOnlyCheckboxDisplayed, setIsRoutesOnlyCheckboxDisplayed] =
    useState<null | boolean>(null);

  useEffect(() => {
    const routesById = routeSet?.routesById;
    if (isRoutesOnlyCheckboxDisplayed === null) {
      setIsRoutesOnlyCheckboxDisplayed(!doAllVehiclesHaveRoutes(routesById));
    }
  }, [isRoutesOnlyCheckboxDisplayed, routeSet?.routesById]);

  const toggleAllVehicles = () => {
    const newVisibleVehicleRoutesById = updateAllVehicleVisibility(
      visibleVehicleRoutesById,
      !isAllToggled
    );
    setVisibleVehicleRoutesById(newVisibleVehicleRoutesById);
    setIsAllToggled(!isAllToggled);
  };

  const isAllVehiclesToggleDisabled = isEmpty(outputObjModified);

  return (
    <>
      <VehicleControlsHeader
        pt={rem(2)}
        minHeight={PANEL_TABS_HEIGHT}
        mr={6}
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <Flex ml="auto" alignItems="center" height={PANEL_TABS_HEIGHT}>
          {isRoutesOnlyCheckboxDisplayed && (
            <InputCheckbox
              data-testid="routes-only-checkbox"
              isSmall
              mr={4}
              checked={!displayVehiclesNoRoutes}
              handleChange={() =>
                setDisplayVehiclesNoRoutes(!displayVehiclesNoRoutes)
              }
              id="routes-only"
              label="Routes only"
              value="routes-only"
            />
          )}
          <Toggle
            data-testid="toggle-all-vehicles"
            isActive={isAllToggled}
            isDisabled={isAllVehiclesToggleDisabled}
            size="x-small"
            label="All"
            onClickFunc={toggleAllVehicles}
          />
        </Flex>
      </VehicleControlsHeader>

      <ScrollYFlex mt={PANEL_TABS_HEIGHT} flexDirection="column">
        <VehicleControls
          mapState={mapState}
          errorMessageFailedRoutes={errorMessageFailedRoutes}
          routeSet={routeSet}
        />
      </ScrollYFlex>
    </>
  );
}
