import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Flex,
  Loading,
  Notification,
  Table2,
  Tag,
  Text,
} from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";
import { CellContext } from "@tanstack/react-table";

import { RunsDatum } from "../../../../../api/core/controlPlane.types";
import ControlPanel from "../../../../../components/ControlPanel";
import StandardError from "../../../../../components/StandardError";
import { getAccUrl } from "../../../../../utils/navigation";
import ExperimentRuns from "../../../../Experiments/components/ExperimentRuns";
import { runTooltips } from "../../../data/appTooltips";
import { renderEnsembleRuleDatum } from "../../EnsembleDef/utils/renderEnsembleRuleDatum";
import { Table2HeaderObj } from "../../RunHistory/RunHistory.types";
import {
  getChildOfQueryParam,
  getRunHistoryTableHeaders,
} from "../../RunHistory/utils/getRunHistoryTableHeaders";
import { StyledRunViewContainer } from "../RunDetails.styled";
import {
  RenderRuleDecisionSectionParams,
  RunDetailsEnsembleAnalysisProps,
} from "../RunDetails.types";

const RunDetailsEnsembleAnalysis = ({
  accountId,
  app,
  isExpanded,
  runMetadata,
  runEnsembleAnalysis,
  runEnsembleAnalysisError,
}: RunDetailsEnsembleAnalysisProps) => {
  const theme = useTheme();

  const bestRunId = runEnsembleAnalysis?.rules_result.best_run_id;

  const runHistoryHeaders: Table2HeaderObj[] = useMemo(() => {
    if (!runEnsembleAnalysis || !runEnsembleAnalysis?.child_runs) {
      return [];
    }
    return getRunHistoryTableHeaders({
      accId: accountId,
      appId: app.id,
      childOf: runMetadata?.id,
      childOfName: runMetadata?.name,
      highlightId: bestRunId,
      isLinkTargetBlank: true,
      runHistory: runEnsembleAnalysis?.child_runs,
      theme,
    });
  }, [
    accountId,
    app.id,
    bestRunId,
    runEnsembleAnalysis,
    runMetadata?.id,
    runMetadata?.name,
    theme,
  ]);

  if (runEnsembleAnalysisError) {
    return (
      <StyledRunViewContainer {...{ isExpanded }}>
        <StandardError errorMessage={runEnsembleAnalysisError} />
      </StyledRunViewContainer>
    );
  }
  if (!runEnsembleAnalysis) {
    return <Loading type="full-screen" dotColor={theme.color.orange500} />;
  }

  const renderRuleDecisionSection = ({
    data,
    sectionTitle,
  }: RenderRuleDecisionSectionParams) => {
    return (
      <>
        <Text styleName="body-2-bold" styles={{ color: theme.color.gray800 }}>
          {sectionTitle}
        </Text>

        {data.length ? (
          <Table2
            mt={2}
            data={data}
            layoutSchema={[
              { width: rem(200) },
              { width: rem(280) },
              { width: rem(180) },
              { width: rem(100) },
            ]}
            initialState={{
              columnVisibility: {
                isBestRun: false,
              },
            }}
            headers={[
              {
                id: "isBestRun",
                accessorKey: "isBestRun",
                cell: () => null,
              },
              {
                id: "runId",
                accessorKey: "runId",
                header: "Run ID",
                label: "Run ID",
                cell: (props: CellContext<RunsDatum, any>) => {
                  const runId = props.getValue();
                  const isRowBestRun = props.row.getValue("isBestRun");

                  return (
                    <Flex alignItems="center">
                      <Link
                        to={getAccUrl(
                          accountId,
                          `/app/${app.id}/run/${runId}${getChildOfQueryParam(
                            runMetadata?.id,
                            runMetadata?.name
                          )}`
                        )}
                        id={runId}
                        target="_blank"
                      >
                        {runId}
                      </Link>

                      {isRowBestRun && (
                        <Tag
                          ml={rem(6)}
                          label="Best"
                          size="small"
                          textColor={theme.color.yellow800}
                          backgroundColor={theme.color.yellow200}
                        />
                      )}
                    </Flex>
                  );
                },
              },
              {
                id: "statPath",
                accessorKey: "statPath",
                header: "Statistics path",
                label: "Statistics path",
                cell: (props: CellContext<RunsDatum, any>) => {
                  return (
                    <Text
                      styleName="code"
                      styles={{
                        fontSize: theme.ui2Typography.fontSizeMeta1,
                        lineHeight: theme.ui2Typography.lineHeightBody3,
                        wordBreak: "break-all",
                      }}
                    >
                      {props.getValue()}
                    </Text>
                  );
                },
              },
              {
                id: "statValue",
                accessorKey: "statValue",
                header: "Metric value",
                label: "Metric value",
                cell: (props: CellContext<RunsDatum, any>) => {
                  return (
                    <Text
                      styleName="code"
                      styles={{
                        fontSize: theme.ui2Typography.fontSizeMeta1,
                        lineHeight: theme.ui2Typography.lineHeightBody3,
                      }}
                    >
                      {props.getValue()}
                    </Text>
                  );
                },
              },
              {
                id: "index",
                accessorKey: "index",
                header: "Index",
                label: "Index",
                cell: (props: CellContext<RunsDatum, any>) => {
                  return (
                    <Text
                      styleName="code"
                      styles={{
                        fontSize: theme.ui2Typography.fontSizeMeta1,
                        lineHeight: theme.ui2Typography.lineHeightBody3,
                      }}
                    >
                      {props.getValue()}
                    </Text>
                  );
                },
              },
            ]}
          />
        ) : (
          <Text
            mt={1}
            pb={2}
            styleName="body-2"
            styles={{ color: theme.color.gray600 }}
          >
            None
          </Text>
        )}
      </>
    );
  };

  const ensembleDefRuleDecisionCount = runEnsembleAnalysis?.rules_result
    ?.decisions
    ? runEnsembleAnalysis.rules_result.decisions.length
    : 0;

  return (
    <StyledRunViewContainer {...{ isExpanded }}>
      {runEnsembleAnalysis.error && (
        <Notification
          mt={4}
          mb={-2}
          type="error"
          message={runEnsembleAnalysis.error}
        />
      )}

      {bestRunId && (
        <Box mr={[4, 6, 7]}>
          <ControlPanel
            headerTitle="Best Run"
            hasNoBorder
            isOpen
            stylesDetails={{ paddingLeft: 0 }}
            headerTooltipContent={runTooltips.detail.ensembleBestRunId.content}
            headerTooltipExtraLinkLabel={
              runTooltips.detail.ensembleBestRunId.extraLinkLabel
            }
            headerTooltipExtraLinkUrl={
              runTooltips.detail.ensembleBestRunId.extraLinkLabel
            }
          >
            <Flex pb={4}>
              <Text styleName="body2" hasLink>
                <Link
                  to={getAccUrl(
                    accountId,
                    `/app/${app.id}/run/${bestRunId}${getChildOfQueryParam(
                      runMetadata?.id,
                      runMetadata?.name
                    )}`
                  )}
                  id={bestRunId}
                  target="_blank"
                >
                  <Text as="strong" styleName="body-2-bold">
                    {bestRunId}
                  </Text>
                </Link>
              </Text>
            </Flex>
          </ControlPanel>
        </Box>
      )}

      {runEnsembleAnalysis?.rules_result.decisions &&
        runEnsembleAnalysis.rules_result.decisions.map((decision, index) => {
          const isLastRule = index === ensembleDefRuleDecisionCount - 1;

          const {
            statistics_path: statisticsPath,
            objective,
            tolerance,
            index: ruleIndex,
          } = decision.rule;

          return (
            <Box
              key={decision.rule.id}
              mr={[isExpanded ? 4 : 0, isExpanded ? 6 : 0, isExpanded ? 7 : 0]}
            >
              <ControlPanel
                headerTitle={decision.rule.id}
                isOpen
                hasCustomContentWrapper
                stylesDetails={{
                  paddingRight: 0,
                  paddingLeft: 0,
                }}
              >
                <Box ml={1} pb={4} pl={7}>
                  {renderEnsembleRuleDatum(
                    "Statistics path",
                    statisticsPath,
                    true
                  )}
                  {renderEnsembleRuleDatum("Objective", objective)}
                  {renderEnsembleRuleDatum(
                    "Tolerance",
                    `${tolerance.type}, ${tolerance.value}`
                  )}
                  {renderEnsembleRuleDatum("Index", `${ruleIndex}`)}

                  {decision.runs_in_tolerance && (
                    <Box mt={3} pt={5} hasBorderTop>
                      {renderRuleDecisionSection({
                        data: decision.runs_in_tolerance.map(
                          (toleranceRun) => ({
                            index: toleranceRun.index,
                            runId: toleranceRun.run_id,
                            statPath: decision.rule.statistics_path,
                            statValue: toleranceRun.metric_value,
                            isBestRun:
                              isLastRule &&
                              !!bestRunId &&
                              toleranceRun.run_id === bestRunId,
                          })
                        ),
                        sectionTitle: "Runs within tolerance",
                      })}
                    </Box>
                  )}
                  {decision.discarded_runs && (
                    <Box mt={4}>
                      {renderRuleDecisionSection({
                        data: decision.discarded_runs.map((discardRun) => ({
                          index: discardRun.index,
                          runId: discardRun.run_id,
                          statPath: decision.rule.statistics_path,
                          statValue: discardRun.metric_value,
                        })),
                        sectionTitle: "Discarded runs",
                      })}
                    </Box>
                  )}
                </Box>
              </ControlPanel>
            </Box>
          );
        })}

      <Box pr={7} pb={[5, 6]}>
        {runEnsembleAnalysis &&
          runEnsembleAnalysis?.child_runs &&
          runEnsembleAnalysis.child_runs.length && (
            <ExperimentRuns
              kind="ensemble"
              experimentRuns={runEnsembleAnalysis?.child_runs}
              runHistoryHeaders={runHistoryHeaders}
            />
          )}
      </Box>
    </StyledRunViewContainer>
  );
};

export default RunDetailsEnsembleAnalysis;
