import styled from "@emotion/styled/macro";
import { space } from "styled-system";

import { filterProps } from "../../lib/filterProps";
import { rem } from "../../lib/tools";
import Flex from "../Flex";

import {
  renderArrowBackgroundImage,
  renderArrowBackgroundPosition,
  renderArrowBottomPosition,
  renderArrowLeftPosition,
  renderArrowRightPosition,
  renderArrowTopPosition,
  renderBottomPosition,
  renderLeftPosition,
  renderRightPosition,
  renderTopPosition,
} from "./utils/renderArrow";
import { TooltipPlaceProps } from "./Tooltip.types";

export const TooltipContent = styled(Flex)`
  display: none;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  left: ${(props) =>
    renderLeftPosition({
      direction: props.direction,
      theme: props.theme,
    })};
  right: ${(props) =>
    renderRightPosition({
      direction: props.direction,
      theme: props.theme,
    })};
  top: ${(props) =>
    renderTopPosition({
      orientation: props.orientation,
      theme: props.theme,
    })};
  bottom: ${(props) =>
    renderBottomPosition({
      orientation: props.orientation,
      theme: props.theme,
    })};
  cursor: text;
  border-radius: ${({ theme }) => theme.border.radiusSmall};
  z-index: 1111;

  /*
    generic link styling that is applied to any a tag passed
    to the Tooltip content.
  */
  a {
    text-decoration: none;
    cursor: pointer;
    color: ${({ theme }) => theme.color.white};
    border-bottom-width: ${({ theme }) => theme.border.width};
    border-bottom-style: ${({ theme }) => theme.border.style};
    border-bottom-color: ${({ theme }) => theme.color.gray500};

    &:visited {
      color: ${({ theme }) => theme.color.gray200};
    }
    &:hover,
    &:focus,
    &:active {
      color: ${({ theme }) => theme.color.white};
      border-bottom-color: ${({ theme }) => theme.color.gray100};
    }
    &:focus {
      outline: none;
    }
    &:focus-visible {
      outline: none;
      color: ${({ theme }) => theme.color.white};
      border-bottom-color: ${({ theme }) => theme.color.gray100};
      box-shadow: 0 0 ${({ theme }) => theme.spacing.s1} ${rem(1)}
        ${({ theme }) => theme.color.gray400};
    }
  }

  /*
    this is made oversized so the cursor can be moved over to
    the tooltip content without it disappearing (that is, the
    :hover state is kept active)
  */
  &::before {
    content: "";
    display: block;
    width: ${({ theme }) => theme.spacing.s4};
    height: ${({ theme }) => theme.spacing.s4};
    position: absolute;
    left: ${(props) =>
      renderArrowLeftPosition({
        direction: props.direction,
        theme: props.theme,
      })};
    right: ${(props) =>
      renderArrowRightPosition({
        direction: props.direction,
        theme: props.theme,
      })};
    top: ${(props) =>
      renderArrowTopPosition({
        orientation: props.orientation,
        theme: props.theme,
      })};
    bottom: ${(props) =>
      renderArrowBottomPosition({
        orientation: props.orientation,
        theme: props.theme,
      })};
    background-image: ${(props) =>
      renderArrowBackgroundImage({
        direction: props.direction,
        theme: props.theme,
      })};
    background-position: ${(props) =>
      renderArrowBackgroundPosition({
        direction: props.direction,
        theme: props.theme,
      })};
    background-repeat: no-repeat;
    background-size: ${rem(5)} ${rem(9)};
  }
`;

const getTooltipColor = (hexColorString?: string) => {
  if (hexColorString) {
    return hexColorString.replace("#", "%23");
  }
};

export const TooltipPlace = styled("div", {
  shouldForwardProp: (prop: any) => filterProps(prop),
})<TooltipPlaceProps>`
  ${space}

  width: ${({ theme }) => theme.spacing.s4};
  height: ${({ theme }) => theme.spacing.s4};
  position: relative;
  cursor: pointer;
  background-image: ${(props) =>
    `url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='${getTooltipColor(
      props.hexColorString || props.theme.color.gray600
    )}' xmlns='http://www.w3.org/2000/svg'%3e %3cpath fill-rule='evenodd' clip-rule='evenodd' d='M1 8.00221C1 4.135 4.135 1 8.00221 1C11.8694 1 15.0044 4.135 15.0044 8.00221C15.0044 11.8694 11.8694 15.0044 8.00221 15.0044C4.135 15.0044 1 11.8694 1 8.00221ZM8.00221 1.95C4.65967 1.95 1.95 4.65967 1.95 8.00221C1.95 11.3447 4.65967 14.0544 8.00221 14.0544C11.3447 14.0544 14.0544 11.3447 14.0544 8.00221C14.0544 4.65967 11.3447 1.95 8.00221 1.95ZM8.79855 11.1876C8.79855 11.6274 8.44205 11.9839 8.00227 11.9839C7.5625 11.9839 7.20599 11.6274 7.20599 11.1876C7.20599 10.7478 7.5625 10.3913 8.00227 10.3913C8.44205 10.3913 8.79855 10.7478 8.79855 11.1876ZM6.42894 6.67536C6.42894 5.93576 7.06646 5.23465 8.00235 5.23465C8.93824 5.23465 9.57577 5.93576 9.57577 6.67536C9.57577 7.21575 9.27491 7.49099 8.7785 7.79255C8.72205 7.82685 8.65751 7.86433 8.58881 7.90423C8.39475 8.01693 8.16755 8.14888 7.99609 8.28248C7.74218 8.48032 7.45235 8.79503 7.45235 9.27654C7.45235 9.5803 7.6986 9.82654 8.00235 9.82654C8.30611 9.82654 8.55235 9.5803 8.55235 9.27654L8.55229 9.27539C8.55211 9.27332 8.55197 9.27169 8.55818 9.26188C8.57023 9.24286 8.60075 9.20584 8.67218 9.15018C8.78174 9.06481 8.90461 8.99359 9.06605 8.90002L9.06608 8.9L9.06613 8.89997C9.14925 8.85179 9.2426 8.79769 9.34962 8.73267C9.91493 8.38925 10.6758 7.82839 10.6758 6.67536C10.6758 5.29154 9.50839 4.13465 8.00235 4.13465C6.49631 4.13465 5.32893 5.29154 5.32893 6.67536C5.32893 6.97911 5.57518 7.22536 5.87893 7.22536C6.18269 7.22536 6.42894 6.97911 6.42894 6.67536Z'/%3e %3c/svg%3e")`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: ${({ theme }) => theme.spacing.s4};
  z-index: 11;

  &:hover,
  &:focus,
  &:active {
    background-image: ${({ theme }) =>
      `url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='${theme.color.gray800.replace(
        "#",
        "%23"
      )}' xmlns='http://www.w3.org/2000/svg'%3e %3cpath fill-rule='evenodd' clip-rule='evenodd' d='M1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8ZM8.00011 5.23244C7.06422 5.23244 6.42669 5.93355 6.42669 6.67315C6.42669 6.97691 6.18045 7.22315 5.87669 7.22315C5.57293 7.22315 5.32669 6.97691 5.32669 6.67315C5.32669 5.28933 6.49407 4.13244 8.00011 4.13244C9.50615 4.13244 10.6735 5.28933 10.6735 6.67315C10.6735 7.82618 9.91268 8.38704 9.34738 8.73046C9.24034 8.79549 9.14698 8.8496 9.06385 8.89778L9.06383 8.89779L9.06382 8.8978C8.90237 8.99138 8.7795 9.0626 8.66993 9.14797C8.5985 9.20363 8.56799 9.24065 8.55594 9.25968C8.54973 9.26948 8.54987 9.27112 8.55004 9.27318L8.55011 9.27433C8.55011 9.57809 8.30386 9.82434 8.00011 9.82434C7.69635 9.82434 7.45011 9.57809 7.45011 9.27434C7.45011 8.79282 7.73993 8.47811 7.99385 8.28027C8.1653 8.14668 8.3925 8.01473 8.58656 7.90202L8.58656 7.90202L8.58661 7.90199C8.65529 7.86211 8.71982 7.82463 8.77625 7.79035C9.27266 7.48878 9.57353 7.21354 9.57353 6.67315C9.57353 5.93355 8.936 5.23244 8.00011 5.23244ZM8.79631 11.1854C8.79631 11.6252 8.4398 11.9817 8.00003 11.9817C7.56025 11.9817 7.20374 11.6252 7.20374 11.1854C7.20374 10.7456 7.56025 10.3891 8.00003 10.3891C8.4398 10.3891 8.79631 10.7456 8.79631 11.1854Z' /%3e %3c/svg%3e")`};
    z-index: 9999;

    ${TooltipContent} {
      display: flex;
    }
  }
`;
