import {
  ScenarioTestOptionSets,
  ScenarioTestRun,
} from "../../../api/core/controlPlane.types";
import { DEFAULT_PLAN_SET_ID_LABEL } from "../../../config/experiments";
import useManageEntity from "../../../hooks/useManageEntity";
import { PlanSet } from "../../../pages/Experiments/Experiments.types";
import { LATEST_INSTANCE_ID } from "../../../utils/constants";
import {
  getFilteredPlanSetConfigOptions,
  getPlanSetConfigOptionValueSets,
} from "../utils/planSets";

type GetPlanPayloadReturn = {
  option_sets: ScenarioTestOptionSets;
  runs: ScenarioTestRun[];
};
type GetScenarioTestPayloadParams = {
  appId: string;
  isSubscriptionApp: boolean;
  planSets: PlanSet[];
  repetitions: number;
  latestVersionId?: string;
};

const useBatchExperiments = () => {
  const { loadEntity: loadInputSet } = useManageEntity("experiments/inputsets");

  const getScenarioTestPayload = async ({
    appId,
    isSubscriptionApp,
    latestVersionId,
    planSets,
    repetitions,
  }: GetScenarioTestPayloadParams): Promise<GetPlanPayloadReturn> => {
    const finalPayload: GetPlanPayloadReturn = {
      option_sets: {},
      runs: [],
    };

    let planSetRuns: ScenarioTestRun[] = [];

    for (const planSet of planSets) {
      // set up base data
      const scenarioId =
        planSet.id || `${DEFAULT_PLAN_SET_ID_LABEL}-${Date.now()}`;

      const configOptions = getFilteredPlanSetConfigOptions(
        planSet.configOptions
      );
      const inputSetId = planSet.inputSetId;
      const instance = planSet.instances.filter(Boolean)[0];
      const instanceId = instance?.instanceId;

      // ensure latest version id is used for latest instance
      const versionId =
        !isSubscriptionApp && instanceId === LATEST_INSTANCE_ID
          ? latestVersionId
          : instance?.versionId;

      // skip plan set if no input set or instance specified
      if (!instanceId || !versionId || !inputSetId) {
        continue;
      }

      // get input set inputs
      const inputSet = await loadInputSet(appId, inputSetId, true);

      let inputSetInputIds: string[] | undefined;
      if (inputSet?.input_ids && inputSet.input_ids.length) {
        inputSetInputIds = inputSet?.input_ids;
      }
      if (inputSet?.inputs && inputSet?.inputs.length) {
        inputSetInputIds = inputSet?.inputs.map((input) => input.id);
      }

      // skip plan set if no input IDs in input set (backend error)
      if (!inputSetInputIds?.length) {
        continue;
      }

      // get cartesian cross product of parsed config values
      // (if comma-delimited values, spread into unique option sets)
      const cartesianConfigOptionValueSets =
        getPlanSetConfigOptionValueSets(configOptions);

      // get config options
      const configOptionsOptions = configOptions.map(
        (configOption) => configOption.option
      );

      // create payload for option_sets and initial runs
      const numberOfPlanSets = cartesianConfigOptionValueSets.length;

      for (let index = 0; index < numberOfPlanSets; index++) {
        const subPlanSetId = `${scenarioId}${index ? `_${index}` : ""}`;
        const valueSet = cartesianConfigOptionValueSets[index];

        finalPayload["option_sets"][subPlanSetId] = configOptionsOptions.reduce(
          (configOptionsObj, configOption, index) => {
            configOptionsObj[configOption] = valueSet[index];
            return configOptionsObj;
          },
          {} as {
            [key: string]: string;
          }
        );

        // add runs to runs array
        planSetRuns = [
          ...planSetRuns,
          ...inputSetInputIds.map((inputId) => {
            return {
              input_id: inputId,
              input_set_id: inputSet?.id,
              instance_id: instanceId,
              option_set: subPlanSetId,
              scenario_id: scenarioId,
              repetition: 0,
              version_id: versionId,
            };
          }),
        ];
      }
    }

    // repeat runs as necessary
    const repeatedPlanSetRuns = new Array(repetitions)
      .fill(undefined)
      .flatMap((_empty, index) => {
        return planSetRuns.map((planSetRun) => {
          return {
            ...planSetRun,
            repetition: index + 1,
          };
        });
      });

    finalPayload["runs"] = [...planSetRuns, ...repeatedPlanSetRuns];

    return finalPayload;
  };

  return {
    getScenarioTestPayload,
  };
};

export default useBatchExperiments;
