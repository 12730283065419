import React from "react";
import { rem } from "@console/dsc/src/lib/tools";

import Modal2 from "../../../../../components/Modal2";
import { ConfirmTeamMemberRemovalModalProps } from "../Members.types";
import {
  getModalContentTeamMemberInviteRemoval,
  getModalContentTeamMemberRemoval,
} from "../utils/modalContent";

const ConfirmTeamMemberRemovalModal = ({
  exitModal,
  teamMemberRemovalProcessing: inviteProcessing,
  removeTeamMember,
  teamMember,
}: ConfirmTeamMemberRemovalModalProps) => {
  const isPending = teamMember.pending_invite;
  const modalContent = isPending
    ? getModalContentTeamMemberInviteRemoval(teamMember)
    : getModalContentTeamMemberRemoval(teamMember);

  return (
    <Modal2
      isActive={!!teamMember}
      width={rem(600)}
      title={modalContent.title}
      summary={modalContent.summary}
      closeActionOnClick={(e: any) => exitModal({ e })}
      primaryActionLabel={modalContent.primaryActionLabel}
      primaryActionOnClick={(e: any) => removeTeamMember({ e, teamMember })}
      primaryIsDisabled={inviteProcessing}
      primaryIsLoading={inviteProcessing}
      secondaryActionLabel={modalContent.secondaryActionLabel}
      secondaryActionOnClick={(e: any) => exitModal({ e })}
    />
  );
};

export default ConfirmTeamMemberRemovalModal;
