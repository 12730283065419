import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  AvatarEnsembleDef,
  Box,
  Flex,
  Loading,
  RowDetail,
  Tag,
  Text,
} from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";

import { trackEvent } from "../../../../analytics";
import { useUser } from "../../../../AuthProvider";
import Header from "../../../../components/Header";
import { useMetaTitle } from "../../../../components/Layout";
import StandardError from "../../../../components/StandardError";
import { ENDPOINT_ENSEMBLES } from "../../../../config/endpoints";
import { MEASURE_ENTITY_ROW } from "../../../../config/general";
import useManageEntity from "../../../../hooks/useManageEntity";
import { formatDate } from "../../../../utils/format";
import { userHasAccessToAction } from "../../../../utils/rbac_utils";
import { ActionGroups } from "../../../../utils/rbac_utils/types";
import { AppPageProps } from "../../App.types";
import { ensembleDefinitionTooltips } from "../../data/appTooltips";
import useReturnPaths from "../../hooks/useReturnPaths";

import EnsembleRules from "./components/EnsembleRules";
import EnsembleRunGroups from "./components/EnsembleRunGroups";

const EnsembleDef = ({ app }: AppPageProps) => {
  const [{ roles }] = useUser();
  const theme = useTheme();
  const [, setMetaTitle] = useMetaTitle();
  const params = useParams() as { id: string };
  const { pathname } = useLocation();
  const { returnPathList } = useReturnPaths();

  const {
    entity: ensembleDefinition,
    entityLoadError: ensembleDefinitionError,
    loadEntity: loadEnsembleDef,
  } = useManageEntity(ENDPOINT_ENSEMBLES);

  // page meta display
  useEffect(() => {
    setMetaTitle(ensembleDefinition?.name || "");
  }, [setMetaTitle, ensembleDefinition]);

  // load ensemble definition details
  useEffect(() => {
    if (!ensembleDefinition && !ensembleDefinitionError) {
      loadEnsembleDef(app.id, params.id);
    }
  }, [
    app.id,
    ensembleDefinition,
    ensembleDefinitionError,
    loadEnsembleDef,
    params.id,
  ]);

  if (ensembleDefinitionError) {
    return <StandardError errorMessage={ensembleDefinitionError} />;
  }
  if (!ensembleDefinition) {
    return <Loading type="full-screen" dotColor={theme.color.orange500} />;
  }

  const canUserCreateAndEditEnsembleDefs = userHasAccessToAction(
    roles,
    ActionGroups.RunOperator,
    {}
  );

  return (
    <>
      <Header
        configPageTitle={{
          label: ensembleDefinition.name,
          ancestorIcon: <AvatarEnsembleDef />,
          ancestorLabel: "Ensemble Definitions",
          ancestorUrl: returnPathList,
        }}
        configActionButton={{
          label: "Edit",
          url: `${pathname}/edit`,
          onClick: () =>
            trackEvent("EnsembleDefs", {
              view: "Ensemble Def",
              action: "Edit Button Clicked",
            }),
          isActionAllowed: canUserCreateAndEditEnsembleDefs,
        }}
        secondaryButton={{
          label: "New ensemble definition",
          url: `${returnPathList}/new`,
          isActionAllowed: canUserCreateAndEditEnsembleDefs,
        }}
      />

      <RowDetail
        hasNoBorder
        property="Name"
        render={
          <Box maxWidth={MEASURE_ENTITY_ROW}>
            <Text styleName="body-2">{ensembleDefinition.name}</Text>
          </Box>
        }
      />

      <RowDetail
        property="ID"
        tooltipCopy={ensembleDefinitionTooltips.detail.content}
        render={
          <Flex>
            <Tag
              ml={rem(-6)}
              type="id"
              label={ensembleDefinition.id}
              contentToCopy={ensembleDefinition.id}
            />
          </Flex>
        }
      />

      {ensembleDefinition.description && (
        <RowDetail
          property="Description"
          render={
            <Box maxWidth={MEASURE_ENTITY_ROW}>
              <Text styleName="body-2">{ensembleDefinition.description}</Text>
            </Box>
          }
        />
      )}

      <RowDetail
        property="Date created"
        render={
          <Box maxWidth={MEASURE_ENTITY_ROW}>
            <Text
              as="time"
              styleName="body-2"
              htmlTitle={ensembleDefinition.created_at}
            >
              {formatDate(ensembleDefinition.created_at, true)}
            </Text>
          </Box>
        }
      />

      <RowDetail
        property="Last updated"
        render={
          <Box maxWidth={MEASURE_ENTITY_ROW}>
            <Text
              as="time"
              styleName="body-2"
              htmlTitle={ensembleDefinition.updated_at}
            >
              {formatDate(ensembleDefinition.updated_at, true)}
            </Text>
          </Box>
        }
      />

      <RowDetail
        property="Run groups"
        tooltipCopy={ensembleDefinitionTooltips.create.runGroups.content}
        tooltipExtraLinkLabel={
          ensembleDefinitionTooltips.create.runGroups.extraLinkLabel
        }
        tooltipExtraLinkUrl={
          ensembleDefinitionTooltips.create.runGroups.extraLinkUrl
        }
        render={
          <EnsembleRunGroups
            appId={app.id}
            runGroups={ensembleDefinition.run_groups}
          />
        }
      />

      <RowDetail
        property="Rules"
        tooltipCopy={ensembleDefinitionTooltips.create.rules.content}
        tooltipExtraLinkLabel={
          ensembleDefinitionTooltips.create.rules.extraLinkLabel
        }
        tooltipExtraLinkUrl={
          ensembleDefinitionTooltips.create.rules.extraLinkUrl
        }
        secondaryLabel="Evaluation priority corresponds to rule order"
        render={<EnsembleRules rules={ensembleDefinition.rules} />}
      />
    </>
  );
};

export default EnsembleDef;
