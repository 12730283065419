import React from "react";
import { Flex } from "@console/dsc";
import { useTheme } from "@emotion/react";

import { trackEvent } from "../../../analytics";
import SocialIcon from "../../SocialIcon";

const SocialIcons = () => {
  const theme = useTheme();

  return (
    <Flex alignItems="center" ml="auto">
      <SocialIcon
        onClick={() =>
          trackEvent("Footer Social Link Clicked", {
            type: "GitHub",
          })
        }
        iconType="github"
        url="https://github.com/nextmv-io"
        iconSize={20}
        iconColor="rgba(255,255,255,0.4)"
        iconColorHover={theme.color.white}
        iconColorFocus={theme.color.white}
        iconColorActive={theme.color.white}
      />
      <SocialIcon
        onClick={() =>
          trackEvent("Footer Social Link Clicked", {
            type: "Twitter",
          })
        }
        ml={3}
        iconType="twitter"
        url="https://twitter.com/getnextmv"
        iconSize={20}
        iconColor="rgba(255,255,255,0.4)"
        iconColorHover={theme.color.white}
        iconColorFocus={theme.color.white}
        iconColorActive={theme.color.white}
      />
      <SocialIcon
        onClick={() =>
          trackEvent("Footer Social Link Clicked", {
            type: "LinkedIn",
          })
        }
        ml={3}
        iconType="linkedin"
        url="https://www.linkedin.com/company/nextmv/"
        iconSize={20}
        iconColor="rgba(255,255,255,0.4)"
        iconColorHover={theme.color.white}
        iconColorFocus={theme.color.white}
        iconColorActive={theme.color.white}
      />
      <SocialIcon
        onClick={() =>
          trackEvent("Footer Social Link Clicked", {
            type: "YouTube",
          })
        }
        ml={4}
        iconType="youtube"
        url="https://www.youtube.com/channel/UCM9PRPJwP9w-lPS_uaJh17g"
        iconSize={20}
        iconColor="rgba(255,255,255,0.4)"
        iconColorHover={theme.color.white}
        iconColorFocus={theme.color.white}
        iconColorActive={theme.color.white}
      />
    </Flex>
  );
};

export default SocialIcons;
