import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Box, Flex, Text } from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";

import { trackEvent } from "../../analytics";
import { useMetaTitle, withLayoutProvider } from "../Layout/Layout.context";
import Logo from "../Logo";

import CustomerLogos from "./components/CustomerLogos";
import LandingIllustration from "./components/LandingIllustration";
import SocialIcons from "./components/SocialIcons";
import { PublicMain } from "./LayoutPublic.styled";

type LayoutPublicProps = {
  children: React.ReactNode;
};

const currentYear = new Date().getFullYear();

const LayoutPublic = ({ children }: LayoutPublicProps) => {
  const [metaTitle] = useMetaTitle();
  const theme = useTheme();

  const renderLayout = () => (
    <Flex width="100%" minHeight="100vh">
      <PublicMain>
        <Flex height="100%" flexDirection={["column", "column", "row"]}>
          <Flex
            width={[1 / 1, 1 / 1, 3 / 7]}
            order={[1, 1, 0]}
            pt={[8, 8, 6]}
            pr={[6, 6, 10]}
            pb={[7, 8]}
            pl={[6, 6, 9]}
            flexGrow={[1, 1, 0]}
            flexDirection="column"
            justifyContent="space-between"
            background={theme.color.orgNavy}
          >
            <Flex
              width="100%"
              flexGrow={1}
              alignItems="flex-end"
              justifyContent="flex-start"
              pb={10}
              ml={-4}
            >
              <Box width={rem(320)} noShrink style={{ opacity: 0.86 }}>
                <LandingIllustration />
              </Box>
            </Flex>

            <Box mt="auto" width="100%">
              <CustomerLogos />

              <Flex
                mt={2}
                pt={4}
                width="100%"
                justifyContent="space-between"
                hasBorderTop
                style={{ borderColor: "rgba(255, 255, 255, 0.1)" }}
              >
                <Text
                  styleName="body-2"
                  styles={{ color: theme.color.white, opacity: 0.57 }}
                >
                  &copy; nextmv.io inc. {currentYear}
                </Text>

                <SocialIcons />
              </Flex>
            </Box>
          </Flex>

          <Flex
            width={[1 / 1, 1 / 1, 4 / 7]}
            order={[0, 0, 1]}
            pl={[6, 9, rem(72)]}
            pr={[6, 9, rem(72)]}
            flexDirection="column"
            background={theme.color.white}
          >
            <Box mt={[7, 7, 10]}>
              <Link
                to="/"
                onClick={() =>
                  trackEvent("AuthFlow", {
                    view: "General",
                    action: "Logo Upper Left Clicked",
                  })
                }
              >
                <Logo />
              </Link>
            </Box>

            <Flex
              maxWidth={rem(640)}
              flexGrow={1}
              pb={[10, 10, 11]}
              flexDirection="column"
              justifyContent="center"
              style={{ position: "relative" }}
            >
              <Box minHeight={["auto", "auto", rem(390)]}>{children}</Box>
            </Flex>
          </Flex>
        </Flex>
      </PublicMain>
    </Flex>
  );

  return (
    <>
      <Helmet>
        <title>{metaTitle} | Nextmv Cloud</title>
        <meta property="og:title" content={`${metaTitle} | Nextmv Cloud`} />
      </Helmet>

      {renderLayout()}
    </>
  );
};

export default withLayoutProvider(LayoutPublic);
