import {
  AvatarExperimentAcceptance,
  AvatarExperimentBatch,
  AvatarExperimentScenario,
  AvatarExperimentShadow,
  AvatarExperimentSwitchback,
  AvatarInputSet,
  Box,
  Flex,
  PreviewRow2,
  Tag,
  Text,
} from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";

import { trackEvent } from "../../../analytics";
import { TrackEvents } from "../../../analytics/functions";
import {
  AcceptanceTestResponse,
  AppResponse,
  BatchExperimentResponse,
  ExperimentStatus,
  InputSetResponse,
  ShadowTestResponse,
  SwitchbackTestResponse,
} from "../../../api/core/controlPlane.types";
import { capitalizeFirstLetter, formatPlural } from "../../../utils/format";
import { getAccUrl } from "../../../utils/navigation";
import {
  getShadowTestEndCriteria,
  getShadowTestInstances,
} from "../subpages/ShadowTests/utils/shadowTestMeta";

export type ExperimentType =
  | "acceptance"
  | "batch"
  | "input-set"
  | "scenario"
  | "shadow"
  | "switchback";
type Experiment =
  | AcceptanceTestResponse
  | BatchExperimentResponse
  | InputSetResponse
  | ShadowTestResponse
  | SwitchbackTestResponse;

type TrackEventCategories =
  | "AcceptanceTests"
  | "Experiments"
  | "Dashboard"
  | "InputSets"
  | "Scenario"
  | "ShadowTests"
  | "SwitchbackTests";
type TrackEventProperties =
  | TrackEvents["AcceptanceTests"]
  | TrackEvents["Experiments"]
  | TrackEvents["Dashboard"]
  | TrackEvents["InputSets"]
  | TrackEvents["Scenario"]
  | TrackEvents["ShadowTests"]
  | TrackEvents["SwitchbackTests"];

type RenderExperimentsListParams = {
  app: AppResponse;
  experiments:
    | null
    | (InputSetResponse & { kind?: ExperimentType })[]
    | (AcceptanceTestResponse & { kind?: ExperimentType })[]
    | (BatchExperimentResponse & { kind?: ExperimentType })[]
    | (ShadowTestResponse & { kind?: ExperimentType })[]
    | (SwitchbackTestResponse & { kind?: ExperimentType })[]
    | (Experiment & { kind?: ExperimentType })[];
  theme: any;
  canUserCreateAndEdit: boolean;
  accountId?: string | null;
  isMini?: boolean;
  kind?: ExperimentType;
  limit?: number;
  urlEdit?: string;
  urlEditOnClickTrackEventCategory?: TrackEventCategories;
  urlEditOnClickTrackEventProperties?: TrackEventProperties;
  urlOnClickTrackEventCategory?: TrackEventCategories;
  urlOnClickTrackEventProperties?: TrackEventProperties;
};

type RenderExperimentAvatarParams = Pick<
  RenderExperimentsListParams,
  "kind" | "isMini"
>;

type RenderPreviewRowMetaParams = Pick<RenderExperimentsListParams, "kind"> & {
  experiment: Experiment;
  theme: any;
};

type RenderExperimentStatusParams = {
  status: ExperimentStatus;
  theme: any;
};

const renderExperimentAvatar = ({
  kind,
  isMini,
}: RenderExperimentAvatarParams): React.ReactNode | undefined => {
  switch (kind) {
    case "acceptance":
      return <AvatarExperimentAcceptance size={isMini ? 20 : 24} />;
    case "batch":
      return <AvatarExperimentBatch size={isMini ? 20 : 24} />;
    case "input-set":
      return <AvatarInputSet size={24} />;
    case "scenario":
      return <AvatarExperimentScenario size={isMini ? 20 : 24} />;
    case "shadow":
      return <AvatarExperimentShadow size={isMini ? 20 : 24} />;
    case "switchback":
      return <AvatarExperimentSwitchback size={isMini ? 20 : 24} />;
    default:
      return;
  }
};

const renderExperimentStatus = ({
  status,
  theme,
}: RenderExperimentStatusParams): React.ReactNode => {
  const isStatusFailed = status === "failed";
  return (
    <Text
      styleName="body-3"
      styles={{
        fontWeight: isStatusFailed
          ? theme.ui2Typography.fontWeightBody3Bold
          : theme.ui2Typography.fontWeightBody3,
        color: isStatusFailed ? theme.color.red600 : theme.color.gray700,
      }}
    >
      {capitalizeFirstLetter(status)}
    </Text>
  );
};

const getInputsCount = (inputSet: InputSetResponse) => {
  if (inputSet.inputs.length) {
    return inputSet.inputs.length;
  }
  if (inputSet.input_ids.length) {
    return inputSet.input_ids.length;
  }
  return 0;
};

const renderMetaSubDescription = ({
  experiment,
  kind,
  theme,
}: RenderPreviewRowMetaParams): React.ReactNode | undefined => {
  if (kind === "batch" || kind === "scenario") {
    const batchExperiment = experiment as BatchExperimentResponse;
    return (
      <Flex mt={rem(1)} alignItems="flex-end">
        {renderExperimentStatus({ status: batchExperiment.status, theme })}

        <Text ml={2} styleName="body-3" styles={{ color: theme.color.gray600 }}>
          ({batchExperiment.number_of_runs} runs)
        </Text>
      </Flex>
    );
  }
  if (kind === "input-set") {
    const inputSet = experiment as InputSetResponse;
    return (
      <Text styleName="body-3" styles={{ color: theme.color.gray600 }}>
        {getInputsCount(inputSet)}{" "}
        {formatPlural("input file", inputSet.input_ids.length, "s")}
      </Text>
    );
  }
  if (kind && ["shadow", "switchback"].includes(kind)) {
    const test = experiment as ShadowTestResponse | SwitchbackTestResponse;
    return (
      <Flex mt={rem(1)} alignItems="flex-end">
        {renderExperimentStatus({ status: test.status, theme })}
      </Flex>
    );
  }
  return;
};

const renderMetaExtra1 = ({
  experiment,
  kind,
}: RenderPreviewRowMetaParams): React.ReactNode | undefined => {
  let instanceIds: string[] = [];

  if (kind === "acceptance") {
    const acceptanceTest = experiment as AcceptanceTestResponse;
    instanceIds = [
      acceptanceTest.control.instance_id,
      acceptanceTest.candidate.instance_id,
    ];
  }
  if (kind === "batch") {
    const batchExperiment = experiment as BatchExperimentResponse;
    instanceIds = [...batchExperiment.instance_ids];
  }
  if (kind === "scenario") {
    const scenarioTest = experiment as BatchExperimentResponse;
    const scenarioCount = scenarioTest.option_sets
      ? Object.keys(scenarioTest.option_sets).length
      : 0;
    return (
      <Text mt={rem(2)} styleName="body-3">
        Scenarios: {scenarioCount}
      </Text>
    );
  }
  if (kind === "shadow") {
    const shadowTest = experiment as ShadowTestResponse;
    const shadowTestInstances = getShadowTestInstances(shadowTest);
    if (shadowTestInstances) {
      instanceIds = [
        shadowTestInstances?.baselineInstances,
        shadowTestInstances?.candidateInstances,
      ];
    }
  }
  if (kind === "switchback") {
    const switchbackTest = experiment as SwitchbackTestResponse;
    instanceIds = [
      switchbackTest.comparison.baseline_instance_id,
      switchbackTest.comparison.candidate_instance_id,
    ];
  }

  return (
    !!instanceIds.length && (
      <Flex alignItems="flex-start">
        <Text mt={rem(2)} styleName="body-3">
          Instances
        </Text>
        <Flex alignItems="flex-start" flexWrap="wrap">
          {instanceIds.map((instanceId, index) => (
            <Tag
              key={`${instanceId}-${index}`}
              mt={rem(1)}
              ml={1}
              label={instanceId}
            />
          ))}
        </Flex>
      </Flex>
    )
  );
};

const renderMetaExtra2 = ({
  experiment,
  kind,
  theme,
}: RenderPreviewRowMetaParams): React.ReactNode | undefined => {
  if (kind === "batch") {
    const batchExperiment = experiment as BatchExperimentResponse;
    return (
      <Flex alignItems="flex-end" mt={rem(1)}>
        <Text styleName="body-3">Input&nbsp;set</Text>
        <Text ml={2} styleName="body-3" styles={{ color: theme.color.gray600 }}>
          {batchExperiment.input_set_id}
        </Text>
      </Flex>
    );
  }
  if (kind === "shadow") {
    const shadowTest = experiment as ShadowTestResponse;
    const shadowTestEndCriteria = getShadowTestEndCriteria(shadowTest);

    return (
      <Flex alignItems="flex-end" mt={rem(2)}>
        <Text styleName="body-3">End criteria</Text>

        {shadowTestEndCriteria.map((endCriterion) => (
          <Text key={endCriterion.id} styleName="body-3" ml={2}>
            {endCriterion.shortType}: {endCriterion.shortValue}
          </Text>
        ))}
      </Flex>
    );
  }
  return;
};

const getEntityName = (kind?: ExperimentType) => {
  if (!kind) return "entities";

  switch (kind) {
    case "batch":
      return "batch experiments";
    case "input-set":
      return "input sets";
    default:
      return `${kind} tests`;
  }
};

export const renderExperimentsList = ({
  accountId,
  app,
  canUserCreateAndEdit,
  experiments,
  isMini,
  kind,
  limit,
  theme,
  urlEditOnClickTrackEventCategory,
  urlEditOnClickTrackEventProperties,
  urlOnClickTrackEventCategory,
  urlOnClickTrackEventProperties,
}: RenderExperimentsListParams) => {
  if (!experiments) return;

  if (!experiments.length) {
    return (
      <Text
        testId={`${kind}-not-found-message`}
        mt={isMini ? 3 : 5}
        styleName={isMini ? "body-3" : "body-2"}
      >
        No {getEntityName(kind)} found.
      </Text>
    );
  }

  const returnedExperiments = limit ? experiments.slice(0, limit) : experiments;

  return returnedExperiments.map((experiment, index) => {
    const experimentType = experiment?.kind || kind;

    return (
      <PreviewRow2
        key={`${experiment.id}-${index}`}
        hasNoBorder={index === 0 ? true : false}
        icon={
          <Box mt={rem(1)}>
            {renderExperimentAvatar({ kind: experimentType })}
          </Box>
        }
        name={experiment.name}
        id={experiment.id}
        description={experiment?.description}
        lastUpdated={experiment?.updated_at}
        metaSubDescription={renderMetaSubDescription({
          experiment,
          theme,
          kind: experimentType,
        })}
        metaExtra1={renderMetaExtra1({
          experiment,
          theme,
          kind: experimentType,
        })}
        metaExtra2={renderMetaExtra2({
          experiment,
          theme,
          kind: experimentType,
        })}
        url={getAccUrl(
          accountId,
          `/app/${app.id}/experiment/${experimentType}/${experiment.id}`
        )}
        {...(urlOnClickTrackEventCategory &&
          urlOnClickTrackEventProperties && {
            urlOnClick: () =>
              trackEvent(
                urlOnClickTrackEventCategory,
                urlOnClickTrackEventProperties
              ),
          })}
        {...(canUserCreateAndEdit && {
          urlEdit: getAccUrl(
            accountId,
            `/app/${app.id}/experiment/${experimentType}/${experiment.id}/edit?source=list`
          ),
        })}
        {...(canUserCreateAndEdit &&
          urlEditOnClickTrackEventCategory &&
          urlEditOnClickTrackEventProperties && {
            urlEditOnClick: () =>
              trackEvent(
                urlEditOnClickTrackEventCategory,
                urlEditOnClickTrackEventProperties
              ),
          })}
      />
    );
  });
};
