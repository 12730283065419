import React, { useEffect, useMemo, useState } from "react";
import { Box, Tabs } from "@console/dsc";
import { isEmpty } from "lodash";

import { useCoreFetchWithAccount } from "../../../../../api/core/dataPlane.hooks";
import { useUser } from "../../../../../AuthProvider";
import { getBannerType } from "../../../../../components/Banner/utils/getBannerType";
import InputEditor from "../../../../../components/InputEditor";
import Map from "../../../../../components/Map";
import useMapState from "../../../../../components/Map/hooks/useMapState";
import {
  SolutionRoute,
  SolutionRoutes,
} from "../../../../../components/Map/Map.types";
import { EMPTY_ROUTE_SET } from "../../../../../components/Map/utils/constants";
import { getMapMarkerPoints } from "../../../../../components/Map/utils/getMapMarkerPoints";
import OutputEditor from "../../../../../components/OutputEditor";
import {
  StyledControlPanel,
  StyledControlPanelTabsContainer,
  StyledInputEditorContainer,
  StyledMapContainer,
  StyledRunResultContainer,
  StyledSolutionOutputContainer,
} from "../RunDetails.styled";
import {
  PanelViewType,
  RunDetailsRenderViewProps,
  RunRoutingModifiedOutput,
} from "../RunDetails.types";
import {
  getModifiedCustomerHSDOutputSolutions,
  getModifiedNextrouteOutputSolutions,
  getModifiedRouterOutputSolutions,
  getModifiedRoutingLegacyOutputSolutions,
} from "../utils/getModifiedRunOutputSolutions";
import { getNewSolutionRoute } from "../utils/getNewSolutionRoute";
import { getResultTabs } from "../utils/getResultPanelTabs";
import { getVisibleVehicleRoutesById } from "../utils/getVisibleVehicleRoutes";

import DownloadRunInput from "./DownloadRunInput";
import DownloadRunOutput from "./DownloadRunOutput";
import ResultControls from "./ResultControls";

const RunDetailsRoutingInputAndResult = ({
  appId,
  isInputBelowRenderThreshold,
  shouldReduceVisuals,
  isOutputBelowRenderThreshold,
  mainView,
  run,
  runAsString,
  runInput,
  runInputAsString,
  runMetadata,
  runGenre,
}: RunDetailsRenderViewProps) => {
  const [user] = useUser();
  const bannerType = getBannerType(user);

  const [panelView, setPanelView] = useState<PanelViewType>("vehicles");
  const [outputObjModified, setOutputObjModified] =
    useState<RunRoutingModifiedOutput>();
  const [solutionRoutes, setSolutionRoutes] = useState<SolutionRoutes>([]);
  const [routeSet, setRouteSet] = useState<SolutionRoute>(EMPTY_ROUTE_SET);
  const [errorMessageFailedRoutes, setErrorMessageFailedRoutes] = useState("");

  const fetchWithAccount = useCoreFetchWithAccount();

  // this is for map display, must match v0 DEMO schema which
  // is a solutions array (note: this differs from v0 run schema)
  const modifiedRunDetailsOutput = useMemo(() => {
    let runDetailsOutputClone = JSON.parse(JSON.stringify(run.output));
    let runDetailsOutputSolutionsModified = undefined;

    if (runGenre === "nextroute") {
      runDetailsOutputSolutionsModified = getModifiedNextrouteOutputSolutions(
        run.output
      );
    }
    if (runGenre === "router") {
      runDetailsOutputSolutionsModified = getModifiedRouterOutputSolutions(
        run.output
      );
    }
    if (runGenre === "routing-legacy") {
      runDetailsOutputSolutionsModified =
        getModifiedRoutingLegacyOutputSolutions(run.output);
    }
    if (runGenre === "customer-hsd") {
      runDetailsOutputSolutionsModified = getModifiedCustomerHSDOutputSolutions(
        run.output
      );
    }

    runDetailsOutputClone.solutions = runDetailsOutputSolutionsModified;

    return runDetailsOutputClone;
  }, [run.output, runGenre]);

  // runSolutionRoute is equivalent to newSolutionRoute in useSolutionRouteChange()
  // it's an extra item needed to help the run details view fit in the Demo page pattern
  const [runSolutionRoute, setRunSolutionRoute] = useState<SolutionRoute>();

  // check to ensure run details input is loaded
  // unless we refactor (from what I can tell), the inputState expects a default
  // input config based on the Demo setup; so we setup an empty input as the default
  // but need a way to check to see if the run input has been loaded in its place
  const [runInputLoaded, setRunInputLoaded] = useState(false);

  const mapState = useMapState();
  const { setVisibleVehicleRoutesById } = mapState;

  // set modified run output to match schema for visualization
  useEffect(() => {
    isEmpty(outputObjModified) &&
      setOutputObjModified(modifiedRunDetailsOutput);
  }, [modifiedRunDetailsOutput, outputObjModified, setOutputObjModified]);

  // load run input
  useEffect(() => {
    if (!runInputLoaded) {
      setRunInputLoaded(true);
    }
  }, [runInputLoaded]);

  // set solution routes
  useEffect(() => {
    !runSolutionRoute &&
      isOutputBelowRenderThreshold &&
      getNewSolutionRoute({
        fetchWithAccount,
        solutions: outputObjModified && outputObjModified.solutions,
        setErrorMessageFailedRoutes,
        setRunSolutionRoute,
      });

    if (runSolutionRoute && !solutionRoutes.length) {
      const solutionRoutesArr =
        !solutionRoutes.length && outputObjModified?.solutions?.length
          ? new Array(outputObjModified.solutions.length)
          : solutionRoutes;

      const newSolutionsArray = Object.assign([], solutionRoutesArr, {
        0: runSolutionRoute,
      }) as SolutionRoutes;

      setSolutionRoutes(newSolutionsArray);
      setVisibleVehicleRoutesById(
        getVisibleVehicleRoutesById(runSolutionRoute.routesById)
      );
      setRouteSet(runSolutionRoute);
    }
  }, [
    fetchWithAccount,
    isOutputBelowRenderThreshold,
    outputObjModified,
    runSolutionRoute,
    setErrorMessageFailedRoutes,
    setRouteSet,
    setSolutionRoutes,
    setVisibleVehicleRoutesById,
    solutionRoutes,
  ]);

  const markerPoints = getMapMarkerPoints(runInput);

  const resultTabs = getResultTabs({
    panelView,
    hasOutput: !isEmpty(outputObjModified),
    setPanelView,
  });

  const renderResultView = () => (
    <>
      <DownloadRunOutput
        {...{ appId, runMetadata }}
        isVehicleVisualizationView
      />

      <StyledControlPanelTabsContainer>
        <Tabs ml={4} tabs={resultTabs} />
      </StyledControlPanelTabsContainer>

      {panelView === "vehicles" && (
        <ResultControls
          errorMessageFailedRoutes={errorMessageFailedRoutes}
          mapState={mapState}
          outputObjModified={outputObjModified}
          routeSet={routeSet}
        />
      )}

      {panelView === "output" && (
        <StyledSolutionOutputContainer>
          <OutputEditor value={runAsString || ""} />
        </StyledSolutionOutputContainer>
      )}
    </>
  );

  const renderInputView = () => (
    <StyledInputEditorContainer>
      <DownloadRunInput {...{ appId, runMetadata }} />

      {runInputLoaded && <InputEditor input={runInputAsString} isReadOnly />}
    </StyledInputEditorContainer>
  );

  const renderStandardRoutingView = () => (
    <StyledRunResultContainer hasBanner={!!bannerType}>
      <StyledControlPanel>
        {mainView === "input" && renderInputView()}
        {["result", "analysis"].includes(mainView) && renderResultView()}
      </StyledControlPanel>

      {["input", "result"].includes(mainView) && (
        <StyledMapContainer
          {...(shouldReduceVisuals && {
            className: "large-file-render",
          })}
        >
          <Map
            activeTab={mainView}
            mapState={mapState}
            markerCoords={markerPoints}
            routeSet={routeSet}
          />
        </StyledMapContainer>
      )}
    </StyledRunResultContainer>
  );

  if (mainView === "input") {
    return !isInputBelowRenderThreshold ? (
      <Box pt={10}>
        <DownloadRunInput {...{ appId, runMetadata }} />
      </Box>
    ) : (
      renderStandardRoutingView()
    );
  }
  if (mainView === "result") {
    return !isOutputBelowRenderThreshold ? (
      <Box pt={10}>
        <DownloadRunOutput
          {...{ appId, runMetadata }}
          isVehicleVisualizationView
        />
      </Box>
    ) : (
      renderStandardRoutingView()
    );
  }

  // should never get here
  return <></>;
};

export default RunDetailsRoutingInputAndResult;
