import { useState } from "react";
import { InputSize } from "@console/dsc/src/components/Input/Input.types";
import { kebabCase } from "lodash";

import { trackEvent, TrackEvents } from "../analytics/functions";
import {
  AppEntityEndpoints,
  AppResponse,
  WebhookEntityEndpoints,
} from "../api/core/controlPlane.types";
import {
  ENTITY_ID_CHAR_COUNT_MAX,
  ENTITY_ID_CHAR_COUNT_MIN,
  ENTITY_NAME_CHAR_COUNT_MAX,
  ENTITY_NAME_CHAR_COUNT_MIN,
} from "../utils/constants";
import { CONTENT_ID_RULES, CONTENT_NAME_RULES } from "../utils/systemIds";

export type StandardInputType = "name" | "id" | "description";
export type UseStandardInputReturnValue = ReturnType<typeof useStandardInputs>;
export type StandardInputPayloads = {
  id: string;
  name: string;
  description?: string;
};

type CheckStandardInputsForErrorParams = {
  type: StandardInputType;
  checkMinLength?: boolean;
  input?: string;
};

export const standardInputValueByKey = {
  name: (value: string) => value.trimStart(),
  id: (value: string) => value.trimStart().replace(" ", "-"),
  description: (value: string) => value,
};

const emptyStandardInputPayload = {
  id: "",
  name: "",
  description: "",
};

export const getEmptyEntityError = (type: "name" | "id") => {
  const isId = type === "id";
  const typeLabel = isId ? type.toUpperCase() : type;
  return `Please enter ${isId ? "an" : "a"} ${typeLabel}. ${
    isId ? CONTENT_ID_RULES : ""
  }`;
};

const useStandardInputs = (
  app?: AppResponse,
  entityEndpoint?: keyof AppEntityEndpoints | keyof WebhookEntityEndpoints,
  isEditView = false
) => {
  const [pendingStandardInputs, setPendingStandardInputs] =
    useState<StandardInputPayloads>(emptyStandardInputPayload);
  const [idUpdated, setIdUpdated] = useState<boolean>(false);
  const [standardInputsErrors, setStandardInputsErrors] = useState({
    description: "",
    id: "",
    name: "",
  });

  const checkStandardInputsForError = ({
    checkMinLength,
    input,
    type,
  }: CheckStandardInputsForErrorParams) => {
    if (!input && (type === "name" || type === "id")) {
      return setStandardInputsErrors((prevState) => ({
        ...prevState,
        [type]: getEmptyEntityError(type),
      }));
    }
    if (!input) return;

    if (type === "name") {
      if (input.length > ENTITY_NAME_CHAR_COUNT_MAX) {
        return setStandardInputsErrors((prevState) => ({
          ...prevState,
          name: CONTENT_NAME_RULES,
        }));
      }
      if (checkMinLength && input.length < ENTITY_NAME_CHAR_COUNT_MIN) {
        return setStandardInputsErrors((prevState) => ({
          ...prevState,
          name: CONTENT_NAME_RULES,
        }));
      }
      return setStandardInputsErrors((prevState) => ({
        ...prevState,
        name: "",
      }));
    }

    if (type === "id" && !isEditView) {
      if (input.length > ENTITY_ID_CHAR_COUNT_MAX) {
        return setStandardInputsErrors((prevState) => ({
          ...prevState,
          id: CONTENT_ID_RULES,
        }));
      }
      if (checkMinLength && input.length < ENTITY_ID_CHAR_COUNT_MIN) {
        return setStandardInputsErrors((prevState) => ({
          ...prevState,
          id: CONTENT_ID_RULES,
        }));
      }

      return setStandardInputsErrors((prevState) => ({
        ...prevState,
        id: "",
      }));
    }
  };

  const handleStandardInputsChange = <T extends StandardInputType>({
    target: { name: targetName, value: targetValue },
  }: {
    target: { name: T; value: StandardInputPayloads[T] };
  }) => {
    if (targetValue && targetName === "id") {
      setIdUpdated(true);

      checkStandardInputsForError({
        input: targetValue,
        type: "id",
      });
    }
    if (targetValue && targetName === "name") {
      checkStandardInputsForError({
        input: targetValue,
        type: "name",
      });
      if (!idUpdated) {
        checkStandardInputsForError({
          input: kebabCase(targetValue),
          type: "id",
        });
      }
    }
    setPendingStandardInputs({
      ...pendingStandardInputs,
      [targetName]: standardInputValueByKey[targetName](targetValue || ""),
      ...(!idUpdated && targetName === "name" && !isEditView
        ? { id: kebabCase(targetValue) }
        : {}),
    });
  };

  const handleStandardInputsOnBlur =
    <T extends StandardInputType>({
      target: { name: targetName, value: targetValue },
    }: {
      target: { name: T; value: StandardInputPayloads[T] };
    }) =>
    <T extends keyof TrackEvents>({
      trackEventCategory,
      trackEventProperties,
    }: {
      trackEventCategory?: T;
      trackEventProperties?: TrackEvents[T];
    }) => {
      // only check min length requirement on blur so
      // UI does not error immediately on input entry
      checkStandardInputsForError({
        checkMinLength: true,
        input: targetValue,
        type: targetName,
      });

      trackEventCategory &&
        trackEventProperties &&
        handleStandardInputsTrack({
          targetName: targetName,
          targetValue: targetValue || "",
          trackEventCategory,
          trackEventProperties,
        });
    };

  const handleStandardInputsTrack = <T extends keyof TrackEvents>({
    targetName,
    targetValue,
    trackEventCategory,
    trackEventProperties,
  }: {
    targetName: StandardInputType;
    targetValue: string;
    trackEventCategory: T;
    trackEventProperties: TrackEvents[T];
  }) => {
    if (targetValue) {
      if (
        targetName === "id" &&
        targetValue !== kebabCase(pendingStandardInputs.name)
      ) {
        trackEvent(trackEventCategory, trackEventProperties);
      }
      if (["name", "description"].includes(targetName)) {
        trackEvent(trackEventCategory, trackEventProperties);
      }
    }
  };

  // pre-fill group of standard inputs
  const updateStandardInputs = (
    inputKeyValuePairs: { key: StandardInputType; value: string | undefined }[]
  ) => {
    const standardInputsObj = inputKeyValuePairs.reduce(
      (obj, inputKeyValue) => {
        const { key, value } = inputKeyValue;
        obj[key] = standardInputValueByKey[key](value || "");
        if (key === "name") {
          obj["id"] = kebabCase(value);
        }
        return obj;
      },
      {} as StandardInputPayloads
    );

    setPendingStandardInputs({
      ...pendingStandardInputs,
      ...standardInputsObj,
    });
  };

  const getMaxLength = (type: StandardInputType): number | undefined => {
    switch (type) {
      case "id":
        return ENTITY_ID_CHAR_COUNT_MAX;
      case "name":
        return ENTITY_NAME_CHAR_COUNT_MAX;
      default:
        return undefined;
    }
  };

  const getStandardInputsProps = <T extends keyof TrackEvents>({
    isDisabled,
    placeholder,
    testId,
    trackEventCategory,
    trackEventProperties,
    size,
    type,
  }: {
    placeholder: string;
    testId: string;
    type: StandardInputType;
    isDisabled?: boolean;
    size?: InputSize;
    trackEventCategory?: T;
    trackEventProperties?: TrackEvents[T];
  }) => {
    return {
      errorMessage: standardInputsErrors[type],
      errorMessageTestId: `${testId}-error`,
      htmlType: "text",
      isError: !!standardInputsErrors[type],
      isDisabled,
      maxLength: getMaxLength(type),
      name: type,
      onBlur: (e: any) =>
        handleStandardInputsOnBlur(e)({
          trackEventCategory,
          trackEventProperties,
        }),
      onChange: handleStandardInputsChange,
      placeholder,
      size,
      testId,
      value: pendingStandardInputs[type],
    };
  };

  return {
    getMaxLength,
    getStandardInputsProps,
    handleStandardInputsChange,
    handleStandardInputsTrack,
    pendingStandardInputs,
    setPendingStandardInputs,
    setStandardInputsErrors,
    standardInputsErrors,
    updateStandardInputs,
  };
};

export default useStandardInputs;
