import React, { useEffect, useState } from "react";
import { Flex, Text, Tooltip } from "@console/dsc";
import { useTheme } from "@emotion/react";

import {
  MarketplacePartnerApp,
  MarketplacePartnerAppLocalReturn,
} from "../../../../api/core/controlPlane.types";
import { useUser } from "../../../../AuthProvider";
import Header from "../../../../components/Header";
import { useMetaTitle } from "../../../../components/Layout";
import NewUserQuestions from "../../../../components/NewUserQuestions";
import { useMarketplaceCollection } from "../../../../contexts/marketplace/Marketplace.context";
import { useIsNewUser } from "../../../../utils";
import { KNAPSACK_SUBSCRIPTION_ID } from "../../../MarketplaceApp/data/marketAppNextmvKnapsack";
import { NEXTROUTE_SUBSCRIPTION_ID } from "../../../MarketplaceApp/data/marketAppNextroute";
import { ORTOOLS_KNAPSACK_SUBSCRIPTION_ID } from "../../../MarketplaceApp/data/marketAppORToolsKnapsack";
import { PYOMO_KNAPSACK_SUBSCRIPTION_ID } from "../../../MarketplaceApp/data/marketAppPyomoKnapsack";
import CustomAppBanner from "../../components/CustomAppBanner";
import MarketplaceAppPreview from "../../components/MarketplaceAppPreview";
import { marketplaceApps } from "../../data/marketplaceApps";
import { StyledGrid } from "../../Marketplace.styled";

const pageTitle = "Apps";

const MarketplaceApps = () => {
  const [{ features, id: accountId }] = useUser();
  const [, setMetaTitle] = useMetaTitle();
  const theme = useTheme();
  const [isNewUser, setIsNewUser] = useIsNewUser();

  const [featuredApps, setFeaturedApps] =
    useState<MarketplacePartnerAppLocalReturn[]>();
  const [apps, setApps] = useState<MarketplacePartnerAppLocalReturn[]>();
  const [starterTemplates, setStarterTemplates] =
    useState<MarketplacePartnerAppLocalReturn[]>();

  const { appSubscriptions, appSubscriptionsLoading, loadAppSubscriptions } =
    useMarketplaceCollection();

  // manage page display
  useEffect(() => {
    setMetaTitle(pageTitle);
  }, [setMetaTitle]);

  // load locally-defined marketplace apps
  // apps on this page are managed entirely on the front-end
  useEffect(() => {
    if (!apps) {
      const collectFeaturedApps: MarketplacePartnerAppLocalReturn[] = [];
      const collectApps: MarketplacePartnerAppLocalReturn[] = [];
      const collectStarterTemplates: MarketplacePartnerAppLocalReturn[] = [];

      marketplaceApps.forEach((marketplaceApp) => {
        if (marketplaceApp.id === NEXTROUTE_SUBSCRIPTION_ID) {
          collectFeaturedApps.push(marketplaceApp);
        } else if (
          [
            KNAPSACK_SUBSCRIPTION_ID,
            ORTOOLS_KNAPSACK_SUBSCRIPTION_ID,
            PYOMO_KNAPSACK_SUBSCRIPTION_ID,
          ].includes(marketplaceApp.id)
        ) {
          collectStarterTemplates.push(marketplaceApp);
        } else {
          collectApps.push(marketplaceApp);
        }
      });

      setFeaturedApps(collectFeaturedApps);
      setApps(collectApps);
      setStarterTemplates(collectStarterTemplates);
    }
  }, [apps]);

  // load app subscriptions into context if not already present
  // keep in context so we don't have to reload them each time
  // a marketplace app is viewed
  useEffect(() => {
    if (!appSubscriptions.length && appSubscriptionsLoading) {
      loadAppSubscriptions();
    }
  }, [appSubscriptions.length, appSubscriptionsLoading, loadAppSubscriptions]);

  return (
    <>
      <Header configPageTitle={{ label: pageTitle }} />

      <CustomAppBanner />

      <StyledGrid mt={4}>
        {featuredApps &&
          featuredApps.map((marketplaceApp) => (
            <MarketplaceAppPreview
              key={marketplaceApp.id}
              {...{ accountId }}
              marketplaceApp={marketplaceApp as MarketplacePartnerApp}
              isFeatured
            />
          ))}

        {apps &&
          apps.map((marketplaceApp) => (
            <MarketplaceAppPreview
              key={marketplaceApp.id}
              {...{ accountId }}
              marketplaceApp={marketplaceApp as MarketplacePartnerApp}
            />
          ))}
      </StyledGrid>

      <Flex mt={9} pb={2} hasBorderBottom alignItems="center">
        <Text
          as="h2"
          styleName="header-2"
          styles={{ color: theme.color.gray800 }}
        >
          Starter Templates
        </Text>
        <Tooltip ml={1}>
          These are templates that can be used as a starting point for building
          your own custom apps.
        </Tooltip>
      </Flex>

      <StyledGrid mt={4} pb={[6, 6, 8]}>
        {starterTemplates &&
          starterTemplates.map((marketplaceApp) => (
            <MarketplaceAppPreview
              key={marketplaceApp.id}
              {...{ accountId }}
              marketplaceApp={marketplaceApp as MarketplacePartnerApp}
            />
          ))}
      </StyledGrid>

      {features?.newuserq && isNewUser && (
        <NewUserQuestions {...{ setIsNewUser }} />
      )}
    </>
  );
};

export default MarketplaceApps;
