import React, { useState } from "react";
import { Select } from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";

import { useUser } from "../../../../../AuthProvider";
import Modal2 from "../../../../../components/Modal2";
import { hasRolesRestrictions } from "../../../../../utils/authProviderHelpers";
import {
  getKnownTeamMemberRoles,
  TeamMemberRole,
  teamMemberRoleFromOrgRole,
} from "../data/roles";
import { EditTeamMemberRoleProps } from "../Members.types";
import { getModalContentEditTeamMemberRole } from "../utils/modalContent";

const EditRoleModal = ({
  exitModal,
  teamMemberEditRoleProcessing,
  editTeamMemberRole,
  teamMember,
}: EditTeamMemberRoleProps) => {
  const [user] = useUser();
  const [teamMemberRole, setTeamMemberRole] = useState<TeamMemberRole>(
    teamMemberRoleFromOrgRole(teamMember.role)
  );
  const modalContent = getModalContentEditTeamMemberRole(teamMember);

  return (
    <Modal2
      isActive={!!teamMember}
      children={
        <Select
          mt={2}
          placeholder="Select new role for this member"
          options={getKnownTeamMemberRoles(!hasRolesRestrictions(user)).filter(
            (role) => !(role.value === "root")
          )}
          value={teamMemberRole}
          onChange={(selection: TeamMemberRole) => {
            setTeamMemberRole(selection);
          }}
        />
      }
      width={rem(600)}
      title={modalContent.title}
      summary={modalContent.summary}
      closeActionOnClick={(e: any) => exitModal({ e })}
      primaryActionLabel={modalContent.primaryActionLabel}
      primaryActionOnClick={(e: any) =>
        editTeamMemberRole({ e, teamMember, teamMemberRole })
      }
      primaryIsDisabled={teamMemberEditRoleProcessing}
      primaryIsLoading={teamMemberEditRoleProcessing}
      secondaryActionLabel={modalContent.secondaryActionLabel}
      secondaryActionOnClick={(e: any) => exitModal({ e })}
    />
  );
};

export default EditRoleModal;
