import React, { useCallback, useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import {
  AvatarInputSet,
  Box,
  Input,
  Notification,
  RowDetail,
} from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";

import { trackEvent } from "../../../../analytics";
import {
  CreateInputSetPayload,
  InputSetInput,
} from "../../../../api/core/controlPlane.types";
import { API_BASE } from "../../../../api/core/core.config";
import { useUser } from "../../../../AuthProvider";
import Footer from "../../../../components/Footer";
import Header from "../../../../components/Header";
import InstanceSelect from "../../../../components/InstanceSelect";
import { useMetaTitle } from "../../../../components/Layout";
import SelectAppInput from "../../../../components/SelectAppInput";
import { AppInputOption } from "../../../../components/SelectAppInput/SelectAppInput.types";
import UploadFile from "../../../../components/UploadFile";
import useFileIntercept from "../../../../components/UploadFile/hooks/useFileIntercept";
import { getLocalDirectory } from "../../../../components/UploadFile/utils/getLocalDirectory";
import { RUN_FORMAT_JSON, RUN_FORMAT_TEXT } from "../../../../config/apps";
import {
  INPUT_SET_CREATE_TYPE_INPUTS,
  INPUT_SET_CREATE_TYPE_INSTANCE_DATE,
  INPUT_SET_CREATE_TYPE_RUN_IDS,
  INPUT_SET_CREATE_TYPE_UPLOAD_FILE,
  INPUT_SET_MAX_INPUTS,
  INPUT_SET_MAX_RUN_IDS,
} from "../../../../config/experiments";
import useRunDetails from "../../../../contexts/apps/hooks/useRunDetails";
import { useExperiments } from "../../../../contexts/experiments/Experiments.context";
import useManageEntity from "../../../../hooks/useManageEntity";
import useStandardInputs from "../../../../hooks/useStandardInputs";
import useStreamlitTokenRefresh from "../../../../hooks/useStreamlitTokenRefresh";
import {
  checkConfigDisplayRow,
  checkConfigDisplayRowProperty,
  checkConfigDisplayView,
} from "../../../../utils/pageConfigDisplay";
import {
  AppPageProps,
  ConfigDisplayRows,
  ConfigDisplayViews,
} from "../../../App/App.types";
import useReturnPaths from "../../../App/hooks/useReturnPaths";
import { isFileTypeJson } from "../../../App/subpages/RunDetails/utils/runDataChecks";
import InputSetsCreateTypeSelect from "../../components/InputSetsCreateTypeSelect";
import InputSetsDateRangeSelect from "../../components/InputSetsDateRangeSelect";
import { inputSetTooltips } from "../../data/microcopy";

import useNewInputSet from "./hooks/useNewInputSet";
import { modifyCreateInputSetPayload } from "./utils/utils";

const pageTitle = "New input set";

type FileUploadMatch = {
  name: string;
  uploadId: string;
};

const NewInputSet = ({
  app,
  configDisplay,
  setInputSetInputsCount,
  setParentError,
  setSignalHasSubmitted,
  setSignalIsInUse,
  signalHasSubmitted,
  signalShouldSubmit,
  signalIsInUse,
  wrapper = (children: React.ReactNode) => <form>{children}</form>,
}: AppPageProps) => {
  const [, setMetaTitle] = useMetaTitle();
  const [{ features, id: accountId }] = useUser();
  const location = useLocation();
  const { pathname } = location;
  const { returnPath, returnPathList } = useReturnPaths();
  const localDirectory = getLocalDirectory(pathname);
  const encodedApiUrl = encodeURIComponent(API_BASE);

  const searchParams = new URLSearchParams(location.search);
  const custom_create = searchParams.get("custom_create");

  // manage new input set
  const [isProcessing, setIsProcessing] = useState(false);
  const [referenceAppInputOptions, setReferenceAppInputOptions] = useState<
    AppInputOption[] | null
  >(null);
  const [uploadedFilesManifest, setUploadedFilesManifest] = useState<
    FileUploadMatch[]
  >([]);
  const [uploadedFilesUniqueIds, setUploadedFilesUniqueIds] = useState<
    string[]
  >([]);

  const token = useStreamlitTokenRefresh({
    streamlitSubdomain: custom_create,
  });

  // manage view config
  const [displayConfigRows, setDisplayConfigRows] =
    useState<ConfigDisplayRows | null>(null);
  const [displayConfigViews, setDisplayConfigViews] =
    useState<ConfigDisplayViews | null>(null);

  const { loadInputSets } = useExperiments();
  const { createTemporaryRunUploadMetadata } = useRunDetails();
  const { addEntity: addAppInput, entityAddError: appInputAddError } =
    useManageEntity("inputs");

  const {
    addEntity: addInputSet,
    isEntityAdded: isInputSetAdded,
    entityAddError: inputSetAddError,
  } = useManageEntity("experiments/inputsets");

  const {
    acceptedFiles,
    createTypeSelected,
    dateRangeSelected,
    handleCreateTypeClick,
    handleDateSelect,
    handleInputSetRunIdsChange,
    pendingInputSetCriteria,
    pendingInputSetInputIds,
    pendingInputSetInstanceIds,
    runIdsString,
    setAcceptedFiles,
    setCreateTypeSelected,
    setPendingInputSetInputIds,
    setPendingInputSetInstanceIds,
  } = useNewInputSet(app);

  const {
    getStandardInputsProps,
    pendingStandardInputs,
    standardInputsErrors,
    updateStandardInputs,
  } = useStandardInputs(app, "experiments/inputsets");

  const {
    clearWebWorkersAndLocalOPFS,
    isFileUploadProcessRunning,
    isPrecheckRunning,
    fileInterceptError,
    fileUploadMetadata,
    setIsFileUploadProcessRunning,
    setIsPrecheckRunning,
    uploadFilesViaWebWorker,
  } = useFileIntercept({
    app,
  });

  const handleInputSetCreate = useCallback(async () => {
    setIsProcessing(true);

    if (appInputAddError || fileInterceptError || inputSetAddError) {
      return;
    }

    let payload: CreateInputSetPayload = {
      ...pendingStandardInputs,
      ...pendingInputSetCriteria,
    };

    if (createTypeSelected.value === INPUT_SET_CREATE_TYPE_INPUTS) {
      const filteredPendingInputSetInputIds =
        pendingInputSetInputIds.filter(Boolean);

      if (
        filteredPendingInputSetInputIds.length &&
        referenceAppInputOptions?.length
      ) {
        payload["inputs"] = filteredPendingInputSetInputIds.reduce(
          (pendingInputSetInputs, pendingInputSetInputId) => {
            const selectedAppInput = referenceAppInputOptions.find(
              (appInputOption) => appInputOption.id === pendingInputSetInputId
            );
            if (selectedAppInput) {
              pendingInputSetInputs.push(selectedAppInput);
            }
            return pendingInputSetInputs;
          },
          [] as InputSetInput[]
        );
      }
    }

    if (createTypeSelected.value === INPUT_SET_CREATE_TYPE_INSTANCE_DATE) {
      const filteredPendingInputSetInstanceIds =
        pendingInputSetInstanceIds.filter(Boolean);
      if (filteredPendingInputSetInstanceIds.length) {
        payload["instance_id"] = filteredPendingInputSetInstanceIds[0];
      }
    }

    if (createTypeSelected.value === INPUT_SET_CREATE_TYPE_UPLOAD_FILE) {
      let acceptedAppInputUploads: InputSetInput[] = [];

      if (!acceptedFiles || !acceptedFiles.length) {
        setIsProcessing(false);
        return;
      }

      for (const acceptedFile of acceptedFiles) {
        // get upload ID from manifest
        const uploadId = uploadedFilesManifest.find(
          (uploadedFile) => uploadedFile.name === acceptedFile.name
        )?.uploadId;

        const newInput = await addAppInput(
          app.id,
          {
            name: acceptedFile.name,
            upload_id: uploadId,
            format: {
              input: {
                type: isFileTypeJson(acceptedFile.type)
                  ? RUN_FORMAT_JSON
                  : RUN_FORMAT_TEXT,
              },
            },
          },
          true
        );

        if (newInput) {
          acceptedAppInputUploads.push({
            id: newInput.id,
            name: newInput.name,
          });
        } else {
          // assume error if empty newInput, the for loop is too fast
          // to recognize the errors coming back from the return
          setIsProcessing(false);
          break;
        }
      }

      payload["inputs"] = acceptedAppInputUploads;
    }

    // final payload must be modified to fit the selected create type
    // if you submit both run_ids and instance_id and *_time the
    // backend will return an error
    payload = modifyCreateInputSetPayload(payload, createTypeSelected);

    await addInputSet(app.id, payload);
  }, [
    acceptedFiles,
    addAppInput,
    addInputSet,
    app.id,
    appInputAddError,
    createTypeSelected,
    fileInterceptError,
    inputSetAddError,
    pendingInputSetCriteria,
    pendingInputSetInputIds,
    pendingInputSetInstanceIds,
    pendingStandardInputs,
    referenceAppInputOptions,
    uploadedFilesManifest,
  ]);

  // page display
  useEffect(() => {
    if (checkConfigDisplayView("html-title", displayConfigViews)) {
      setMetaTitle(pageTitle);
    }
  }, [displayConfigViews, setMetaTitle]);

  // manage display config
  useEffect(() => {
    if (configDisplay && configDisplay.views && !displayConfigViews) {
      setDisplayConfigViews(configDisplay.views);
    }
    if (configDisplay && configDisplay.rows && !displayConfigRows) {
      setDisplayConfigRows(configDisplay.rows);
    }
    if (configDisplay && configDisplay.presets && !pendingStandardInputs.id) {
      const configDisplayPresets = configDisplay.presets;
      Object.keys(configDisplayPresets).forEach((presetKey) => {
        switch (presetKey) {
          // name, description go with id all at once
          case "id":
            if (!configDisplayPresets.id) return;
            updateStandardInputs([
              { key: "name", value: configDisplayPresets["name"] },
              { key: "id", value: configDisplayPresets["id"] },
              {
                key: "description",
                value: configDisplayPresets["description"],
              },
            ]);
            return;
          case "createType":
            setCreateTypeSelected(configDisplayPresets["createType"]);
            return;
        }
      });
    }
  }, [
    configDisplay,
    displayConfigRows,
    displayConfigViews,
    pendingStandardInputs.id,
    setCreateTypeSelected,
    updateStandardInputs,
  ]);

  // turn off loading state if error
  useEffect(() => {
    if (
      (appInputAddError || inputSetAddError || fileInterceptError) &&
      isProcessing
    ) {
      setIsProcessing(false);
    }
  }, [appInputAddError, fileInterceptError, inputSetAddError, isProcessing]);

  // collect all the unique upload IDs from fileUploadMetadata returned
  // from web worker until the length of the array matches the manifest
  // otherwise the main thread will not wait for the async file upload
  // process that is happening in the web worker
  useEffect(() => {
    if (fileUploadMetadata) {
      if (
        uploadedFilesUniqueIds.find((id) => id === fileUploadMetadata.uploadId)
      ) {
        return;
      }
      setUploadedFilesUniqueIds((prevState) => {
        return [...prevState, fileUploadMetadata.uploadId];
      });
    }
  }, [fileUploadMetadata, uploadedFilesUniqueIds]);

  // end file upload processing when all upload IDs have been collected
  useEffect(() => {
    if (
      isFileUploadProcessRunning &&
      acceptedFiles?.length === uploadedFilesManifest.length &&
      acceptedFiles?.length === uploadedFilesUniqueIds.length
    ) {
      setIsFileUploadProcessRunning(false);
    }
  }, [
    acceptedFiles?.length,
    isFileUploadProcessRunning,
    setIsFileUploadProcessRunning,
    uploadedFilesManifest.length,
    uploadedFilesUniqueIds.length,
  ]);

  const startFileUploadProcess = useCallback(async () => {
    setIsFileUploadProcessRunning(true);

    if (acceptedFiles) {
      for (const acceptedFile of acceptedFiles) {
        // create upload ID & URL to pass to web worker
        const fileUploadMetadata = await createTemporaryRunUploadMetadata(
          app.id
        );

        if (
          fileUploadMetadata.upload_url &&
          fileUploadMetadata.upload_id &&
          acceptedFile
        ) {
          // upload single file via web worker
          await uploadFilesViaWebWorker(
            [acceptedFile],
            fileUploadMetadata,
            localDirectory
          );

          setUploadedFilesManifest((prevState) => {
            const newUpload = {
              name: acceptedFile.name,
              uploadId: fileUploadMetadata.upload_id,
            };

            return [...prevState, newUpload];
          });
        }
      }
    }

    return;
  }, [
    acceptedFiles,
    app.id,
    createTemporaryRunUploadMetadata,
    localDirectory,
    setIsFileUploadProcessRunning,
    uploadFilesViaWebWorker,
  ]);

  // handle pre-create file uploads process
  useEffect(() => {
    if (
      isPrecheckRunning &&
      acceptedFiles?.length &&
      !isFileUploadProcessRunning
    ) {
      if (
        acceptedFiles.length !== uploadedFilesManifest.length &&
        acceptedFiles.length !== uploadedFilesUniqueIds.length
      ) {
        startFileUploadProcess();
        return;
      }
      if (
        acceptedFiles.length === uploadedFilesManifest.length &&
        acceptedFiles.length === uploadedFilesUniqueIds.length
      ) {
        setIsPrecheckRunning(false);
        handleInputSetCreate();
        return;
      }
    }
  }, [
    acceptedFiles,
    handleInputSetCreate,
    isFileUploadProcessRunning,
    isPrecheckRunning,
    setIsPrecheckRunning,
    startFileUploadProcess,
    uploadedFilesManifest.length,
    uploadedFilesUniqueIds.length,
  ]);

  // clear OPFS data and close web workers on exit
  useEffect(() => {
    return () => {
      clearWebWorkersAndLocalOPFS(localDirectory);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePrecheck = useCallback(async () => {
    setIsProcessing(true);

    if (createTypeSelected.value === INPUT_SET_CREATE_TYPE_UPLOAD_FILE) {
      setIsPrecheckRunning(true);
    } else {
      handleInputSetCreate();
    }

    return;
  }, [createTypeSelected.value, handleInputSetCreate, setIsPrecheckRunning]);

  // begin create process if signaled from parent component
  useEffect(() => {
    if (signalShouldSubmit && !isProcessing) {
      handlePrecheck();
    }
  }, [handlePrecheck, isProcessing, signalShouldSubmit]);

  // signal to parent component to use this component process
  useEffect(() => {
    if (acceptedFiles?.length && !signalIsInUse && setSignalIsInUse) {
      setSignalIsInUse(true);
    }
    if (!acceptedFiles?.length && signalIsInUse && setSignalIsInUse) {
      setSignalIsInUse(false);
    }
  }, [acceptedFiles?.length, setSignalIsInUse, signalIsInUse]);

  // send create error to parent component
  useEffect(() => {
    if (inputSetAddError && setParentError) {
      setParentError(inputSetAddError);
    }
  }, [inputSetAddError, setParentError]);

  // update input count and send to parent component
  useEffect(() => {
    if (setInputSetInputsCount && acceptedFiles?.length) {
      setInputSetInputsCount(acceptedFiles?.length || 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles?.length]);

  const handleCancel = () => {
    trackEvent("InputSets", {
      view: "Create Input Set",
      action: "Create Input Set Canceled",
    });
    return;
  };

  const trackAndLoadInputsSets = () => {
    trackEvent("InputSets", {
      view: "Create Input Set",
      action: "New Input Set Created",
    });

    loadInputSets(app.id);
    return;
  };

  if (isInputSetAdded) {
    if (signalIsInUse && setSignalHasSubmitted && !signalHasSubmitted) {
      trackAndLoadInputsSets();
      setSignalHasSubmitted(true);
    }
    if (!signalIsInUse) {
      trackAndLoadInputsSets();
      return <Redirect to={returnPathList} />;
    }
  }

  const isActionButtonDisabled =
    !pendingStandardInputs.name ||
    !pendingStandardInputs.id ||
    (createTypeSelected.value === INPUT_SET_CREATE_TYPE_UPLOAD_FILE &&
      !acceptedFiles?.length) ||
    (createTypeSelected.value === INPUT_SET_CREATE_TYPE_INPUTS &&
      !pendingInputSetInputIds.filter(Boolean).length) ||
    (createTypeSelected.value === INPUT_SET_CREATE_TYPE_INSTANCE_DATE &&
      !pendingInputSetInstanceIds.filter(Boolean).length) ||
    (createTypeSelected.value === INPUT_SET_CREATE_TYPE_RUN_IDS &&
      !pendingInputSetCriteria.run_ids.length) ||
    !!standardInputsErrors.name ||
    !!standardInputsErrors.id ||
    isFileUploadProcessRunning ||
    isProcessing;

  return (
    <>
      {checkConfigDisplayView("header", displayConfigViews) && (
        <Header
          configPageTitle={{
            label: pageTitle,
            ancestorIcon: <AvatarInputSet size={24} />,
            ancestorLabel: "Input Sets",
            ancestorUrl: returnPathList,
          }}
        />
      )}

      {checkConfigDisplayView("main", displayConfigViews) && (
        <>
          {(features || {}).streamlit && custom_create ? (
            <>
              {token && (
                <iframe
                  title="Custom input set creation"
                  src={`https://${custom_create}.streamlit.app//?app_id=${app.id}&url=${encodedApiUrl}&token=${token}&account=${accountId}&embedded=true`}
                  style={{
                    width: "100%",
                    height: "calc(100vh - 64px)",
                    border: "none",
                  }}
                />
              )}
            </>
          ) : (
            wrapper(
              <>
                {checkConfigDisplayRow("name", displayConfigRows) && (
                  <RowDetail
                    hasNoBorder
                    property="Name"
                    secondaryLabel="For reference only"
                    render={
                      <Box width="100%" maxWidth={rem(408)}>
                        <Input
                          {...getStandardInputsProps({
                            placeholder: "Input set name",
                            testId: "new-input-set-name-input",
                            type: "name",
                            trackEventCategory: "InputSets",
                            trackEventProperties: {
                              view: "Create Input Set",
                              action: "Field Entered",
                              meta: {
                                field: "name",
                              },
                            },
                          })}
                        />
                      </Box>
                    }
                  />
                )}

                {checkConfigDisplayRow("id", displayConfigRows) && (
                  <RowDetail
                    property="ID"
                    tooltipCopy={inputSetTooltips.idCreateView.content}
                    render={
                      <Box width="100%" maxWidth={rem(408)}>
                        <Input
                          {...getStandardInputsProps({
                            placeholder: "Input set ID",
                            testId: "new-input-set-id-input",
                            type: "id",
                            trackEventCategory: "InputSets",
                            trackEventProperties: {
                              view: "Create Input Set",
                              action: "Input Set ID Changed",
                            },
                          })}
                        />
                      </Box>
                    }
                  />
                )}

                {checkConfigDisplayRow("description", displayConfigRows) && (
                  <RowDetail
                    property="Description"
                    secondaryLabel="(optional)"
                    render={
                      <Box width="100%" maxWidth={rem(408)}>
                        <Input
                          {...getStandardInputsProps({
                            placeholder: "Input set description",
                            testId: "new-input-set-description-input",
                            type: "description",
                            trackEventCategory: "InputSets",
                            trackEventProperties: {
                              view: "Create Input Set",
                              action: "Field Entered",
                              meta: {
                                field: "description",
                              },
                            },
                          })}
                        />
                      </Box>
                    }
                  />
                )}

                {checkConfigDisplayRow("create-type", displayConfigRows) && (
                  <InputSetsCreateTypeSelect
                    pendingCreateType={createTypeSelected}
                    onAdd={handleCreateTypeClick}
                  />
                )}

                {createTypeSelected.value === INPUT_SET_CREATE_TYPE_INPUTS &&
                  checkConfigDisplayRow("inputs", displayConfigRows) && (
                    <RowDetail
                      property="Inputs"
                      secondaryLabel={`Select up to ${INPUT_SET_MAX_INPUTS} inputs to assign to this input set`}
                      tooltipCopy={inputSetTooltips.createViewInputs.content}
                      render={
                        <SelectAppInput
                          {...{ app, setReferenceAppInputOptions }}
                          testId="new-input-set-inputs-select"
                          maxLimit={INPUT_SET_MAX_INPUTS}
                          pendingInputIds={pendingInputSetInputIds}
                          setPendingInputIds={setPendingInputSetInputIds}
                        />
                      }
                    />
                  )}

                {createTypeSelected.value ===
                  INPUT_SET_CREATE_TYPE_INSTANCE_DATE && (
                  <>
                    {checkConfigDisplayRow(
                      "instance-select",
                      displayConfigRows
                    ) && (
                      <RowDetail
                        property="Instance"
                        secondaryLabel="Select runs from this instance"
                        tooltipCopy={
                          inputSetTooltips.createViewInstanceDate.content
                        }
                        render={
                          <InstanceSelect
                            {...{ app }}
                            isSingleSelect
                            testId="new-input-set-instance-select"
                            pendingInstanceIds={pendingInputSetInstanceIds}
                            setPendingInstanceIds={
                              setPendingInputSetInstanceIds
                            }
                          />
                        }
                      />
                    )}
                    {checkConfigDisplayRow(
                      "instance-date-range",
                      displayConfigRows
                    ) && (
                      <InputSetsDateRangeSelect
                        pendingDateRange={dateRangeSelected}
                        onAdd={handleDateSelect}
                      />
                    )}
                  </>
                )}

                {createTypeSelected.value === INPUT_SET_CREATE_TYPE_RUN_IDS &&
                  checkConfigDisplayRow("run-ids", displayConfigRows) && (
                    <RowDetail
                      property="Run IDs"
                      secondaryLabel={`Enter each run ID as a new line (max ${INPUT_SET_MAX_RUN_IDS})`}
                      tooltipCopy={inputSetTooltips.createViewRunIds.content}
                      render={
                        <Box width="100%" maxWidth={rem(408)}>
                          <Input
                            name="run_ids"
                            data-testid="new-input-set-run-ids-textarea"
                            htmlType="text"
                            placeholder="Enter run IDs"
                            value={runIdsString}
                            onChange={handleInputSetRunIdsChange}
                            isTextArea
                          />
                        </Box>
                      }
                    />
                  )}

                {createTypeSelected.value ===
                  INPUT_SET_CREATE_TYPE_UPLOAD_FILE &&
                  checkConfigDisplayRow("upload-files", displayConfigRows) && (
                    <RowDetail
                      {...(checkConfigDisplayRowProperty(
                        "upload-files",
                        "property",
                        displayConfigRows
                      ) && { property: "Upload files" })}
                      {...(checkConfigDisplayRowProperty(
                        "upload-files",
                        "secondaryLabel",
                        displayConfigRows
                      ) && {
                        secondaryLabel: `Upload up to ${INPUT_SET_MAX_INPUTS} files`,
                      })}
                      {...(checkConfigDisplayRowProperty(
                        "upload-files",
                        "tooltipCopy",
                        displayConfigRows
                      ) && {
                        tooltipCopy:
                          inputSetTooltips.createViewUploadFiles.content,
                      })}
                      {...(checkConfigDisplayRowProperty(
                        "upload-files",
                        "hasNoBorder",
                        displayConfigRows
                      ) && { hasNoBorder: configDisplay ? true : false })}
                      {...(checkConfigDisplayRowProperty(
                        "upload-files",
                        "render",
                        displayConfigRows
                      ) && {
                        render: (
                          <Box width="100%" maxWidth={rem(408)}>
                            <UploadFile
                              {...{ acceptedFiles, app, setAcceptedFiles }}
                              isMultiple
                              isMultipleTypeAllowed
                              maxFiles={INPUT_SET_MAX_INPUTS}
                            />

                            {(appInputAddError || fileInterceptError) && (
                              <Notification
                                mt={3}
                                type="error"
                                message={appInputAddError || fileInterceptError}
                              />
                            )}
                          </Box>
                        ),
                      })}
                    />
                  )}

                {checkConfigDisplayView("footer", displayConfigViews) && (
                  <Footer
                    actionButtonLabel="Create input set"
                    app={app}
                    endpoint="experiments/inputsets"
                    error={inputSetAddError}
                    handleCancel={handleCancel}
                    handleMainAction={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      handlePrecheck();
                      return;
                    }}
                    isActionButtonLoading={isProcessing}
                    isActionButtonDisabled={isActionButtonDisabled}
                    returnPath={returnPath}
                    returnPathList={returnPathList}
                    view="create"
                  />
                )}
              </>
            )
          )}
        </>
      )}
    </>
  );
};

export default NewInputSet;
