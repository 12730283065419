import React, { useEffect, useState } from "react";
import { DateRange, DayPicker } from "react-day-picker";
import { Box } from "@console/dsc";
import { reformatTime } from "@console/dsc/src/lib/format";
import { DateTime } from "luxon";

import Bin from "../Bin";
import { handleClickOutside } from "../Modal2/utils/modalDisplay";

import CustomCaptionRange from "./components/CustomCaptionRange";
import CustomCaptionSingle from "./components/CustomCaptionSingle";
import DatePickerFooter from "./components/DatePickerFooter";
import {
  DATEPICKER_DEFAULT_TIME_FROM,
  DATEPICKER_DEFAULT_TIME_TO,
} from "./utils/constants";
import { StyledDatePicker, StyledDatePickerCanvas } from "./DatePicker.styled";
import { DatePickerProps } from "./DatePicker.types";

import "react-day-picker/dist/style.css";

const DatePicker = ({
  clearAction,
  closeAction,
  htmlId,
  isActive,
  isFutureDisabled,
  isRange,
  offsetX,
  offsetY,
  orientation = "top",
  primaryAction,
  selectedDay,
  setDateStart,
  setDateEnd,
  testId,
}: DatePickerProps) => {
  const [range, setRange] = useState<DateRange | undefined>();
  const [timeFrom, setTimeFrom] = useState(DATEPICKER_DEFAULT_TIME_FROM);
  const [timeTo, setTimeTo] = useState(DATEPICKER_DEFAULT_TIME_TO);

  // using range to manage both range and single day
  if (!isRange && selectedDay && !range?.from) {
    setRange({ from: DateTime.fromISO(selectedDay).toJSDate() });
  }

  const defaultMonth = DateTime.now()
    .minus({ months: isRange ? 1 : 0 })
    .toJSDate();
  const disableFutureDays = {
    from: DateTime.now().plus({ days: 1 }).toJSDate(),
    to: DateTime.now().plus({ years: 10 }).toJSDate(),
  };

  // close DatePicker on escape key
  const handleKeyDown = (e: KeyboardEvent) => {
    if (isActive && e && e.key === "Escape") {
      closeAction(e);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  });

  useEffect(() => {
    if (range && range.from) {
      const timeFromArray = timeFrom.split(":");
      const timeFromHour = parseInt(timeFromArray[0]);
      const timeFromMinute = parseInt(timeFromArray[1]);

      setDateStart &&
        setDateStart(
          reformatTime({
            time: DateTime.fromJSDate(range.from)
              .set({ hour: timeFromHour, minute: timeFromMinute })
              .toISO(),
            inputDateType: "iso",
            outputDateFormat: "rfc3339",
          })
        );
    }
    if (range && range.to) {
      const timeToArray = timeTo.split(":");
      const timeToHour = parseInt(timeToArray[0]);
      const timeToMinute = parseInt(timeToArray[1]);

      setDateEnd &&
        setDateEnd(
          reformatTime({
            time: DateTime.fromJSDate(range.to)
              .set({ hour: timeToHour, minute: timeToMinute })
              .toISO(),
            inputDateType: "iso",
            outputDateFormat: "rfc3339",
          })
        );
    }
  }, [range, setDateEnd, setDateStart, timeFrom, timeTo]);

  const handleDateSelection = (selected?: DateRange | Date) => {
    // if range, selected is DateRange; if single, selected is Day
    // both can be undefined depending on user action, docs:
    // https://github.com/gpbl/react-day-picker/blob/main/src/types/EventHandlers.ts#L64
    if (isRange) {
      setRange(selected as DateRange);
    } else {
      setRange({ from: selected as Date });
    }
  };

  const datePickerId = htmlId || "nextmv-date-picker";

  return (
    <>
      <StyledDatePickerCanvas
        {...{ isActive }}
        id={datePickerId}
        onClick={(e) =>
          handleClickOutside({
            closeAction: closeAction,
            isActive,
            e,
            targetId: datePickerId,
          })
        }
      />

      <StyledDatePicker {...{ isActive, offsetX, offsetY, orientation }}>
        <Bin>
          <Box m={-3}>
            <DayPicker
              {...{ defaultMonth }}
              showOutsideDays
              fixedWeeks
              numberOfMonths={isRange ? 2 : 1}
              {...(isRange
                ? {
                    mode: "range",
                    selected: range,
                    pagedNavigation: true,
                  }
                : {
                    mode: "single",
                    selected: range?.from,
                  })}
              onSelect={handleDateSelection}
              components={{
                Caption: isRange ? CustomCaptionRange : CustomCaptionSingle,
              }}
              disabled={[...(isFutureDisabled ? [disableFutureDays] : [])]}
              {...(testId && {
                "data-testid": testId,
              })}
            />

            <DatePickerFooter
              {...{
                clearAction,
                isRange,
                primaryAction,
                range,
                setRange,
                setTimeFrom,
                setTimeTo,
                timeFrom,
                timeTo,
              }}
            />
          </Box>
        </Bin>
      </StyledDatePicker>
    </>
  );
};

export default DatePicker;
