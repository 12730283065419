import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Box,
  Flex,
  IllustrationBunnyCharged,
  Loading,
  Notification,
  Text,
} from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";

import { getOrgKeys } from "../../api/core/dataPlane";
import { useUser } from "../../AuthProvider";
import Layout, { useMetaTitle } from "../../components/Layout";
import useMarketplacePartners from "../../contexts/marketplace/hooks/useMarketplacePartners";
import { getAccUrl } from "../../utils/navigation";
import { userHasAccessToAction } from "../../utils/rbac_utils";
import { ActionGroups } from "../../utils/rbac_utils/types";
import AccessDenied from "../AccessDenied";
import { getMarketplaceAppTemplateName } from "../MarketplaceApp/utils/getMarketplaceAppDetails";

import CopyCommand from "./components/CopyCommand";
import InstallStep from "./components/InstallStep";
import { installStepsCommands } from "./data/installSteps";

const pageTitle = "Install CLI & Download SDK";

const InstallContent = () => {
  const [isApiKeyLoading, setIsApiKeyLoading] = useState(true);
  const [apiKey, setApiKey] = useState("");
  const [showAPIWarning, setShowAPIWarning] = useState(false);
  const [{ features, id: accountId }] = useUser();
  const isNextmvUser = features?.internal;
  const displayedApiKey = isNextmvUser ? "$NEXTMV_API_KEY" : apiKey;

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const subscriptionId: string | null = searchParams.get("subscriptionId");

  const [, setMetaTitle] = useMetaTitle();
  const theme = useTheme();

  const [isStep1Active, setIsStep1Active] = useState(true);
  const [isStep2Active, setIsStep2Active] = useState(false);
  const [isStep3Active, setIsStep3Active] = useState(false);

  const {
    loadMarketplacePartnerApp,
    marketplacePartnerApp,
    marketplacePartnerAppError,
  } = useMarketplacePartners();

  useEffect(() => {
    setMetaTitle(pageTitle);
  }, [setMetaTitle]);

  // load marketplace app (if applicable)
  useEffect(() => {
    if (
      !marketplacePartnerApp &&
      !marketplacePartnerAppError &&
      subscriptionId
    ) {
      loadMarketplacePartnerApp(subscriptionId);
    }
  }, [
    loadMarketplacePartnerApp,
    marketplacePartnerApp,
    marketplacePartnerAppError,
    subscriptionId,
  ]);

  // load API key
  useEffect(() => {
    const fetchOrgKeys = async () => {
      try {
        const apiKeys = await getOrgKeys(accountId || "");
        const activeApiKey = apiKeys.find((key) => !key.disabled);
        if (activeApiKey) {
          setApiKey(activeApiKey.api_key);
        } else {
          setShowAPIWarning(true);
        }
      } catch (error) {
        console.error("Error fetching org keys", error);
        setShowAPIWarning(true);
      }
      setIsApiKeyLoading(false);
    };

    if (!displayedApiKey && !isNextmvUser) {
      fetchOrgKeys();
    } else {
      setIsApiKeyLoading(false);
    }
  }, [accountId, displayedApiKey, isNextmvUser]);

  if (
    isApiKeyLoading ||
    (!marketplacePartnerApp && !marketplacePartnerAppError && subscriptionId)
  ) {
    return <Loading type="full-screen" dotColor={theme.color.orange500} />;
  }

  // in this case we will ignore the 404 error if a bad parameter is passed;
  // which in theory should only happen if you manually type in a query param
  const hasValidSubscriptionId =
    subscriptionId && !marketplacePartnerAppError && marketplacePartnerApp;
  const marketplaceAppName =
    hasValidSubscriptionId && marketplacePartnerApp.name;

  const renderInlineCode = (code?: string) => {
    return (
      <Text as="code" styleName="code-bold" styles={{ fontSize: rem(15) }}>
        {code}
      </Text>
    );
  };

  const modelType = hasValidSubscriptionId && marketplacePartnerApp?.modelType;
  const renderHeaderContent = () => {
    const headerTitle =
      hasValidSubscriptionId && marketplaceAppName
        ? `Get started customizing the ${marketplaceAppName} app`
        : "Install dev tools";
    const headerSubtitle =
      hasValidSubscriptionId && modelType
        ? `Follow the steps below to create your own custom ${modelType.toLowerCase()} model based on the ${marketplaceAppName} app. (Skip to step 3 if you’ve already installed Nextmv CLI.)`
        : "Follow the steps below to download, install, and configure Nextmv CLI.";

    return (
      <>
        <Text
          as="h1"
          styleName="marketing-header-3"
          styles={{
            color: theme.color.gray800,
          }}
        >
          {headerTitle}
        </Text>

        <Text
          styleName="body-1-plus"
          hasLink
          hasCodeInline
          mt={2}
          styles={{
            code: {
              fontSize: `${theme.ui2Typography.fontSizeBody1} !important`,
            },
          }}
        >
          {headerSubtitle}
        </Text>

        {showAPIWarning && (
          <Notification
            mt={6}
            type="notice"
            message={
              <Text styleName="body-2" hasLink>
                In order to install the CLI, you will need an active API key.
                Visit the{" "}
                <Link to={getAccUrl(accountId, "/api-key")}>
                  API key section
                </Link>{" "}
                of the Teams page to create a new key or activate an existing
                one.
              </Text>
            }
          />
        )}
      </>
    );
  };

  const renderStep3Title = () => {
    if (hasValidSubscriptionId && modelType) {
      return `Clone a new ${modelType.toLowerCase()} model`;
    }
    return "Clone a community app or add your existing code";
  };

  const renderGenericCommunityAppsContent = () => {
    return (
      <>
        <Text mt={[5, 5, 5, 1]} styleName="body-1">
          Nextmv CLI can be used to run models locally, create and manage apps,
          runs, experiments and more. You can also clone existing community apps
          as a starting point for your custom app.
        </Text>

        <Text mt={4} styleName="body-1" hasLink>
          To clone a specific marketplace app, visit the{" "}
          <Link to={getAccUrl(accountId, "/marketplace")}>
            Nextmv Marketplace
          </Link>{" "}
          to choose a community app and then click Customize app to get specific
          instructions for that app. Or visit the{" "}
          <a
            href="https://www.nextmv.io/docs/community-apps/get-started"
            target="_blank"
            rel="noreferrer"
          >
            Community apps section
          </a>{" "}
          in Docs.
        </Text>
      </>
    );
  };

  const renderStep3Content = () => {
    if (hasValidSubscriptionId) {
      const templateId = getMarketplaceAppTemplateName(subscriptionId);
      const marketplaceAppCodeRepoLink = marketplacePartnerApp?.codeRepoLink;

      if (!templateId) return renderGenericCommunityAppsContent();

      return (
        <>
          <Text
            mt={[5, 5, 5, 1]}
            styleName="body-1"
            hasCodeInline
            styles={{
              code: {
                fontSize: `${theme.ui2Typography.fontSizeBody2} !important`,
              },
            }}
          >
            With Nextmv CLI, initialize a new project by cloning the{" "}
            {renderInlineCode(templateId)} app:
          </Text>

          <Box mt={4}>
            <CopyCommand
              buttonAriaLabel={installStepsCommands.init.ariaLabel}
              command={installStepsCommands.init.command(templateId)}
              trackEventData={installStepsCommands.init.tracking(templateId)}
            />
          </Box>

          <Text mt={4} styleName="body-1">
            This will create a new &#8220;{templateId}&#8221; directory in your
            working directory. Go to this new directory:
          </Text>

          <Box mt={4}>
            <CopyCommand
              buttonAriaLabel={installStepsCommands.initCd.ariaLabel}
              command={installStepsCommands.initCd.command(templateId)}
              trackEventData={installStepsCommands.initCd.tracking(templateId)}
            />
          </Box>

          {marketplaceAppCodeRepoLink && (
            <Text mt={4} styleName="body-1" hasLink>
              To get started customizing and running your app locally,{" "}
              <Text
                as="a"
                styleName="body-1-bold"
                href={marketplaceAppCodeRepoLink}
                target="_blank"
                rel="noreferrer"
                isLink
              >
                view the {templateId} README
              </Text>
              .
            </Text>
          )}
        </>
      );
    }
    return renderGenericCommunityAppsContent();
  };

  return (
    <Flex
      flexGrow={1}
      flexDirection={["column", "column", "column", "row"]}
      pb={[8, 8, 8, 9]}
    >
      <Box
        maxWidth={rem(760)}
        width={1 / 1}
        flexShrink={0}
        mt={[6, 6, 8, 10]}
        order={[1, 1, 1, 2]}
      >
        <Box maxWidth={rem(600)} ml={[0, 6]}>
          {renderHeaderContent()}
        </Box>

        <Box mt={7} ml={[0, 6]} pb={8}>
          <InstallStep
            stepNumber="1"
            stepTitle="Download & install Nextmv CLI"
            content={
              <>
                <Text mt={[5, 5, 5, 1]} styleName="body-1">
                  In your terminal, execute the command below to download and
                  install Nextmv CLI. Note that your API key is included in the
                  command.
                </Text>

                <Box mt={4}>
                  <CopyCommand
                    buttonAriaLabel={installStepsCommands.download.ariaLabel}
                    command={installStepsCommands.download.command(
                      displayedApiKey
                    )}
                    trackEventData={installStepsCommands.download.tracking()}
                  />
                </Box>

                <Notification
                  width={`calc(100% - ${rem(44)})`}
                  mt={3}
                  size="small"
                  message="You may need to refresh your shell environment after installation. If so, a notification will appear."
                />
              </>
            }
            isActive={isStep1Active}
            setIsActiveCurrent={setIsStep1Active}
            setIsActiveNext={setIsStep2Active}
          />

          <InstallStep
            stepNumber="2"
            stepTitle="Configure the CLI installation with your account"
            content={
              <Box mt={4}>
                <CopyCommand
                  buttonAriaLabel={installStepsCommands.configure.ariaLabel}
                  command={installStepsCommands.configure.command(
                    displayedApiKey
                  )}
                  trackEventData={installStepsCommands.configure.tracking()}
                />
              </Box>
            }
            isActive={isStep2Active}
            setIsActiveCurrent={setIsStep2Active}
            setIsActiveNext={setIsStep3Active}
          />

          <InstallStep
            stepNumber="3"
            stepTitle={renderStep3Title()}
            content={renderStep3Content()}
            isActive={isStep3Active}
            setIsActiveCurrent={setIsStep3Active}
          />
        </Box>
      </Box>

      <Box
        maxWidth={rem(320)}
        mt={[0, 0, 0, 6]}
        ml={["auto", 4, 0, 0, 4]}
        mr={["auto", 0, 0, 6, 10]}
        order={[2, 2, 2, 1]}
      >
        <IllustrationBunnyCharged />
      </Box>
    </Flex>
  );
};

const Install = () => {
  const [{ roles }] = useUser();
  // User cannot install if they can't make API Keys
  const userCanDownloadCLI = userHasAccessToAction(
    roles,
    ActionGroups.SecurityOperator,
    {}
  );

  return (
    <Layout>
      {userCanDownloadCLI ? <InstallContent /> : <AccessDenied />}
    </Layout>
  );
};

export default Install;
