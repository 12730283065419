import React from "react";
import { Box, Flex, Tag } from "@console/dsc";
import { useTheme } from "@emotion/react";

import { trackEvent } from "../../../analytics";
import { MarketplaceAppPreviewViewSource } from "../../../analytics/types/marketplace.types";
import { MarketplacePartnerApp } from "../../../api/core/controlPlane.types";
import PreviewItem from "../../../components/PreviewItem";
import { StyledPreviewItemFlex } from "../../../components/PreviewItem/PreviewItem.styled";
import { getAccUrl } from "../../../utils/navigation";
import { StyledFeaturedAppImage } from "../Marketplace.styled";

type MarketplaceAppPreviewProps = {
  marketplaceApp: MarketplacePartnerApp;
  accountId?: string | null;
  isFeatured?: boolean;
  viewSource?: MarketplaceAppPreviewViewSource;
};

const MarketplaceAppPreview = ({
  accountId,
  marketplaceApp,
  isFeatured,
  viewSource,
}: MarketplaceAppPreviewProps) => {
  const theme = useTheme();

  if (!marketplaceApp || marketplaceApp?.isHidden) return <></>;

  return (
    <StyledPreviewItemFlex
      {...(isFeatured && {
        className: "featured-app",
      })}
    >
      <PreviewItem
        {...{ isFeatured }}
        {...(marketplaceApp?.url && {
          url: getAccUrl(accountId, marketplaceApp.url),
        })}
        {...(marketplaceApp?.icon &&
          !isFeatured && {
            avatar: (
              <img
                width="32"
                src={marketplaceApp.icon.url}
                alt={marketplaceApp.icon.alt}
              />
            ),
          })}
        name={marketplaceApp.name}
        description={marketplaceApp.description}
        {...(marketplaceApp?.tags &&
          marketplaceApp.tags.length && {
            renderExtra: (
              <Flex mt={4}>
                {marketplaceApp.tags.map((tag, index) => (
                  <Tag
                    key={`${tag}-${index}`}
                    mr={1}
                    label={tag}
                    textColor={
                      isFeatured ? theme.color.yellow800 : theme.color.gray700
                    }
                    backgroundColor={
                      isFeatured ? theme.color.yellow200 : theme.color.gray200
                    }
                  />
                ))}
              </Flex>
            ),
          })}
        {...(isFeatured &&
          marketplaceApp?.featuredImageUrl &&
          marketplaceApp?.featuredImageAlt && {
            renderExtraTop: (
              <Box pr={4} pl={4}>
                <StyledFeaturedAppImage
                  src={marketplaceApp.featuredImageUrl}
                  alt={marketplaceApp.featuredImageAlt}
                />
              </Box>
            ),
          })}
        onClick={() =>
          trackEvent("Marketplace", {
            view: "Marketplace Apps",
            action: "Marketplace App Clicked",
            meta: {
              id: marketplaceApp.id,
              source: viewSource || "marketplace",
            },
          })
        }
      />
    </StyledPreviewItemFlex>
  );
};

export default MarketplaceAppPreview;
