import { Link } from "react-router-dom";
import { Flex } from "@console/dsc";
import { styleHideVisually } from "@console/dsc/src/lib/constants";
import { rem } from "@console/dsc/src/lib/tools";
import styled from "@emotion/styled";

import {
  HEADER_HEIGHT,
  HEADER_HEIGHT_MD_NAV_EXTRA,
  MAIN_NAV_SIDEBAR_WIDTH,
} from "../../utils/constants";

import { StyledHeaderProps, StyledPageSwitcherListProps } from "./Header.types";

export const StyledHeader = styled(Flex)<StyledHeaderProps>`
  width: 100%;
  background-color: ${({ theme }) => theme.color.white};
  box-shadow: 0 ${rem(1)} 0 0 ${({ theme }) => theme.color.gray200T};
  z-index: 9999;

  @media (min-width: ${({ theme }) => theme.breakpoint.sm}) {
    width: 100%;
  }

  ${(props) =>
    props.isExpanded &&
    `
      @media (min-width: ${props.theme.breakpoint.md}) {
        width: calc(100% - ${MAIN_NAV_SIDEBAR_WIDTH});
        height: ${
          props.hasNavExtra ? HEADER_HEIGHT_MD_NAV_EXTRA : HEADER_HEIGHT
        };
        position: fixed;
        left: ${MAIN_NAV_SIDEBAR_WIDTH};
      }
      @media (min-width: ${props.theme.breakpoint.rg}) {
        height: ${HEADER_HEIGHT};
      }
    `}
`;

export const StyledPageSwitcherContainer = styled.div`
  box-sizing: border-box;
  display: block;
  position: relative;
  width: ${rem(28)};
  height: ${rem(28)};
  margin-right: ${rem(2)};
  margin-left: ${({ theme }) => theme.spacing["-s1"]};
  padding-top: ${({ theme }) => theme.spacing.s1};
  padding-left: ${({ theme }) => theme.spacing.s1};
  cursor: pointer;
  border-radius: ${({ theme }) => theme.border.radius};

  &:hover,
  &:focus,
  &:focus-visible {
    background-color: ${({ theme }) => theme.color.gray100};

    svg {
      fill: ${({ theme }) => theme.color.gray700};
    }
  }

  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 ${({ theme }) => theme.border.widthMediumShadowOnly}
      ${({ theme }) => theme.color.gray400};
  }
`;

export const StyledPageSwitcherList = styled.ul<StyledPageSwitcherListProps>`
  box-sizing: border-box;
  width: ${rem(280)};
  padding-top: ${({ theme }) => theme.spacing.s1};
  padding-right: ${({ theme }) => theme.spacing.s3};
  padding-bottom: ${({ theme }) => theme.spacing.s3};
  padding-left: ${({ theme }) => theme.spacing.s3};
  position: absolute;
  left: ${({ theme }) => theme.spacing.s7};
  top: ${({ theme }) => theme.spacing["-s2"]};
  border-radius: ${({ theme }) => theme.border.radius};
  background-color: ${({ theme }) => theme.color.white};
  box-shadow: 0 0 0 ${({ theme }) => theme.border.widthMediumShadowOnly}
    ${({ theme }) => theme.color.gray200};

  ${(props) => !props.isActive && styleHideVisually}
`;

export const StyledPageSwitcherItem = styled.li`
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing.s1};
`;

export const StyledPageSwitcherLink = styled(Link)`
  display: block;
  padding-top: ${({ theme }) => theme.spacing.s1};
  padding-right: ${({ theme }) => theme.spacing.s2};
  padding-bottom: ${({ theme }) => theme.spacing.s1};
  padding-left: ${({ theme }) => theme.spacing.s2};
  font-family: ${({ theme }) => theme.ui2Typography.fontFamilyBody1Bold};
  font-size: ${({ theme }) => theme.ui2Typography.fontSizeBody1Bold};
  font-style: ${({ theme }) => theme.ui2Typography.fontStyleBody1Bold};
  font-weight: ${({ theme }) => theme.ui2Typography.fontWeightBody1Bold};
  line-height: ${({ theme }) => theme.ui2Typography.lineHeightBody1Bold};
  letter-spacing: ${({ theme }) => theme.ui2Typography.letterSpacingBody1Bold};
  text-transform: ${({ theme }) => theme.ui2Typography.textTransformBody1Bold};
  text-decoration: none;
  color: ${({ theme }) => theme.color.gray700};
  border-radius: ${({ theme }) => theme.border.radiusSmall};

  &:hover,
  &:focus,
  &:active {
    color: ${({ theme }) => theme.color.gray800};
    background-color: ${({ theme }) => theme.color.gray100};
  }
  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 ${({ theme }) => theme.border.widthMediumShadowOnly}
      ${({ theme }) => theme.color.gray400};
  }
`;
