import React from "react";
import { Box, Details, Flex, Tabs, Text, Tooltip } from "@console/dsc";
import { TabLink } from "@console/dsc/src/components/Tabs/Tabs.types";
import { TooltipDirection } from "@console/dsc/src/components/Tooltip/Tooltip.types";
import { CSSStylesProps } from "@console/dsc/src/lib/renderExtraStyles";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";

type ControlPanelProps = {
  children: React.ReactNode;
  hasCustomContentWrapper?: boolean;
  hasNoBorder?: boolean;
  headerExtra?: string;
  headerTitle?: string;
  headerTooltipContent?: string;
  headerTooltipDirection?: TooltipDirection;
  headerTooltipExtraLinkLabel?: string;
  headerTooltipExtraLinkUrl?: string;
  onClick?: (e?: any) => void;
  isExpanded?: boolean;
  isOpen?: boolean;
  stylesDetails?: CSSStylesProps;
  summaryNode?: React.ReactNode;
  tabs?: TabLink[];
  testId?: string;
  useNonNativeElements?: boolean;
};

const ControlPanel = ({
  children,
  hasNoBorder,
  hasCustomContentWrapper,
  headerExtra,
  headerTitle,
  headerTooltipContent,
  headerTooltipDirection,
  headerTooltipExtraLinkLabel,
  headerTooltipExtraLinkUrl,
  isExpanded,
  isOpen,
  onClick,
  stylesDetails,
  summaryNode,
  tabs,
  testId,
  useNonNativeElements,
}: ControlPanelProps) => {
  const theme = useTheme();

  const renderTabs = () => {
    if (!tabs) return null;
    return <Tabs ml={6} size="small" type="panel" tabs={tabs} />;
  };

  return (
    <Details
      {...{ onClick, testId, useNonNativeElements }}
      controlPosition="left"
      size="large"
      hasNoChildrenWrapper
      isOpen={isOpen}
      summary={
        summaryNode || (
          <Flex mr={1} ml={1} alignItems="center">
            <Text
              as="h3"
              styleName="body-2-bold"
              styles={{ color: theme.color.gray800 }}
            >
              {headerTitle}
              {headerExtra && (
                <Text
                  as="span"
                  ml={1}
                  styleName="body-3"
                  styles={{ color: theme.color.gray600 }}
                >
                  {headerExtra}
                </Text>
              )}
            </Text>

            {headerTooltipContent && (
              <Tooltip
                ml={1}
                direction={headerTooltipDirection}
                extraLinkLabel={headerTooltipExtraLinkLabel}
                extraLinkUrl={headerTooltipExtraLinkUrl}
              >
                {headerTooltipContent}
              </Tooltip>
            )}

            {tabs && renderTabs()}
          </Flex>
        )
      }
      styles={{
        paddingRight: theme.spacing.s3,
        paddingLeft: theme.spacing.s3,
        borderTopWidth: hasNoBorder ? 0 : theme.border.width,
        borderTopStyle: theme.border.style,
        borderTopColor: theme.color.gray200,
        ".summary::before, .summary .details-control-icon": {
          width: rem(28),
          height: rem(28),
          borderRadius: theme.border.radius,
          backgroundColor: theme.color.gray100,
          backgroundSize: theme.spacing.s5,
        },
        ".summary:hover::before, .summary:focus::before, .summary:active::before, .summary:hover .details-control-icon, .summary:focus .details-control-icon, .summary:active .details-control-icon":
          {
            backgroundColor: theme.color.gray200,
          },
        ...(isExpanded && {
          "&[open]": {
            flexGrow: 1,
          },
        }),
        ...stylesDetails,
      }}
    >
      {hasCustomContentWrapper ? (
        children
      ) : (
        <Box mt={2} mr={2} ml={rem(36)}>
          {children}
        </Box>
      )}
    </Details>
  );
};

export default ControlPanel;
