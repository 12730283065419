import { Flex, Text } from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";

export const renderEnsembleRuleDatum = (
  label: string,
  value: string,
  isCodeStyle?: boolean
) => {
  return (
    <Flex alignItems="center" mt={3} pt={3} hasBorderTop>
      <Text styleName="body-2" styles={{ width: rem(100), shrink: 0 }}>
        {label}
      </Text>
      <Text styleName={isCodeStyle ? "code" : "body-2"} ml={2}>
        {value}
      </Text>
    </Flex>
  );
};
