import React, { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import {
  AvatarExperimentShadow,
  Box,
  Input,
  Loading,
  RowDetail,
} from "@console/dsc";
import { useTheme } from "@emotion/react";

import { trackEvent } from "../../../../analytics";
import Footer from "../../../../components/Footer";
import Header from "../../../../components/Header";
import { useMetaTitle } from "../../../../components/Layout";
import StandardError from "../../../../components/StandardError";
import { INPUT_WIDTH_STANDARD } from "../../../../config/general";
import { useExperiments } from "../../../../contexts/experiments/Experiments.context";
import useManageEntity from "../../../../hooks/useManageEntity";
import useStandardInputs from "../../../../hooks/useStandardInputs";
import { AppPageProps } from "../../../App/App.types";
import useReturnPaths from "../../../App/hooks/useReturnPaths";
import { sharedTooltipCopy } from "../../data/microcopy";

const EditShadowTest = ({ app }: AppPageProps) => {
  const [, setMetaTitle] = useMetaTitle();
  const theme = useTheme();
  const { returnPath, returnPathList } = useReturnPaths();
  const params = useParams() as {
    id: string;
    field?: string;
  };

  const [isProcessing, setIsProcessing] = useState(false);

  const { loadShadowTests } = useExperiments();

  const {
    editEntity: editShadowTest,
    entity: shadowTest,
    entityLoadError: shadowTestLoadError,
    entityEditError: shadowTestEditError,
    loadEntity: loadShadowTest,
    isEntityEdited: isShadowTestSaved,
  } = useManageEntity("experiments/shadow");

  const {
    getStandardInputsProps,
    pendingStandardInputs,
    standardInputsErrors,
    updateStandardInputs,
  } = useStandardInputs(app, "experiments/shadow", true);

  // load shadow test
  useEffect(() => {
    if (!shadowTest && !shadowTestLoadError) {
      loadShadowTest(app.id, params.id);
    }
  }, [app.id, shadowTest, shadowTestLoadError, loadShadowTest, params.id]);

  // page display
  useEffect(() => {
    if (shadowTest) {
      setMetaTitle(`Edit ${shadowTest.name}`);
    }
  }, [shadowTest, setMetaTitle]);

  // pre-fill pending shadow test with original values
  useEffect(() => {
    if (shadowTest && !pendingStandardInputs.id) {
      updateStandardInputs([
        { key: "name", value: shadowTest.name },
        { key: "id", value: shadowTest.id },
        { key: "description", value: shadowTest.description },
      ]);
    }
  }, [shadowTest, pendingStandardInputs.id, updateStandardInputs]);

  // disable loading state if edit shadow test error
  useEffect(() => {
    if (shadowTestEditError && isProcessing) {
      setIsProcessing(false);
    }
  }, [isProcessing, shadowTestEditError]);

  const handleShadowTestSave = async (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    e.preventDefault();
    e.stopPropagation();

    setIsProcessing(true);

    const payload = { ...pendingStandardInputs };

    await editShadowTest(app.id, shadowTest!.id, payload);
  };

  const handleCancel = () => {
    trackEvent("ShadowTests", {
      view: "Edit Shadow Test",
      action: "Edit Shadow Test Canceled",
    });
    return;
  };

  const handleShadowTestPostDelete = () => {
    trackEvent("ShadowTests", {
      view: "Edit Shadow Test",
      action: "Shadow Test Deleted",
    });

    loadShadowTests(app.id);
  };

  if (shadowTestLoadError) {
    return <StandardError errorMessage={shadowTestLoadError} />;
  }
  if (!pendingStandardInputs.id || !shadowTest) {
    return <Loading type="full-screen" dotColor={theme.color.orange500} />;
  }
  if (isShadowTestSaved) {
    trackEvent("ShadowTests", {
      view: "Edit Shadow Test",
      action: "Shadow Test Saved",
    });

    loadShadowTest(app.id, params.id);
    loadShadowTests(app.id);

    return <Redirect to={returnPath} />;
  }

  const isActionButtonDisabled =
    !pendingStandardInputs.name || !!standardInputsErrors.name;

  return (
    <>
      <Header
        configPageTitle={{
          label: `Edit ${shadowTest.name}`,
          ancestorIcon: <AvatarExperimentShadow size={24} />,
          ancestorLabel: "Shadow Tests",
          ancestorUrl: returnPathList,
        }}
      />

      <form>
        <RowDetail
          hasNoBorder
          property="Name"
          secondaryLabel="For reference only"
          render={
            <Box width="100%" maxWidth={INPUT_WIDTH_STANDARD}>
              <Input
                {...getStandardInputsProps({
                  placeholder: "Shadow test name",
                  testId: "edit-shadow-test-name-input",
                  type: "name",
                  trackEventCategory: "ShadowTests",
                  trackEventProperties: {
                    view: "Edit Shadow Test",
                    action: "Field Changed",
                    meta: {
                      field: "name",
                    },
                  },
                })}
              />
            </Box>
          }
        />

        <RowDetail
          property="ID"
          testId="shadow-test-row-id"
          secondaryLabel="Read-only"
          tooltipCopy={sharedTooltipCopy.idEdit("shadow test").content}
          render={
            <Box flexGrow={1} maxWidth={INPUT_WIDTH_STANDARD}>
              <Input
                htmlType="text"
                isDisabled
                placeholder="Shadow Test ID"
                value={shadowTest.id}
                readOnly
              />
            </Box>
          }
        />

        <RowDetail
          property="Description"
          secondaryLabel="(optional)"
          render={
            <Box width="100%" maxWidth={INPUT_WIDTH_STANDARD}>
              <Input
                {...getStandardInputsProps({
                  placeholder: "Shadow test description",
                  testId: "edit-shadow-test-description-input",
                  type: "description",
                  trackEventCategory: "ShadowTests",
                  trackEventProperties: {
                    view: "Edit Shadow Test",
                    action: "Field Changed",
                    meta: {
                      field: "description",
                    },
                  },
                })}
              />
            </Box>
          }
        />

        <Footer
          app={app}
          endpoint="experiments/shadow"
          entityId={shadowTest.id}
          error={shadowTestEditError}
          handleCancel={handleCancel}
          handleMainAction={handleShadowTestSave}
          handlePostDelete={handleShadowTestPostDelete}
          isActionButtonLoading={isProcessing}
          isActionButtonDisabled={isActionButtonDisabled}
          returnPath={returnPath}
          returnPathList={returnPathList}
          view="edit"
        />
      </form>
    </>
  );
};

export default EditShadowTest;
