import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Loading,
  Notification,
  PreviewRow2,
  Tag,
  Text,
} from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";

import { trackEvent } from "../../../analytics";
import { Integration } from "../../../api/core/controlPlane.types";
import Header from "../../../components/Header";
import { useMetaTitle } from "../../../components/Layout";
import { useIntegrations } from "../../../contexts/integrations/Integrations.context";
import { getIntegrationAvatar } from "../helpers/avatars";
import { availableIntegrationConfig } from "../Integrations.config";

const pageTitle = "My Integrations";

const InstalledIntegrations = () => {
  const [, setMetaTitle] = useMetaTitle();
  const { pathname } = useLocation();
  const [filterText, setFilterText] = React.useState("");
  const [retried, setRetried] = React.useState(false);

  const {
    filterIntegrationsByName,
    fetchIntegrations,
    integrationsLoading,
    integrationsError,
  } = useIntegrations();

  const filteredIntegrations = filterIntegrationsByName(filterText);

  useEffect(() => {
    setMetaTitle(pageTitle);
  }, [setMetaTitle]);

  useEffect(() => {
    if (filterIntegrationsByName("").length === 0 && !retried) {
      fetchIntegrations();
      setRetried(true);
    }
  }, [fetchIntegrations, filterIntegrationsByName, retried]);

  if (integrationsLoading) {
    return <Loading type="full-screen" />;
  }

  if (integrationsError) {
    return (
      <Notification
        mt={2}
        type="error"
        hasContactExtra
        message={integrationsError}
      />
    );
  }

  const renderInstalledIntegrations = () =>
    filteredIntegrations.length || filterText ? (
      <Box data-testid="integrations-container" mt={rem(-1)}>
        {filteredIntegrations.map((integration: Integration) => (
          <PreviewRow2
            mt={4}
            ml={4}
            key={integration.id}
            icon={
              <Box mt={1}>{getIntegrationAvatar(integration.type, 36)}</Box>
            }
            name={integration.name}
            id={integration.description ?? ""}
            tag={
              availableIntegrationConfig[integration.type]?.isEarlyAccess ? (
                <Tag ml={1} mb={-1} type="early-access" />
              ) : undefined
            }
            url={`${pathname}/${integration.id}`}
            urlOnClick={() =>
              trackEvent("Integrations", {
                view: "Installed",
                action: "Link Clicked",
                meta: {
                  type: "Detail View",
                },
              })
            }
            urlEdit={`${pathname}/${integration.id}/edit?source=list`}
            urlEditOnClick={() =>
              trackEvent("Integrations", {
                view: "Installed",
                action: "Link Clicked",
                meta: {
                  type: "Edit",
                },
              })
            }
          />
        ))}
      </Box>
    ) : (
      <Box maxWidth={rem(720)}>
        <Text mt={2} styleName="body-1" hasLink>
          When you create a new integration from the available Integrations it
          appears here. You can create and configure as many integrations as
          needed. To use an integration, attach it to a run profile. Read more
          about{" "}
          <a
            href="https://www.nextmv.io/docs/vehicle-routing/legacy/routing-app-configuration"
            target="_blank"
            rel="noreferrer"
          >
            how to use integrations
          </a>{" "}
          in Nextmv docs.
        </Text>
      </Box>
    );

  return (
    <>
      <Header
        configPageTitle={{ label: pageTitle }}
        configFilter={
          !filteredIntegrations.length && !filterText
            ? undefined
            : {
                inputText: filterText,
                setInputText: setFilterText,
                testId: "filter-installed-integrations",
              }
        }
      />
      {renderInstalledIntegrations()}
    </>
  );
};

export default InstalledIntegrations;
