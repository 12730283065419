import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import {
  Box,
  Button2,
  Flex,
  IconSocialGitHub,
  IconWrench,
  Tag,
  Text,
  Tooltip,
} from "@console/dsc";
import { rem } from "@console/dsc/src/lib/tools";
import { useTheme } from "@emotion/react";

import { trackEvent } from "../../../analytics";
import { CreateAppSubscriptionPayload } from "../../../api/core/controlPlane.types";
import { useUser } from "../../../AuthProvider";
import StandardError from "../../../components/StandardError";
import useAppSubscriptions from "../../../contexts/marketplace/hooks/useAppSubscriptions";
import { useMarketplaceCollection } from "../../../contexts/marketplace/Marketplace.context";
import { getAccUrl } from "../../../utils/navigation";
import { userHasAccessToAction } from "../../../utils/rbac_utils";
import { ActionGroups } from "../../../utils/rbac_utils/types";
import { StyledMarketplaceAppImage } from "../MarketplaceApp.styled";
import { MarketplaceAppHeaderProps } from "../MarketplaceApp.types";

const MarketplaceAppHeader = ({
  codeRepoLink,
  image,
  marketplaceAppId,
  partnerId,
  summary,
  tags,
  title,
}: MarketplaceAppHeaderProps) => {
  const [user] = useUser();
  const { id: accountId } = user;
  const theme = useTheme();

  const [submittedNewSubscription, setSubmittedNewSubscription] =
    useState(false);

  const { appSubscriptions, loadAppSubscriptions } = useMarketplaceCollection();

  const {
    addNewAppSubscription,
    appSubscriptionsActionLoading,
    appSubscriptionsActionError,
    appSubscriptionsError,
  } = useAppSubscriptions();

  const subscriptionId = `${partnerId}-${marketplaceAppId}`;
  const isSubscribed = appSubscriptions.some(
    (subscription) => subscription.subscription_id === subscriptionId
  );

  // before you can create a subscription app from a marketplace app,
  // you must successfully subscribe to the marketplace app first;
  // this is a one-time event per marketplace app and is only run
  // if isSubscribed is false
  const handleMarketplaceAppSubscribe = async (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    e.preventDefault();
    e.stopPropagation();

    let payload: CreateAppSubscriptionPayload = {
      subscription_id: subscriptionId,
    };

    trackEvent("Marketplace", {
      view: "Marketplace App Details",
      action: "Subscribe to App",
      meta: {
        partner: partnerId,
        application: marketplaceAppId,
      },
    });

    addNewAppSubscription(payload);
    setSubmittedNewSubscription(true);
  };

  // after subscription has been created (no errors), forward user
  // to create new subscription app view; the addNewAppSubscription()
  // run in handleMarketplaceAppSubscribe happens "behind the scenes"
  // while the primary CTA button is in loading state
  if (
    submittedNewSubscription &&
    !appSubscriptionsActionError &&
    !appSubscriptionsActionLoading
  ) {
    loadAppSubscriptions();
    return (
      <Redirect
        to={getAccUrl(
          accountId,
          `/apps/subscription/new?subscriptionId=${subscriptionId}`
        )}
      />
    );
  }

  const userCanSubscribe = userHasAccessToAction(
    user.roles,
    ActionGroups.MarketplaceOperator,
    {}
  );

  // Users can't download the CLI if they
  // can't make API keys
  const userCanDownloadCLI = userHasAccessToAction(
    user.roles,
    ActionGroups.SecurityOperator,
    {}
  );

  return (
    <>
      <Flex
        pt={[6, 6, 8]}
        pr={[4, 6]}
        pb={[4, 4, 4, 0]}
        pl={[4, 6, 7]}
        alignItems={["center", "center", "center", "flex-start"]}
        flexDirection={["column", "column", "column", "row"]}
      >
        <Box pt={3} width="100%" maxWidth={rem(720)}>
          <Flex
            flexDirection={["column", "column", "row", "column", "row"]}
            alignItems={[
              "flex-start",
              "flex-start",
              "flex-end",
              "flex-start",
              "flex-end",
            ]}
          >
            <Text
              as="h1"
              styleName="marketing-header-3"
              styles={{ color: theme.color.gray800 }}
            >
              {title}
            </Text>
            {tags && (
              <Flex
                mt={[3, 3, 0, 3, 0]}
                mb={[0, 0, rem(-3), 0, rem(-3)]}
                ml={[0, 0, 3, 0, 3]}
              >
                {tags.map((tag, index) => (
                  <Tag
                    key={`${tag}-${index}`}
                    mr={1}
                    label={tag}
                    textColor={theme.color.gray700}
                    backgroundColor={theme.color.gray200}
                  />
                ))}
              </Flex>
            )}
          </Flex>

          {summary && (
            <Box mt={2} maxWidth={rem(720)}>
              <Text styleName="body-1-plus">{summary}</Text>
            </Box>
          )}

          {partnerId && marketplaceAppId && userCanSubscribe && (
            <>
              <Flex mt={4} alignItems="center" flexWrap="wrap">
                <Button2
                  mb={2}
                  isLoading={
                    submittedNewSubscription && appSubscriptionsActionLoading
                  }
                  size="large"
                  label="Clone app"
                  testId="marketplace-app-clone-button"
                  {...(isSubscribed && {
                    to: getAccUrl(
                      accountId,
                      `/apps/subscription/new?subscriptionId=${subscriptionId}`
                    ),
                  })}
                  {...(!isSubscribed && {
                    to: "#",
                    onClick: (e: {
                      preventDefault: () => void;
                      stopPropagation: () => void;
                    }) => handleMarketplaceAppSubscribe(e),
                  })}
                />

                {userCanDownloadCLI && (
                  <>
                    <Button2
                      mb={2}
                      ml={2}
                      type="outline-quiet"
                      size="large"
                      label="Customize app"
                      to={getAccUrl(
                        accountId,
                        `/install?subscriptionId=${subscriptionId}`
                      )}
                      icon={<IconWrench iconColor={theme.color.gray700} />}
                      styles={{
                        color: theme.color.gray700,
                        fontWeight: 400,
                        boxShadow: `inset 0 0 0 ${theme.border.widthMediumShadowOnly} ${theme.color.gray200}`,
                        "&:hover, &:focus, &:active": {
                          color: theme.color.gray800,
                          boxShadow: `inset 0 0 0 ${theme.border.widthMediumShadowOnly} ${theme.color.gray400}`,
                          svg: {
                            fill: theme.color.gray800,
                          },
                        },
                      }}
                    />
                    <Tooltip
                      mr={3}
                      mb={2}
                      ml={1}
                      extraLinkLabel="Customize app"
                      extraLinkUrl={getAccUrl(
                        accountId,
                        `/install?subscriptionId=${subscriptionId}`
                      )}
                    >
                      Click the Customize app button and follow the 3-step
                      Nextmv developer tools installation guide to get access to
                      the model code for {title} app. Then start customizing it
                      to your unique needs.
                    </Tooltip>
                  </>
                )}

                {codeRepoLink && (
                  <Button2
                    mb={2}
                    type="outline-quiet"
                    size="large"
                    label="GitHub"
                    href={codeRepoLink}
                    isUrlExternal
                    icon={<IconSocialGitHub iconColor={theme.color.gray700} />}
                    styles={{
                      color: theme.color.gray700,
                      fontWeight: 400,
                      boxShadow: `inset 0 0 0 ${theme.border.widthMediumShadowOnly} ${theme.color.gray200}`,
                      "&:hover, &:focus, &:active": {
                        color: theme.color.gray800,
                        boxShadow: `inset 0 0 0 ${theme.border.widthMediumShadowOnly} ${theme.color.gray400}`,
                        svg: {
                          fill: theme.color.gray800,
                        },
                      },
                    }}
                  />
                )}
              </Flex>

              {(appSubscriptionsError || appSubscriptionsActionError) && (
                <StandardError
                  errorMessage={
                    appSubscriptionsError || appSubscriptionsActionError
                  }
                />
              )}
            </>
          )}
        </Box>

        {image && (
          <Box
            maxWidth={rem(350)}
            mt={[4, 4, 4, 0]}
            ml={[0, 0, 0, "auto"]}
            noShrink
          >
            <StyledMarketplaceAppImage
              src={image.url}
              alt={image.alt}
              maxWidth={rem(350)}
            />
          </Box>
        )}
      </Flex>
    </>
  );
};

export default MarketplaceAppHeader;
